.SystemManagementSettings{
    padding:15px;
    min-height: 80vh;
    @media (min-width: 768px){
        padding:32px;
    }
    :global{
        //
    }
}

.AddModalBody{
}