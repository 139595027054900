.ResourceOverviewCard {
  background-color: #f2f2f2;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  position: relative;
  :global {
    .crew-details {
      display: flex;
      gap: 20px;
      .resource-image {
        cursor: pointer;
        min-width: 110px;
        min-height: 110px;
        img {
          max-width: 110px;
          max-height: 110px;
          border-radius: 4px;
        }
      }
      .resource-details {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .resource-title{
          font-weight: bold;
        }
      }
    }
    .employees-count {
      display: flex;
      gap: 20px;
      margin-top: 10px;
      .employees-total {
        min-width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #bbbbbb;
        border-radius: 4px;
      }
      .outside-click-wrapper {
        flex-grow: 1;
      }
      .chips-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        max-width: 382px;
      }
      .employee-status-chip {
        cursor: pointer;
        background-color: #e0e0e0;
        max-width: 108px;
        height: 25px;
        display: flex;
        flex: 1;
        justify-content: center;
        gap: 5px;
        align-items: center;
        border-radius: 4px;
      }
    }
  }
}

.EmployeesPopup {
  width: 405px;
  height: 505px;
  top: 80px;
  left: 490px;
  border-radius: 4px;
  border: 1px;
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: -412px;
  z-index: 1;
  box-shadow: 1px 1px 10px #8f8f8f;
  @media screen and (max-width: 1024px) {
    width: 300px;
    height: 450px;
    left: -306px;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width:768px) and (max-width:1199px) {
    left: 50%;
    transform: translateX(-50%);
  }

  :global {
    .header {
      padding: 10px;
      width: 100%;
      height: 56px;
      background-color: #f7f9fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: large;
        font-weight: bolder;
      }
      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .employees-list {
      padding: 10px;

      .list-type-name {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-bottom: 10px;
        font-weight: bold;
        min-height: 28.58px;
      }

      ul {
        padding-left: unset;
        max-height: 400px;
        overflow-y: auto;
        position: relative;
        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .emp_list_item{
          border: 1px solid #dfdfdf;
          scrollbar-color: #888 transparent;
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            width: 5px;
          }
          

          &::-webkit-scrollbar-track {
            background: transparent; 
          }

          &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 10px;
          }
        }

        .not_committed_title{
          margin:15px 0;
          font-weight: 600;
        }

        .list-header {
          height: 40px;
          background-color: #f5f4f5;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // padding-left: 10px;
        }
        li {
          padding-left: 10px;
          height: 68px;
          border-bottom: 1px solid #dfdfdf;
          display: flex;
          align-items: center;
          .employee-list-card {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            .width-45{
              flex: 45;
            }
            img{
              overflow: hidden; //prevent alt text overflow in firefox
            }
          }
          .profile-picture {
            max-width: 40px;
            height: 40px;
            border: 1px solid gray;
            border-radius: 50%;
          }
        }
        :first-child {
          border: none;
        }

        @media screen and (max-width: 1024px) {
          max-height: 330px;
        }
      }
    }
  }
}

.ColorCode {
  span {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}