.AddEditNote {
  :global {
    //
    .add-edit-note {
      resize: none;
      margin-bottom: 10px;
    }
    .buttons {
      display: flex;
      gap: 5px;
    }
  }
}
