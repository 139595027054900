@media (max-width: 767px) {
  /* Helpers */
  .desktopNavbar {
    padding: 1.5rem 2rem !important;
  }
  .desktopNavbar .back-button {
    padding-left: 0;
    max-width: 40px;
    margin-left: 0 !important;
    background-position: 0px center !important;
  }
  /*custom new navbar*/
  .new_nav_hide {
    animation: new_nav_hide 0.5s forwards;
  }
  .new_nav {
    background: #202020;
    height: 50px;
    display: flex;
    position: relative;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
  .new_nav .nav_container {
    width: 100%;
    flex: 1 1 100%;
    z-index: 99;
    background: #202020;
  }
  .new_nav .nav-col {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .new_nav .navbar-toggle .icon-bar {
    background: #fff;
    width: 18px;
  }
  .new_nav .navbar-toggle {
    margin: 0;
    position: relative !important;
  }
  .new_nav .logo {
    margin-left: 3px;
  }

  .new_nav ul.actions {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .new_nav ul.actions li {
    position: relative;
  }
  .new_nav ul.actions li span.notify-count {
    position: absolute;
    color: #fff;
    top: -3px !important;
    right: auto !important;
    left: 16px !important;
    font-size: 8px !important;
    background: #dc0223;
    border-radius: 7px;
    min-width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    text-align: center;
    padding-right: 5px;
    padding-left: 5px;
  }
  .new_nav ul.actions li a {
    padding: 0 10px;
    height: 26px;
    display: flex;
  }
  .new_nav img.pro_pic {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #dc0223;
  }
  .new_nav img.pro_pic.availability {
    border: 1px solid #52dd77;
  }

  .new_nav img.pro_pic.guest-code {
    border: 1px solid #ffb300;
  }

  .new_nav .action_buttons {
    margin-left: auto;
  }

  .new_nav .searchWrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 99;
  }
  .new_nav .searchbar {
    position: absolute;
    background: #202020;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
  .new_nav .search_results {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    overflow: scroll;
  }

  .new_nav .cancel_btn {
    margin-left: auto;
  }
  .new_nav .searchbar_input {
    width: 100%;
    margin-right: 10px;
    position: relative;
  }
  .new_nav .searchbar_input input.searchbox {
    width: 100%;
    height: 34px;
    background: #fff;
    border: none;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    outline: none;
    margin: 0;
  }
  .new_nav .searchbar_input input.searchbox:focus {
    outline: none !important;
  }
  .new_nav button.clear_input {
    border: none;
    background: none;
    outline: none;
    position: absolute;
    right: 12px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
  }
  .new_nav button.clear_input img {
    vertical-align: top;
  }
  .new_nav button.search_cancel {
    padding: 0;
    background: none;
    color: #fff;
    border: none;
    font-weight: 500;
  }
  .new_nav button.search_cancel:focus {
    outline: none;
  }
  .new_nav .d-flex {
    display: flex;
  }
  .new_nav .d-none {
    display: none;
  }
  .new_nav_list {
    position: absolute;
    top: 49px;
    left: 0;
    right: 0;
    transform: translateY(calc(-100% - 50px));
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 1);
  }

  /*custom new navbar ends*/

  /* top margins and paddings override */
  .row.top_bar {
    display: none;
  }
  #app .outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  #app .outer.chat-active .outerMostContainer,
  #app .outer.chat-active.admin-user .outerMostContainer {
    padding-top: 50px !important;
  }

  .resource_container.your_crew_div,
  .resource_container.listResourcePage,
  .applicants_container.assign_staff_container,
  .resource_container.listResourcePage {
    margin-top: 30px;
  }
  .applicants_container.applicant_listing,
  .applicants_container.assignStaff_mobile_container,
  .settings_container {
    margin-top: 50px;
  }
  .inv-mobile-view #app .outerMostContainer {
    margin-top: 50px !important;
  }

  .slide-pane__header {
    display: none;
  }
  .sliding_content .msg-col.msg-messages .msg-mob-messageheader {
    display: flex;
    position: relative;
    left: 0;
    right: 0;
    padding: 7px 0;
  }
  .sliding_content .msg-col.msg-messages .msg-mob-messageheader ul.admincontrols.nav > li {
    display: flex;
    align-items: center;
  }
  .slide-pane__content {
    padding: 0 !important;
  }
  .sliding_content .profile-details p {
    line-height: 1.5;
  }
  .sliding_content .emp_type {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .sliding_content .profile-sep {
    display: flex;
    flex-direction: column;
  }
  .accordion__heading:focus,
  .profile-tb-btn:focus,
  .accordion__button:focus {
    outline: none !important;
  }
  .slide-pane__header {
    display: none !important;
  }

  .ReactModalPortal .sliding_profile_overlay {
    top: 50px !important;
  }
  .popover.show.notify_dropdown {
    top: 50px !important;
    position: fixed !important;
    transform: none !important;
    right: 0 !important;
    left: auto !important;
  }
  .notfication_listing a#btn-readmore {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .ReactModal__Overlay.slide-pane__overlay {
    background-color: transparent !important;
  }

  .breadcrumbs {
    margin-top: 50px !important;
  }

  .view_manifest_page.inner_container {
    margin-top: 65px;
  }
  .status_popup {
    top: -88px;
  }
  .unavailability_reason {
    margin: 20px 4px;
    width: 198px;
  }
  .other_reason {
    margin: 0;
    width: 198px;
  }
}

.new_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000057;
  z-index: 8;
}



@media screen and (min-width: 768px) {
  .desktopNavbar .back-button {
    display: none;
  }
  /* .popover.show.notify_dropdown{
        left: auto !important;
        right: 0 !important;
        top: 55px !important;
    } */
  .notification span.notify-count {
    position: absolute;
    color: #fff !important;
    top: -6px !important;
    right: auto !important;
    left: 12px !important;
    font-size: 8px !important;
    background: #dc0223;
    border-radius: 7px;
    min-width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    text-align: center;
    padding: 0 5px !important;
  }
  /*custom new hide  large*/
  .setting_link {
    display: none !important;
  }
  .new_nav_list.navbar-inverse {
    margin-top: 15px;
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
  }
  .row.outer_container .left_sidebar {
    position: relative !important;
  }
  .new_nav {
    background: #202020;
    width: 16.666666666%;
    display: flex;
    position: fixed;
    height: 100%;
    bottom: 0;
    top: 0;
    flex-direction: column;
    z-index: 9;
  }
  .top_bar {
    width: 100% !important;
    padding-left: 16.66666666666% !important;
    margin: 0;
    z-index: 8 !important;
  }
  .new_nav .nav_container {
    width: 100%;
  }
  .new_nav .nav-col {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
  }
  .new_nav .searchbar {
    display: none;
  }
  .new_nav .nav-col {
    flex-direction: column;
  }
  .new_nav .action_buttons {
    display: none;
  }
  .new_nav a.logo,
  .new_nav div.logo {
    display: flex;
    width: 100%;
    padding: 0;
  }
  .new_nav a.logo img {
    width: 100%;
    max-width: 128px;
    /* margin: 0 auto; */
    margin-top: 20px;
  }
  .status_popup {
    top: -88px !important;
  }
  .unavailability_reason {
    margin: 20px 4px;
    width: 284px;
  }
  .other_reason {
    margin: 0;
    width: 284px;
  }
}

@media screen and (max-width: 860px) and (min-width: 768px) {
  .new_nav {
    width: 20% !important;
  }
  .top_bar {
    padding-left: 20% !important;
  }
}

.new_nav_show {
  animation: new_nav_show 0.5s forwards;
}
/* .new_nav_hide{
    animation: new_nav_hide 0.5s forwards;
} */

@keyframes new_nav_show {
  0% {
    transform: translateY(calc(-100% - 50px));
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes new_nav_hide {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(calc(-100% - 50px));
  }
}


/* 27 Oct */

.search_item_avatar.Available{
 border: 2px solid #51dd76;
}

.search_item_avatar.Unavailable{
 border: 2px solid #dc0223;
}



.search_item_avatar.is_guest{
 border: 2px solid #ffb300 !important;
}


.user_click.user_locked{
 filter: grayscale(100%) !important;
}
