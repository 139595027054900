.equipment_container h3 {
  text-align: center;
}
.addEquipmentForm {
  /* width: 50%;
  margin: 0 auto; */
  padding: 25px 0px;
}
.addEquipmentForm textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 45px;
  margin-bottom: 15px;
}
.addEquipmentForm .formField.picture {
  padding-bottom: 10px;
  padding-top: 20px;
}

.submit_equipment.button {
  /* float:  right; */
}

.submit_equipment.button:disabled {
  background: #d0d3d4;
}
.top_option {
  display: flex;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.top_option .inputField {
  flex: 1 0;
}
.top_option .inputField:nth-child(2) {
  margin: 0 25px;
}


.inventory_element .invImg {
    background: url("../images/resource.png") no-repeat;
    /* background-size: 30px 30px !important; */
    background-size: cover !important;
    width: 72px;
    height: 72px;
    background-position: 50% 50% !important;
    box-sizing: border-box;
    margin: 3px 0;
    float: right;
}
.sliding_inventory .invImg {
  background: url("../images/resource.png") no-repeat;
  /* background-size: 30px 30px !important; */
  background-size: cover !important;
  width: 30px;
  height: 30px;
  background-position: 50% 50% !important;
  box-sizing: border-box;
  margin: 3px 0;
  float: left;
}
.inventory_element {
  background: #fff;
  float: left;
}
.master_section {
  width: 40%;
}
.inv_section {
  float: left;
  background: #747474;
  border-radius: 2px;
  /*padding: 5px;*/
}

.head_sec {
  background: #202020 !important;
  width: 97%;
  margin: 10px auto 5px;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}

.inventory_element {
  background: #fff;
  float: left;
  width: 40.8%;
  margin: 5px 0;
  padding: 10px;
  box-sizing: content-box;
  border-radius: 2px;
}
.inventory_element:nth-of-type(even) {
  /*margin-right:  0 !important;*/
}

.inventory_element:nth-of-type(odd) {
  margin-right: 10px !important;
  margin-left: 5px;
}

.rightToImg {
  float: left;
  width: calc(100% - 40px);
  /* margin-left: 10px; */
  min-height: 40px;
  line-height: 40px;
}

.inventory_element label {
  font-size: 12px;
  color: #b1b2b4;
  margin-bottom: 0;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  float: unset;
}
.available_section {
  margin-right: 15px;
}

.team_section {
  width: 36%;
}

.inv_detail_outer {
  display: flex;
  float: left;
  width: 100%;
  flex-wrap: wrap;
}

.inv_detail_outer .inv_section {
  /*flex: 1 0 20%;*/
}
/* .inv_crew_list .list-right{
  display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
} */

.master_section.inv_section,
.inv_crew_list.in_section {
  min-height: 800px;
  /* overflow-y: auto; */
  margin-bottom: 25px !important;
}
.master_section.inv_section .inventory_element {
  background: #fff !important;
  float: left;
  width: 50%;
  margin: 0;
  border: 5px solid #747474;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 2px;
  min-height: 115px;
  border-radius: 0 !important;
}
.master_section.inv_section .head_sec {
  border: 10px solid #747474;
  margin: 0 0 -5px;
  width: 100%;
}
.master_section.inv_section .inventory_element:nth-of-type(odd) {
  margin: 0 !important;
  border-left: 10px solid #747474;
}
.master_section.inv_section .inventory_element:nth-of-type(even) {
  border-right: 10px solid #747474;
  margin-bottom: 0 !important;
}
.team_section.inv_section {
  flex: 1 0 34%;
}
.available_section .inventory_element {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  min-height: 105px;
  margin: 5px 0 5px 0 !important;
  background: #fff !important;
}

.available_section .inventory_element:nth-of-type(odd) {
  margin: 0;
}
.available_section .head_sec {
  width: 100%;
}
.available_section .inventory_element:first-child {
  margin-top: 5px;
}
.inv_detail_outer .in_section {
  flex: 1 0;
  margin: 0 0px;
}

.inv_detail_outer .in_section:nth-of-type(2) {
  margin: 0 25px;
}
.in_section.user_area .inv_section {
  width: 47.5%;
  padding: 0 10px;
  max-height: 800px;
  overflow-y: auto;
  margin-bottom: 25px;
  box-sizing: border-box;
  overflow-x: hidden;
}
.team_section .inventory_element {
  width: 100%;
  margin: 10px 0 0px;
  padding: 10px;
  box-sizing: border-box;
  min-height: 105px;
}

.team_section .inventory_element:first-child {
  margin-top: 5px;
}
.user_area .inv_section {
  background: #d6d6d6;
  min-height: 99%;
}
.inv_crew_list {
  /*border: 1px dashed #B1B2B4;*/
  padding: 5px 0;
}
.top_option .inputField input,
.top_option .inputField select {
  padding: 10px;
}
.inv_crew_list img {
  width: 100px;
  height: 100px;
  background-position: 50% 50%;
  box-sizing: border-box;
  border-radius: 2px;
}

.inv_crew_list .resource_item {
  background: #fff;
  margin: 5px 10px;
  width: 45%;
  border-radius: 2px;
}

.inv_crew_list .resource_item:nth-child(even) {
  margin-left: 0;
}

.btn-assign.button {
  width: 85%;
  padding: 2px 0;
}

.inv_crew_list p {
  margin: 5px 0;
  text-align: center;
  width: auto;
}
.addEquipmentForm .react-datepicker-wrapper,
.addEquipmentForm .react-datepicker__input-container {
  width: 100%;
}

#team_section_outer {
  min-height: 800px;
}
.available_section.inv_section {
  /*min-height: 1610px;*/
}
#avaiable_section_outer,
#master_section_outer {
  /*float: left;*/
  min-height: 800px;
}

.inv_crew_list.in_section {
  position: relative;
  margin-bottom: 20px;
  margin-right: 0 !important;
}
.inv_crew_list.in_section p.epmty_crew {
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
}
.team_section.inv_section {
  float: right;
}
.inv_crew_list div {
  border: 1px dashed #b1b2b4;
  float: left;
  width: 100%;
  min-height: 100%;
  position: relative;
  padding-top: 10px;
  cursor: default;
}
.inv_crew_list div:first-child {
  padding-top: 5px;
}
.inv_crew_list div.resource_item {
  padding-top: 10px;
}
.sliding_inventory .invField {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.sliding_inventory .invImg {
  margin-bottom: 20px;
  width: 70px;
  height: 50px;
  background-size: 70px !important;
}
.sliding_inventory .invField label {
  font-size: 12px;
  color: #b1b2b4;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.in_section.user_area .inv_section::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d0d3d4;
}
.in_section.user_area .inv_section::-webkit-scrollbar {
  width: 5px;
  background-color: #e8e8e8 !important;
}
.in_section.user_area .inv_section::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: transparent;
}
.available_section .inventory_element:last-child {
  margin-bottom: 50px;
}
.rightToImg span {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  float: left;
}
.invImg.alternative {
  background-size: 70px !important;
}
.button.edit_inventory,
.button.delete_inventory {
  text-align: center;
  margin-bottom: 20px;
}
.sliding_inventory_overlay .slide-pane__content {
  padding-top: 10px;
}

.team_member .user_pic img {
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.team_member .user_pic_right {
  width: calc(100% - 40px);
  float: left;
  margin-left: 5px;
}

.team_member .user_pic_right p {
  margin-bottom: 0;
  font-size: 12px;
}

.team_member {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}

.team_member:first-child {
  padding-top: 10px;
}

.team_member .user_pic_right p.name {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.team_member .inventory_list {
  /* height: 60px; */
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mspa[errors="error"] #mspa_date {
  border-color: #ff0000 !important;
  background: #ffdcdc !important;
}
.equipment_container .btn_list {
  margin-top: 19px;
}
.equipment_container .page_title h1 {
  float: left;
}
.team_section .crewName {
  text-transform: uppercase;
  text-align: center;
  color: #3f7e39;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  text-decoration: underline;
}
.equipment_container.notAdmin .top_option .inputField:nth-child(2) {
  margin-right: 0;
  margin-left: 0;
}
.equipment_container.notAdmin .in_section.user_area .inv_section {
  width: 47.6%;
}
.equipment_container.notAdmin .team_section .head_sec {
  width: 100%;
}
.inv_detail_outer .inventory_element.over {
  /* height: 50px;
  background: #F7F9FA;*/
}
.equipment_container .picture .upload_or_drag {
  margin-bottom: 20px;
}
.notAdmin .inv_detail_outer .in_section:nth-of-type(2) {
  margin-right: 0;
}
.onlyMiddle.in_section.user_area .inv_section {
  width: 48.6% !important;
}
select.small_input.invHalf {
  width: 48%;
}

#master_section_outer .inventory_element .innerDrag {
  padding: 10px;
  border-bottom: 5px solid #51dd76;
}
#master_section_outer .inventory_element.assigned .innerDrag {
  border-bottom: 5px solid #51dd76;
}
#master_section_outer {
  /* padding-bottom: 150px; */
  float: left;
  width: 100%;
}

/********** inventory view - STARTS **************/
.inventory_view {
  width: 450px;
  border: 1px solid #d8d8d8;
  margin: 0 auto;
  padding: 50px 15px 15px;
}
.inventory_view .inv_field label {
  font-size: 12px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  color: #b1b2b4;
  margin-bottom: 5px;
}
.inventory_view .inv_field {
  margin-bottom: 20px;
}
.inventory_view .inv_field:first-child {
  text-align: center;
}
.inventory_view .inv_field img {
  max-width: 415px;
}
.equipment_container ul#resource_list,
.equipment_container ul#drivers_list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  margin-top: -22px;
  border-bottom: 1px solid #d0d3d4 !important;
  max-height: 245px;
  overflow: auto;
}
.equipment_container ul#resource_list li.item,
.equipment_container ul#drivers_list li.item {
  padding: 15px 10px;
  border: 1px solid #d0d3d4;
  border-bottom: 0;
  cursor: pointer;
}
.equipment_container ul#resource_list li.item:first-child,
.equipment_container ul#drivers_list li.item:first-child {
  border-top: 0;
}
.equipment_container ul#resource_list li.item:last-child,
.equipment_container ul#drivers_list li.item:last-child {
  border-top: 1px solid #d0d3d4;
}
.sliding_content .invField span {
  margin-bottom: 10px;
  display: block;
}

.inventory_view .image-box{

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 250px;
  width: 100%;

}

.inventory_view .inventory-select-slider{
  margin-left: auto;
  margin-right: auto;
}

.inventory_view .inv-slick-item-box{
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

/********** inventory view - ENDS **************/

/********** inventory in profile - STARTS **************/
.inv_head,
.inv_item {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0;
}
.inv_item{
  display: block;
}
.inv_head {
  background: #eef0f2;
}

.inv_cell {
  flex: 1 0;
}
.inventory_table .inv_cell span.cost {
  float: right;
}
.inv_item.total_cost .inv_cell,
.inv_item.total_cost .inv_cell span {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}

.date-status-by{
  margin-top: 10px;
}

/********** inventory in profile - ENDS **************/
.master_equip {
  flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-wrap: wrap;
}
.avail_equip {
  min-height: 800px;
}
.master_equip,
.avail_equip {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.invPlacholder {
  background: rebeccapurple;
  width: 50%;
  height: 300px;
  opacity: 1 !important;
}

.master_equip .test {
  display: flex;
  flex: 0 0 50%;
  border: solid 1px #999;
}

.noPicMaster {
  background-size: 40px !important;
  background-position: 50% 50% !important;
}
.withPicMaster {
  background-size: 70px !important;
  background-position: 50% 50% !important;
}

.button.delete_inventory {
  background: #ae2e41;
}

select#eq_type.disabled {
  cursor: not-allowed;
}

.dltInvPopUp .modal-body {
  margin-top: 20px;
}

.invMileageModal .modal-body {
  max-height: 600px;
  overflow-y: scroll;
  padding-top: 0;
}

.inventory-label-container{
  display: flex;
  justify-content: space-between;
}

.inventory-label-container .green {
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}

.mileageItem {
  padding: 16px 0px 14px 0px;
  border-bottom: 1px solid #D5D8D9;
  text-align: left;
}

.mileageItem:last-child {
  border-bottom: none;
}

.mileageItemTitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #B1B2B4;
}
.mileageItemDescription {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #202020;
}


.dltInvPopUp .ReactModal__Content.ReactModal__Content--after-open {
  width: 30%;
  /* height: 200px; */
}

@media screen and (max-width: 768px){
  .dltInvPopUp .ReactModal__Content.ReactModal__Content--after-open{
    width: 250px;
  }
}

.dltInvPopUp .modal-body p {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.dltInvPopUp .modal-body p .note{
  font-size: 12px;
  font-style: italic;
}
.invImg.withPicMaster {
  background-size: 70px !important;
}

.master-list-outer {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
}
.master-list-outer .is-list {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  width: 50%;
}
.master-list-outer .inventory_element_extended {
  display: flex;
  width: 100%;
}

.inventory_element.inventory_element_extended {
  width: 100% !important;
  max-width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-right: none !important ;
  border-left: none !important ;
}

.outer_drag {
  display: flex;
  flex: 0 0 100%;
  outline: none;
  max-width: 100%;
}
.dropzone-vertical {
  display: flex;
  flex-direction: column;
}

.inventory_element .innerDrag {
  padding: 10px;
  border-bottom: 5px solid #ddd;
  width: 100%;
}
.inventory_element .innerDrag.status-1 {
  border-bottom-color: #ddd !important;
}
.crew_list_boxes_container {
  border: none !important ;
}

.inv_crew_list.in_section p.epmty_crew {
  position: absolute !important;
  top: 21% !important;
}

.inv_crew_list .resource_item {
  background: #fff;
  margin: 5px 7px !important;
}

[data-react-beautiful-dnd-drag-handle].drag-box {
  padding-bottom: 0 !important ;
  /* width: 100% !important ; */
  padding-top: 0 !important;
  outline: none !important ;
  user-select: none;
}
[data-react-beautiful-dnd-drag-handle].drag-box .inventory_element {
  /* margin: 0 !important ; */
  padding: 0 !important ;
  width: 100% !important ;
}
.inventory_list {
  width: 100% !important ;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  /* background: #cdcdcd !important; */
  margin: 0px 0 !important;
  margin-top: 4px !important;
  margin-bottom: 0px !important;
  /* min-height:10px !important ; */
}
.team_member {
  margin-top: 0 !important ;
  margin-bottom: 0 !important ;
}

.avail_equip {
  min-height: 800px !important;
}
/* .inventory_list  [data-react-beautiful-dnd-drag-handle].drag-box:first-child{
 margin-top: 15px !important;
}
.inventory_list  [data-react-beautiful-dnd-drag-handle].drag-box:last-child{
 margin-bottom: 15px !important;
} */

.inventory_list [data-react-beautiful-dnd-drag-handle].drag-box {
  padding-bottom: 0 !important;
  /* width: 100% !important; */
  padding: 0 !important;
  outline: none !important;
  background-color: transparent !important ;
}

.avail_equip .innerDrag {
  border-bottom: none !important ;
}

.inv_crew_list div.resource_item {
  padding-top: 10px !important ;
  /* float: none !important ; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.list-right {
  padding-top: 14px !important ;
  text-align: center !important ;
}
.list-right .resource_item {
  margin-bottom: 9px !important;
}
.list-right .resource_item:last-child {
  float: left !important ;
 
}
.list-right .resource_item:last-child:nth-child(odd){
 margin-left: 20px !important;
}
.drop-zone-vertical {
  min-height: 150px;
  padding-bottom: 50px;
}
.master_section.inv_section,
.inv_crew_list.in_section {
  max-width: 501px !important;
}

.team_section .team_member:last-child .inventory_list {
  min-height: 100px;
}
.team_member .innerDrag {
  border-bottom: 0;
}
.formField .package_note {
  margin-bottom: 10px;
}
.formField.package {
  padding: 5px 20px 0px;
}
.imageList img {
  width: 100%;
  /* margin: 1%; */
  min-height: 100px;
  object-fit: cover;
  max-height: 100px;
}
.addEquipmentForm .formField .mandatory {
  color: red;
}
.addEquipmentForm .formField label {
  width: auto;
}
.addEquipmentForm .upload_item span {
  background: url("../images/close_button.png") no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px;
  position: absolute;
  right: -15px;
  top: -10px;
  cursor: pointer;
}
.addEquipmentForm .upload_item .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
}
.addEquipmentForm .formField.imageList {
  margin-bottom: 10px;
}
.addEquipmentForm .upload_item {
  width: 100%;
  position: relative;
  margin: 15px 0px;
  float: left;
}
.addEquipmentForm .formField.imageList .upload_item{
  box-shadow: 0px 0px 8px 1px #000000;
  height: 100px;
}
/* .addEquipmentForm .imageList .upload_item:first-child img {
  min-height: 156px !important;
} */
.vehicle_fields .inv_field:first-child {
  text-align: left;
}
.equipment_container .formField .txt {
  height: 40px;
  margin: 0 0 20px;
  font-size: 13px;
  color: #202020;
}
.inventoryPopup .ReactModal__Content.ReactModal__Content--after-open {
  width: 420px;
  height: 200px;
  overflow: initial !important;
}

.inventoryQuickAssign .ReactModal__Content.ReactModal__Content--after-open {
  width: 370px;
  height: 200px;
  overflow: initial !important;
}
.inventoryPopup .inv_popup_field {
  width: 50%;
  text-align: center;
  margin: 0 auto 10px;
}

.inventoryPopup .inv_popup_field label {
  text-align: left;
}
.inventoryPopup .inv_popup_field .react-datepicker-wrapper {
  display: block;
  width: 100%;
}
.inventoryPopup .inv_popup_field .react-datepicker__input-container {
  display: block;
}

.inv_field.img_list {
  width: 100%;
  float: left;
}

.inv_field.img_list .upload_item {
  float: left;
  min-height: 100px;
  max-height: 100px;
  margin: 7px;
}

.inv_field.img_list .upload_item img {
  max-height: 100px;
  min-height: 100px;
  object-fit: cover;
  max-width: 90px;
}

.addEquipmentForm .cancel_equipment.button {
  margin: 0 10px;
}

/* Keeru styles */

.sliding_content .inventory-slidebar-image{
  display: table;
}


.sliding_content .inventory-slidebar-image .image-box{
  display: table-cell;
  max-width: 350px;
}

.sliding_content .inventory-slidebar-image .image-box .main-image-top{
  width: 250px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.sliding_content .inventory-slidebar-image .inventory-select-slider{
  padding-left: 40px;
  padding-right: 40px;
  display: table-cell;
  vertical-align: middle;
  width: 275px;
  max-width: 282px;
}

.inventory-select-slider{
  margin-bottom: 10px;
  max-width: 195px;
}

.inventory-select-slider .slick-prev{
  background-image: url('../images/slider-left.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.inventory-select-slider .slick-next{
  background-image: url('../images/slider-right.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.inventory-select-slider .slick-prev:before,
.inventory-select-slider .slick-next:before {
  visibility: hidden;
}


.inv-slick-item-box{
  max-width: 60px;
}
.inv-slick-item-box,
.inv-slick-item-box div{
  outline: none;
}

.slide-pane_from_right.ReactModal__Content--after-open.sliding_inventory{
  width: 55% !important;
}

.slide-pane_from_right.ReactModal__Content--after-open.sliding_inventory .slide-pane__close{

  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 30px;
  width: 35px;
  height: 35px;
  background: url('../images/modal-close.svg') no-repeat top center;
  background-size: contain;
  z-index: 99;
}

.slide-pane_from_right.ReactModal__Content--after-open.sliding_inventory .slide-pane__content {
  padding: 0px 0px;
}

.inventory-slider-top{
  /* padding: 0px 70px 45px; */
  padding: 0px 45px 45px;
}

.inventory-slider-middle{
  padding: 45px 70px 15px;
  z-index: -1;
}

.inventory-slider-bottom{
  padding: 30px 70px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #b1b2b42e;
}

.border-1{
  border-bottom: 1px solid #b1b2b42e;
}

.inventory-slider-bottom .button{
  display: inline-block;
  max-width: 160px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}
.inventory-slider-bottom .print-details{
  float: right;
  position: relative;
}
.inventory-slider-bottom .print-details .print-loader{
  position: absolute;
  width: 20px;
  height: 20px;
  left: -30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


.sliding_inventory .slide-pane__content{
 margin-bottom: 90px;
}

.ReactModal__Overlay, .ReactModal__Overlay *{
  touch-action: manipulation !important;
}

.ReactModal__Overlay .sliding_content * {
  font-family: "Montserrat",sans-serif;
}

.equipment_search{
  float: right;
  width: 300px;
  padding: 10px;
  margin-bottom: 0;
}

.equipment_archived_list.archived_wrapper .list_item .pic {
  width: 46px;
  height: 46px;
  margin-left: 10px;
  border: 3px solid #51dd76;
  border-radius: 100%;
}

.equipment_archived_list.archived_wrapper .list_item_head, 
.equipment_archived_list.archived_wrapper .list_item {
  flex: 1 0 150px !important;
  display: flex;
  align-items: center;
}

.equipment_archived_list.archived_wrapper .list_item_head.unarchive, 
.equipment_archived_list.archived_wrapper .list_item.unarchive {
  flex: 0 0 9% !important;
}

.equipment_archived_list.archived_wrapper .list_item .small_title{
  display: none;
}

.list_item .link{
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .master-list-outer {
    -ms-flex-direction: column;
    flex-direction: column;
}
  .master-list-outer .is-list{
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    padding: 0 !important;
    min-height: auto !important;
    width: 100%;
  }
  .drop-zone-vertical{
    padding-bottom: 0px;
  }
}
/* Keeru styles end */

@media only screen and (max-width: 1199px) {


  .inventory-slider-bottom .button{
    
    max-width: 100px;
    
  }

  .sliding_content .inventory-slidebar-image{
    display: block;
    text-align: center;
  }

  .sliding_content .inventory-slidebar-image .image-box{
    display: block;
    max-width: 350px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .sliding_content .inventory-slidebar-image .image-box .main-image-top{
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .sliding_content .inventory-slidebar-image .inventory-select-slider{
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    vertical-align: middle;
    width: 275px;
    max-width: 295px;
  }

}

@media screen and (max-width: 1080px)
{
  .equipment_archived_list.archived_wrapper .list_item_head, 
  .equipment_archived_list.archived_wrapper .list_item {
    -ms-flex: 0 0 33% !important;
    flex: 0 0 33% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .equipment_archived_list.archived_wrapper .list_item_head.type, 
  .equipment_archived_list.archived_wrapper .list_item.type {
    -ms-flex: 0 0 18% !important;
    flex: 0 0 18% !important;
  }

  .equipment_archived_list.archived_wrapper .list_item_head:first-child, 
  .equipment_archived_list.archived_wrapper .list_item:first-child {
    -ms-flex: 0 0 18% !important;
    flex: 0 0 18% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .equipment_archived_list.archived_wrapper .list_item_head.unarchive, 
  .equipment_archived_list.archived_wrapper .list_item.unarchive {
    -ms-flex: 0 0 10% !important;
    flex: 0 0 10% !important;
  }
  
}

@media only screen and (max-width: 767px) {

  .emailManifestPopup .mail_div ul.searchResultUl,
  .mail_div #txtSearchManiEmployees {
      max-width: 340px;
  }
  .slide-pane_from_right.ReactModal__Content--after-open.sliding_inventory {
    width: 100% !important;
    margin-top: 0 !important;
  }

  .slide-pane_from_right.ReactModal__Content--after-open.sliding_inventory .slide-pane__close{

    right: 20px;
    top: 80px;

  }

  .inventory-slider-top{
    padding: 45px 20px 45px;
  
  }

  .inventory-slider-middle{
    padding: 45px 20px 15px;
    z-index: -1;
  }
  
  .inventory-slider-bottom{
    padding: 20px 20px;
  }

  .addEquipmentForm .cancel_equipment.button {
    margin: 0 2px;
  }
  .login_loader {
    margin: 8px 2px;
  }

  .sliding_content .inventory-slidebar-image{
    display: block;
    text-align: center;
  }
  
  
  .sliding_content .inventory-slidebar-image .image-box{
    display: block;
    max-width: 350px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .sliding_content .inventory-slidebar-image .image-box .main-image-top{
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .sliding_content .inventory-slidebar-image .inventory-select-slider{
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    vertical-align: middle;
    width: 275px;
    max-width: 295px;
  }

  .equipment_list .equipment_search {
    float: left;
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 11px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px){
  .equipment_list .inner_head.filter-container{
    display: flex;
    flex-direction: column;
  }
  .equipment_list .inner_head.filter-container .equipment_search{
    max-width: 250px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px)
{
  .archived_wrapper .appln_list_header {
    display: none;
  }

  .equipment_archived_list.archived_wrapper .list_item_head:first-child, 
  .equipment_archived_list.archived_wrapper .list_item:first-child {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .equipment_archived_list.archived_wrapper .list_item_head, 
  .equipment_archived_list.archived_wrapper .list_item {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .equipment_archived_list.archived_wrapper .list_item_head.type, 
  .equipment_archived_list.archived_wrapper .list_item.type{
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }

  .equipment_archived_list.archived_wrapper .list_item_head.unarchive, 
  .equipment_archived_list.archived_wrapper .list_item.unarchive {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }

  .equipment_archived_list.archived_wrapper .list_item .small_title{
    display: inline;
    font-weight: 600;
  }

  .applicant_item {
    border-bottom: 0;
    border-top: 1px solid #eef0f2;
  }
  .applicant_item:last-child {
    border-bottom: 1px solid #eef0f2;
  }
  .applicant_item .basic_info div {
    flex: 1 0 100% !important;
    text-align: center;
    margin-bottom: 10px;
  }

  .crew_wrapper .employee_list .list_item, .archived_wrapper .list_item {
      word-break: break-all;
      -ms-flex: 1 0 100% !important;
      flex: 1 0 100% !important;
      text-align: center;
      padding: 5px;
      -ms-flex-pack: center;
      justify-content: center;
  }
    
}


@media screen and (max-width: 600px) {
  .inv-mobile-view .outerMostContainer {
    margin-top: 0 !important ;
  }
}

@media screen and (max-width: 550px) {

  .emailManifestPopup .mail_div ul.searchResultUl,
  .mail_div #txtSearchManiEmployees {
    max-width: 100%;
  }
  .mail_div .add_mail {
    float: right;
    margin-top: 10px;
    margin-left: 10px;
  }
}


.mileageValidationBackdrop{
  background-color:rgb(128,128,128,0.6);
  position: absolute;
  height: 100%;
  display: flex;
  width: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.mileageValidationBackdropText {
  color: #fff;
  font-weight: bold;
}

/** Style for inventory image position update */
.rgtImage {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.rgtImage .rightToImg span {
  width: 72px;
}

.rgtImage .inv_field_type label {
  width: 54px;
}

.rgtImage .inv_field_type {
  width: 72px;
}

.rgtImage .invImageContainer {
  width: 72px;
}

.rgtImage .invImageContainer.size__reducer {
  width: 46px;
}

.rgtImage .invImageContainer.size__reducer .invImg {
  margin-right: -10px;
}