.root {
  position: relative;
}

.custom_tooltip {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff !important;
  text-align: center;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 9;
  /* left: 70px; */
  top: -50px;
}

.custom_tooltip_children {
  cursor: inherit;
}

.custom_tooltip_children:hover .custom_tooltip {
  visibility: visible;
}

.custom_tooltip::after {
  border: solid 5px #171717;
  content: "";
  position: absolute;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
  left: 5px;
}

.custom_tooltip_children.fs-tooltip .custom_tooltip {
  top: -100%;
  transform: translateY(-100%);
}

.manifest_list.outer_list.table-view
  .manifest_items.fs_manifest_item_one
  .custom_tooltip_children.fs-tooltip
  .custom_tooltip {
  top: unset;
  bottom: -100%;
  transform: translateY(100%);
}

.manifest_list.outer_list.table-view
  .manifest_items.fs_manifest_item_one
  .custom_tooltip_children.fs-tooltip
  .custom_tooltip::after {
  bottom: unset;
  top: -10px;
  transform: rotateZ(180deg);
}
