.VerbalWarningAckDoc {
  :global {
    width: 100%;
    border: 1px solid #dadada;
    margin: 22px auto;
    min-height: 70vh;
    .warning-doc-header {
      padding-left: 50px;
      padding-right: 50px;
      display: flex;
      border-bottom: 1px solid #dadada;
    }
    .warning-doc-body {
      padding: 0px 50px;
      display: flex;
      justify-content: space-between;
      .warning-doc-body-left {
        width: 65%;
        padding: 30px 0px;
        padding-right: 50px;
        border-right: 1px solid #dadada;
        .custom-table {
          width: 100%;
          border-collapse: collapse;
          tr {
            background-color: #f6f6f6;
          }
          .warning-doc-attachment-td span a {
            color: #297a18;
            text-decoration: underline;
            cursor: pointer;
          }
          .table-item-name {
            font-weight: bold;
            word-break: normal;
          }
        }

        .custom-table th,
        .custom-table td {
          border: 1px solid #ccc;
          padding: 8px;
          text-align: left;
          word-break: break-all;
        }

        .warning-doc-footer {
          display: flex;
          justify-content: space-between;
          margin: 0;
          .footer-input-title {
            font-weight: bold;
          }
          .acknowledgment-sign {
            .sign-canvas {
              width: 320px !important;
              //   min-width: 200px !important;
              //   margin: 0 !important;
            }
          }
          .acknowledgment-date {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .warning-doc-footer {
        display: flex;
        flex-direction: column;
      }

      .warning-doc-body {
        display: block;
        .warning-doc-body-left {
          width: 100%;
          border-right: none;
          padding-right: 0px;
        }
        .warning-doc-body-right {
          display: none;
        }
      }
    }

    @media screen and (max-width: 425px) {
      .warning-doc-footer {
        .acknowledgment-sign {
          .sign-canvas {
            background-color: red;
            display: inline;
          }
        }
      }

      .warning-doc-body {
        padding: 10px;
      }

      .warning-doc-header{
        margin-top: 40px;
      }
      
    }
  }
}
