.headerRoot {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  border-bottom: 1px solid #dadada;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.container {
  width: 100%;
  border: 1px solid #dadada;
  margin: 2rem auto;
}
.submissionLoader {
  opacity: 0.5;
  pointer-events: none;
}

.childrenContainer {
  padding: 30px 50px;
}

@media screen and (min-width: 768px) {
  .headerRoot h3 {
    max-width: calc(100% - 340px);
  }
}

@media (max-width: 769px) {
  .childrenContainer {
    padding: 15px 25px;
  }
}
