.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f9fa;
  padding: 10px;
}

.header h4 {
  margin-left: 10px;
  margin-bottom: 2px;
}

.headerText {
  width: 100%;
  text-align: center;
}

.alertContainer {
  padding: 2rem 3rem 0;
  line-height: 1.8;
}

.closeModal {
  cursor: pointer;
  /* margin-top: 8px !important; */
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0 10px 10px;
  cursor: pointer;
}
