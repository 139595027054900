.TimePicker {
  // .rc-time-picker-input[aria-label="Close Circle"] {
  //   display: none;
  // }
  :global {
    .custom-time-picker {
      position: relative;
      .rc-time-picker-input {
        width: 206px;
        height: 37px;
        // border-left: none;
        color: #000;
      }::after{
        content: url("../../../assets/icons/clock-icon.svg");
        position: absolute;
        top: 10px;
        right: 10px;
      }

      a{
        display: none;
      }
    }
  }
}
