/************ Dashboard Content - STARTS *************/
.page_title {
  border-bottom: 1px solid #d8d8d8;
  font-size: 24px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.inner_head h2 {
  font-size: 16px;
  padding: 10px 15px;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.bulletin_div,
.employee_stats_details,
.applicant_stats_details,
.inventory_details {
  float: left;
  width: 100%;
  background: #fff;
  padding: 0 15px 15px;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 6px 7px 10px -4px #dfe0e2;
}
.view_employee_list_button {
  background: #cac280;
  color: #fff !important;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 11px;
  display: inline-block;
}
.view_employee_list_button a {
  color: #fff !important;
  display: inline-block;
}

.bullet_item {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
}
.d-flex {
  display: flex;
}

.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.bullet_head h3 {
  display: inline-block;
  font-size: 14px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
span.bullet_date {
  color: #b1b2b4;
  margin: 0 10px;
  font-size: 12px;
}
span.pinned_post {
  background: #c9c288;
  color: #fff;
  padding: 3px 5px;
  font-size: 12px;
  border-radius: 3px;
  margin: 15px 0;
  float: right;
}
.bulletin_div.home_bulletin {
  min-height: 105px;
}
.dashboard_container .employee_stats_details {
  /* min-height: 379px; */
  /* margin-bottom: 25px; */
}
.bulletin_imgs {
  margin-top: 10px;
}
.bulletin_imgs img {
  margin-right: 5px;
  width: 75px;
  height: 75px;
}
.bulletin_link p {
  margin-bottom: 5px;
}
.bullet_item .green {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  font-size: 12px;
}
.bulletin_div {
  /* margin-bottom: 25px; */
}
.home_bulletin .bulletin_link_item .green,
.home_bulletin .bulletin_imgs_item {
  cursor: pointer;
  display: inline-block;
}
#bulletinPopupBody img {
  max-width: 100%;
}
.bulletinPopup .ReactModal__Content.ReactModal__Content--after-open {
  height: auto;
  bottom: initial !important;
  max-height: 85%;
}
.bulletinPopup .modal-head {
  height: 47px;
}
.bulletinPopup img.closeModal {
  margin: 0;
}
.home_bulletin .bulletin_imgs_item {
  width: 75px;
  height: 75px;
  background-size: cover !important;
  background-position: 50% 50% !important;
}

/****** employee stats - STARTS ********/
.employee_stats.inner_head h2 {
  display: inline-block;
  width: 50%;
  text-align: center;
  float: left;
}
/*.employee_stats.inner_head h2:first-child {
    border-right: 1px solid #D8D8D8;
}*/
.employee_stats_details {
  padding-top: 10px;
}

.view_tor_button {
  background: #cac280;
  color: #fff !important;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 11px;
  float: right;
}
.view_tor_button a {
  color: #fff !important;
}
.stats_item {
  float: left;
  width: 50%;
  border-left: 1px solid #d8d8d8;
  padding-left: 10px;
  margin-bottom: 20px;
}
.small_grey {
  color: #b1b2b4;
  font-size: 12px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.employee_stats_details span.percent {
  font-size: 16px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.employee_stats_details span.employee_type {
  font-size: 12px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.percent_div {
  margin-bottom: 5px;
}
/*.employee_stats_outer {
    margin-bottom: 30px;
    float: left;
}*/
.employee_stats.inner_head h2:last-child {
  color: #b1b2b4;
}
.home_employee .applicants_list .list_item_head,
.home_employee .applicants_list .list_item {
  flex: 1 0 20% !important;
}
/****** employee stats - ENDS **********/
/****** Applicant stats - STARTS ********/

.applicant_stats_outer {
  /* margin-top: 30px; */
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.applicants_head {
  text-align: center;
  padding: 10px 0 20px;
  border-bottom: 1px solid #d8d8d8;
}
.applicants_head span {
  margin: 0 10px;
  font-size: 12px;
  color: #b1b2b4;
}
.applicants_head span:hover,
.applicants_head span.stats,
.applicants_head span.initial {
  cursor: pointer;
  border-bottom: 1px solid #202020;
  padding-bottom: 5px;
}
.applicant_stats_details h2 {
  font-size: 24px;
  text-align: center;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  padding-bottom: 10px;
}
.appStats_list h3 {
  font-size: 16px;
  padding: 10px 0px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.appStats_list .stats_item {
  width: 100%;
}
.app_stats_body span.employee_type,
.app_stats_body span.percent {
  font-size: 12px;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
/****** Applicant stats - ENDS ********/
/****** Inventory - STARTS ************/
.inventory_details {
  padding-top: 15px;
}
.inventory_item {
  margin-bottom: 15px;
  width: 100%;
  display: table;
}
.inventory_data p {
  margin-bottom: 0;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.inventory_outer {
  /* margin-bottom: 30px; */
  float: left;
  width: 100%;
  margin-top: 30px;
}
.inventory_data {
  padding: 10px 0;
  display: table-cell;
  vertical-align: middle;
  width: 86%;
}
.inventory_progress {
  width: 14%;
  float: left;
}
.inventory_progress .CircularProgressbar {
  width: 15%;
  min-width: 75px;
}
/****** Inventory - ENDS ************/
/* Stats section  - STARTS */

.progress-text p.title {
  font-size: 16px;
  margin-bottom: 5px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.progress-text p.text {
  font-size: 12px;
  color: #b1b2b4;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.progress-text {
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  padding: 24px 10px 4px;
}
.stats_progress_div {
  float: left;
  padding: 5%;
  text-align: center;
  display: table;
  width: 100%;
}
.stats_progress_div .CircularProgressbar {
  width: 65%;
  float: none;
}
.no_emp_result p {
  text-align: center;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.changeEmployees {
  float: right;
  width: 320px;
}
/* Stats section - ENDS */

/* Firestorm overview -STARTS */
.firestorm_overview {
  margin-bottom: 25px;
  float: left;
  width: 100%;
  /* margin-top: 25px; */
}
.firestorm_overview .crew_details {
  padding: 20px 25px;
}
// .firestorm_overview .prof_pic img {
//   width: 80px;
//   height: 80px;
//   object-fit: cover;
// }
// .firestorm_overview .prof_pic {
//   width: 80px;
//   float: left;
//   margin-right: 10px;
// }
.firestorm_overview .name {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.firestorm_overview p {
  margin-bottom: 0;
  text-transform: capitalize;
}
.firestorm_overview .crew_item {
  margin: 20px 0;
}
.firestorm_overview .crew_item:first-child {
  margin-top: 0;
}
.firestorm_overview .crew_item:last-child {
  margin-bottom: 5px;
}

.firestorm_overview {
  .crew_list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    @media (max-width: 1365px) {
      justify-content: space-between;
    }
    .crew_item {
      width: 50%;
      max-width: 190px;
      margin-right: 10%;
      @media (min-width: 1441px) {
        margin-right: 50px;
      }
      @media (max-width: 1365px) {
        max-width: 50%;
        width: calc(50% - 20px);
        margin-top: 0px;
        margin-right: 0px;
      }
      @media (max-width: 1023px) {
        max-width: 100%;
        width: 100%;
      }
      .top {
        display: flex;
        padding-bottom: 8px;
        border-bottom: 1px solid #f1f1f1;
        .prof_pic {
          display: inline-block;
          width: 56px !important;
          margin-right: 10px;
          img {
            width: 56px !important;
            height: 56px !important;
            object-fit: cover;
            border-radius: 3px;
          }
        }
        .detail_div {
          width: calc(100% - 70px);
          p {
            font-family: "Montserrat", sans-serif;
            font-size: 13.5px;
            font-weight: 400;
            color: #5f6061;
            margin-bottom: 2px;
            padding-right: 0px !important;
            white-space: nowrap;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
          .name {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: bold;
            color: black;
          }
        }
      }
      .availability {
        padding: 5px 0;
        p {
          color: black;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
          margin-right: 15px;
          padding-right: 15px;
          // border-right:1px solid #DFDFDF;
          position: relative;
          @media (max-width: 1365px) {
            margin-right: 10px;
            padding-right: 10px;
          }
          &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: 12px;
            right: 0px;
            top: calc(50% - 6px);
            background-color: #dfdfdf;
          }
          span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 6px;
            min-width: 10px;
            display: inline-block;
          }
          &:first-child {
            span {
              background-color: #51dd76;
            }
          }
          &:nth-child(2) {
            span {
              background-color: #dc435d;
            }
          }
          &:last-child {
            // border:none;
            &:after {
              display: none;
            }
            span {
              background-color: #a8a8a8;
            }
          }
        }
      }
    }
  }
}

.firestorm_overview span.available {
  color: #51dd76;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.firestorm_overview span.unavailable {
  color: #dc435d;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
/* Firestorm overview - ENDS */
/************ Dashboard Content - ENDS ***************/
.dashboard_container center#feedLoader {
  display: none;
}
.home_bulletin .bullet_item p {
  word-break: break-word;
}

.hide-error-image {
  display: none !important ;
}
@media screen and (max-width: 767px) {
  .logout {
    padding-left: 14px;
  }
}

.popover.notify_dropdown {
  @media screen and (max-width: 767px) {
    width: 100% !important ;
    max-width: 321px !important ;
  }
}
@media screen and (max-width: 767px) {
  .title.bull_cell {
    white-space: pre-line;
    word-break: break-all;
    font-weight: bold;
    width: 100% !important;
    display: block;
  }
  .message.bull_cell.has-ul-dot {
    width: 100% !important;
    display: block;
    padding-top: 0 !important ;
  }
}

#view_employee_details_table .tbody .td[data-header-title="Position"] {
  &:before {
    position: relative;
    top: 10px;
  }
}
.login-label-wrapper {
  float: left;
  width: 100%;
}
.inline-login-txt-wrapper {
  position: relative;
  display: block;
  width: 100%;
  input {
    &[type="text"],
    [type="password"] {
      padding-right: 35px !important;
    }
  }
  .toggle-icon-password {
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    bottom: 0;
    margin: auto 0;
    right: 8px;
    img {
      width: 100%;
      display: none;
    }

    &[aria-hidden="true"] {
      img {
        &:nth-child(1) {
          display: block !important;
        }
      }
    }
    &[aria-hidden="false"] {
      img {
        &:nth-child(2) {
          display: block !important;
        }
      }
    }
  }
}

.top_btn_group.flex-group {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .notification span {
    position: absolute !important;
    top: -5px !important;
  }

  .dragConList {
    flex: 0 0 50% !important;
    width: 50% !important;
    min-width: 50% !important;
  }
  #permission-tbl label {
    display: inline-block;
  }

  .table.top_table.last_table.ncc_sign {
    tr {
      td {
        min-height: 110px;
      }
    }
  }
}

.toggle-icon-register {
  top: 18px !important;
}

@media screen and (max-width: 767px) {
  .outerMostContainer {
    margin-top: 60px !important ;
  }
}
.resourcebox {
  .image-wrapper.flex-image {
    min-height: 97px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .Logout_div .logout {
    padding-left: 0 !important ;
  }
}

.dashboard-dynamic .inventory_outer {
  margin-top: 0 !important ;
}
.dashboard-dynamic {
  .employee_stats_outer {
    .employee_stats.inner_head {
      h2 {
        width: 90%;
        text-align: left !important ;
      }
    }
  }
}

span[data-offset-key] {
  span {
    font-weight: inherit !important ;
    font-size: inherit !important ;
    font-family: inherit !important ;
  }
}

.col-md-6 {
  @media (min-width: 768px) {
    width: 50%;
  }
}

.empStatusPopup {
  .ReactModal__Content {
    // height: 288px !important;
    width: 100% !important;
    max-width: 455px;
    @media (max-width: 480px) {
      width: calc(100% - 40px) !important;
    }
    .status_step {
      // padding: 38px 0px !important;
      // height: auto;
      // &.step4{
      //   padding: 1px 0px !important;
      // }
      label {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: bold;
        span {
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .button {
        min-width: 100px;
        margin: 12px 5px;
        font-size: 13px;
        padding: 10px 20px;
        font-family: "Proxima Nova Semibold", Georgia, sans-serif;
        &.outlined {
          &.disabled {
            color: #adadad;
            border: 1px solid #e0e0e0;
          }
        }
      }
      .user-status-edit-wrapper {
        max-width: 345px;
        margin: auto;
        div {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}

// Employee List Locked

.employee_list {
  &.locked {
    .employee_item {
      .image-holder {
        img {
          border-color: #abadb0 !important;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
    .color_code {
      background: #a8a8a8 !important;
    }
    .employee_item {
      *:not(.pos_counter) {
        color: #a8a8a8;
      }
    }
  }
}
.crew_wrapper .employee_list.bulk-select .button-list {
  display: none;
  justify-content: flex-end;
  padding: 15px;
  button {
    font-family: "Proxima Nova", sans-serif;
  }
}
.crew_wrapper .employee_list.bulk-select {
  .fas {
    font-size: 16px;
  }
}

.accordion {
  &.active {
    & + .panel {
      overflow: hidden;
      position: relative;
      section.button-list {
        display: flex !important;
        @media screen and (min-width: 768px) {
          position: absolute;
          top: -40px;
          right: 35px;
          padding: 0;
        }
      }
    }
  }
}

.emp_status_modal {
  .ReactModal__Content.ReactModal__Content--after-open {
    // height: 240px;
    @media screen and (max-width: 480px) {
      width: calc(100% - 60px) !important;
    }
  }

  &.lock {
    .ReactModal__Content.ReactModal__Content--after-open {
      height: 200px;
    }
  }
}

.empStatusPopup .status_step.step1,
.empStatusPopup .status_step.step2 {
}

.profileEmp_status_modal {
  .ReactModal__Content.ReactModal__Content--after-open {
    // height: 100px;
    // min-height: 220px;
  }
}

.emp_status {
  .text-bold {
    font-weight: bold;
  }

  .checkb {
    text-align: left;
  }
  label {
    display: flex;
    font-weight: normal !important;
    font-size: 14px !important;
    margin-top: 15px !important;
    span {
      font-weight: normal !important;
      font-size: 14px !important;
    }

    input[type="checkbox"] {
      width: 13px;
      margin-right: 10px;
    }
  }

  .emp_button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      font-family: "Proxima Nova", sans-serif;
      & + button {
        margin-left: 10px;
        font-weight: normal;
      }
    }
  }
  .user-status-edit-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 100% !important;
  }
}

// Notification-bar
.top-notification {
  padding: 12px 66px 11px 20px;
  background-color: #9e2525;
  text-align: center;
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 50px;
  .icon {
    vertical-align: text-bottom;
    margin-right: 6px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: white;
    // vertical-align: sub;
    em {
      font-style: normal;
      font-weight: 600;
      color: white;
      font-size: 14px;
    }
  }
  .refresh-btn {
    background-color: transparent;
    margin-left: 8px;
    padding: 8px 18px;
    line-height: 1;
    font-size: 13px;
    font-weight: 600;
    color: white;
    border-radius: 3px;
    border: 1px solid white;
  }
  .closebtn {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 46px;
    height: 50px;
    background-color: rgba($color: white, $alpha: 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 480px) {
    height: 100px;
    padding: 8px 66px 8px 20px;
    .icon {
      vertical-align: middle;
      margin-right: 6px;
    }
    span {
      vertical-align: sub;
    }
    .refresh-btn {
      margin-top: 10px;
    }
    .closebtn {
      height: 100px;
    }
  }
}

.addEquipmentForm {
  .formField {
    .right {
      @media screen and (max-width: 860px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
      }
      .login_loader {
        margin-left: auto;
        margin-right: auto;
      }
      input {
        @media screen and (max-width: 860px) {
          display: flex;
          flex: 0 0 calc(33.3% - 10px);
          width: calc(33.3% - 10px);
          align-items: center;
          justify-content: center;
          line-height: 18px;
          margin: 0;
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}

.giss__loader {
  display: flex;
  justify-content: center;
  i {
    font-size: 6rem;
    margin-top: 34%;
  }
}

.dashboard_container {
  .res_pdf_container {
    padding-top: 2px !important;
  }
}

.profile_edit_wrapper--sec {
  .panel {
    max-height: unset;
    overflow: initial;
    transition: none;
    display: none;
  }
  .accordion.active + .panel {
    display: block;
    overflow: initial;
  }
}
