.SystemNotificationsTabContent {
  padding: 12px;
  @media (min-width: 768px){
    padding: 32px;
  }
  :global {
    .tor-info-banner {
      cursor: pointer;
      border: 1px solid #e8f0f2;
      background: #f6f9fa;
      column-gap: 20px;
      padding: 16px;
      @media (min-width: 1024px){
        display: flex;
        align-items: center;
      }
      .description {
        width: 100%;
        color: #555;
        font-weight: 400;
        line-height: 24px;
        @media (min-width: 768px){
          width: calc(100% - 190px); 
        } 
      }
      .dropdown{
        transform: rotate(-180deg);
        transition: all 0.3s ease-in-out;
        @media (max-width: 768px){
          text-align: center;
          display: block;
          svg{
            display: inline-block;
          }
        }
        &.open{
          transform: rotate(0deg);
        }
      }
    }
    .sys-notifications-table {
      padding-top: 12px;
      .table-responsive{
        // overflow-x: unset;
        min-height: 70vh;
      }
      table{
        border: 1px solid #E8F0F2;
        margin-bottom: 0;
        thead{
          th{
            padding: 10px 16px;
            vertical-align: middle;
            background: #EEF0F2;
            border-bottom: 1px solid #D8D8D8;
            line-height: 24px;
            &:last-child{
              text-align: right;
              svg{
                display: inline-block;
              }
            }
            &:nth-child(1){
              width: 32%;
              &::after{
                content: '';
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #000;
                display: inline-block;
                margin-left: 5px;
                vertical-align: middle;
                transform: rotate(0deg);
              }
              &.arrow_up{
                &::after{
                  transform: rotate(-180deg);
                }
              }
            }
            &:first-child{
              cursor: pointer;
            }
            &:nth-child(2){
              width: calc(100% - (32% + 82px + 56px));
            }
            &:nth-child(3){
              width: 82px;
            }
            &:nth-child(4){
              width: 56px;
              min-width: 56px;
            }
          }
        }
        tbody{
          tr{
            td{
              padding: 22px 16px;
              background-color: #F3F3F3;
              border: none;
              &:nth-child(1){
                width: 32%;
              }
              &:nth-child(2){
                width: calc(100% - (32% + 82px + 56px));
              }
              &:nth-child(3){
                width: 82px;
                color: #3F7E39;
                line-height: 20px;
                font-weight: 600;
                &:not(:empty){
                  cursor: pointer;
                }
                &:hover{
                  color: #000;
                }
              }
              &:nth-child(4){
                width: 56px;
                min-width: 56px;
                color: #3F7E39;
                line-height: 20px;
                font-weight: 600;
                &:not(:empty){
                  cursor: pointer;
                }
                &:hover{
                  color: #000;
                }
              }
            }
            &:nth-child(even){
              td{
                background-color: #FBFBFB;
              }
            }
          }
          .email__select{
            position: relative;
            @media (min-width: 1200px){ 
              max-width: calc(100% - 60px);
            }
            .CreatableAsyncSelectMulti{
              cursor: pointer;
            }
            .button__div{
              width: 180px;
              display: flex;
              align-items: center;
              column-gap: 10px;
              position: absolute;
              left: calc(100% + 12px);
              top: 50%;
              transform: translateY(-50%);
              @media (max-width: 1200px){
                position: static;
                transform: none;
                margin-top: 10px;
              }
              button{
                padding: 6px 20px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.AddResourceModal {
  :global {
    background-color: #fff;
    border: 1px solid #CCC;
    border-radius: 4px;
    max-width: 460px !important;
    .modal-head{
      padding: 0;
      h4{
        margin: 0;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        padding: 15px 16px;
        background-color: #F7F9FA;
        text-transform: uppercase;
      }
    }
    .add-modal-body{
      padding: 23px;
      color: #202020;
      font-size: 13px;
      font-weight: 400;
      line-height: 23px;
    }
    .add-modal-footer{
      padding: 23px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      justify-content: flex-end;
    }
    .select-box{
      margin-bottom: rem(11);
      &.hr__top{
        margin-top: 32px;
        padding-top: 24px;
        border-top: 1px solid #ccc;
      }
    }

    .select-without-search {
      .css-1fdsijx-ValueContainer{
        max-height: 38px;
        position: relative;
        .css-1jqq78o-placeholder{
          position:absolute ;
          bottom: 14px;

        }
      }
    }
  }
}

.ConfirmDeleteModal {
  :global {
    background-color: #fff;
    border: 1px solid #CCC;
    border-radius: 4px;
    max-width: rem(460px) !important;
    .delete-modal-head{
      h4{
        margin: 0;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        padding: 15px 16px;
        background-color: #F7F9FA;
      }
    }
    .delete-modal-body{
      padding: 16px;
      color: #202020;
      font-size: 13px;
      font-weight: 400;
      line-height: 23px;
    }
    .delete-modal-footer{
      padding: 16px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      justify-content: flex-end;
    }
  }
}
.ConfirmSaveModal {
  :global {
    background-color: #fff;
    border: 1px solid #CCC;
    border-radius: 4px;
    max-width: rem(460px) !important;
    .save-modal-head{
      h4{
        margin: 0;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        padding: 15px 16px;
        background-color: #F7F9FA;
      }
    }
    .save-modal-body{
      padding: 16px;
      color: #202020;
      font-size: 13px;
      font-weight: 400;
      line-height: 23px;
    }
    .save-modal-footer{
      padding: 16px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      justify-content: flex-end;
    }
  }
}
