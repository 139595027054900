/*monsterrat font: remove before generating build*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap");
@import "../../_helpers/sassConfig.scss";

/*attached: remove before compiling and generating build*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Montserrat", sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}
.height-none {
  height: 0px;
}

/* body css to preview 
body{
    
} 
*/

// variables
// $assetsUrl: "https://firestormfire.sfo2.cdn.digitaloceanspaces.com/dev/assets/";
$green: #3f7e39;
$green-t5: #3f7e397e;
$greyB: #f9f9f9;
$borderB: #dadada;

$device-med: 992px;
$device-tab: 768px;
$device-m: 480px;
$device-sm: 375px;
.required-star {
  color: #d33737 !important;
}

body.ReactModal__Body--open{
  @media screen and (max-width: 768px){
    height: 100vh;
    overflow: hidden;
  }
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.px-1 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-3 {
  padding-left: 30px;
  padding-right: 30px;
}

// w4 new
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}
.m-0 {
  margin: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px;
}
.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px;
}
.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px;
}
.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px;
}

.text-bold {
  font-weight: 700;
}

.d-flex {
  display: flex;
  &.center {
    justify-content: center;
  }
}

.flex-column {
  flex-direction: column;
}

.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}

.w-100 {
  width: 100%;
}
.mw-100 {
  max-width: 100px !important;
}
.mw-150 {
  max-width: 150px !important;
}
.mw-200 {
  max-width: 200px !important;
}
.mw-250 {
  max-width: 250px !important;
}
.mw-300 {
  max-width: 300px !important;
}

.pt-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.pos-rel {
  position: relative;
}
.v-align-sub {
  vertical-align: sub;
}

.overflow-hidden {
  overflow: hidden;
}
// buttons
.button {
  &.btn-xs {
    padding: 3px 8px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}
.button {
  &.disabled {
    background-color: #ccc;
    color: #555;
    cursor: not-allowed;
  }
  &.plain {
    background-color: transparent;
    color: rgb(54, 54, 54);
    &:hover {
      color: rgb(82, 82, 82);
    }
  }
}

.other-pdf.doc_info-rh {
  margin-top: 90px !important;
  .action_buttons {
    display: none !important;
  }
}

button {
  border-radius: 3px;
  border: none;
  box-shadow: none;
  // padding: 8px 15px;
  // min-width: 150px;
  color: #fff;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &.accept-sign,
  &.doc-btn {
    background-color: $green;
    color: #fff;
  }

  &.accept-sign {
    width: 100%;
  }
}

.btn-mark-as-read {
  background-color: #686868;
  margin-left: 2rem;
  font-size: 12px;
  max-height: 32px;
  transition: all ease 0.1s;
  &:hover {
    background-color: #a5a5a5;
    color: #fff;
  }
  &:focus {
    color: #fff;
    outline: 0;
    background-color: #a5a5a5;
    &:active {
      outline: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 922px) {
    font-size: 10px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.resource_item {
  p {
    span.status {
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      height: 20px;
      font-weight: 400;
      img {
        width: 15px !important;
        height: 15px !important;
      }
    }
    max-width: 120px;
    &.avail-status {
      overflow: hidden;
      text-align: center;
    }
  }
}

.ReactModal__Overlay.slide-pane__overlay.sliding_profile_overlay {
  z-index: 999;
}
.filter-hover {
  position: absolute;
  right: 0px;
  // width: 100%;
  top: 12px;
  background-color: transparent;
  z-index: 7;
  & .filter_form {
    min-width: 600px;
    max-width: 600px !important;
    position: absolute;
    right: 0;
    margin-top: 14px;
    background-color: #fff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    background-color: transparent;
    z-index: 7;
    .tabs-bar {
      .search_filters {
        margin: 0;
        margin-top: 20px;
      }
    }
    .filter_form {
      position: relative;
      max-width: 100%;
      min-width: 100%;
      margin-top: 0px;
      background-color: #fff;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  .filter_form {
    @media screen and (min-width: 993px) and (max-width: 1280px) {
      margin-top: 15px;
    }
  }
}
// Docs Tabs in Profile
.custom-tabs {
  // margin-top: 2rem;
  background-color: #fff;
  li.custom-tab {
    a {
      @media screen and (min-width: 1560px) {
        min-width: 300px;
      }
      padding: 2rem;
      border: none;
      border-radius: 0px;
      color: #555;
      font-size: 1.5rem;
      outline: none;
      border-bottom: 2px solid transparent;
      i {
        margin-right: 1.5rem;
      }
      &:hover {
        color: #555;
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }

      @media screen and (max-width: 992px) {
        min-width: 100px;
      }
    }
  }
  li.custom-tab.active {
    a {
      border: none;
      border-bottom: 2px solid #3f7e39;
      color: #3f7e39;
      font-weight: bold;
      i {
        color: #3f7e39;
      }
      &:hover {
        // color: #555;
        cursor: default;
        border-bottom: 2px solid #3f7e39;
      }
      div {
        color: #3f7e39;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .nav-tabs {
      display: flex;
      flex-direction: column;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

.doc-list-empty {
  text-align: center;
  padding: 5rem;
}
.custom-tab-content {
  min-height: calc(100vh - 280px);
  background-color: #fff;
  .content {
    .list {
      width: 100%;
      p.button-container {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin: 0;
        @media screen and (max-width: 480px) {
          height: auto;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;
        }
        button {
          position: absolute;
          top: -50px;
          right: 10px;

          @media screen and (max-width: 480px) {
            position: relative;
            width: 100%;
            top: auto;
            right: auto;
          }
        }
      }
    }
    .new {
      width: 450px;
      margin: 0 auto;
      padding-top: 5rem;
      display: flex;
      flex-direction: column;
      p {
        font-size: 1.5rem;
        font-weight: bold;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        button {
          margin-left: 2rem;
        }
      }
      .actions button[disabled] {
        background: #c9c9c9;
        border: 1px solid #c9c9c9;
        color: #000;
      }
    }
    .document {
      width: 100%;
      .doc_info:not(.full-width_form) {
        p {
          display: flex;
          justify-content: flex-end;
          position: relative;
          margin: 0;
          z-index: 7;
          width: 100%;
          &.button-container {
            margin: 0 auto;
            button#back-button {
              @media screen and (max-width: 768px) {
                position: absolute;
                top: 32px;
                right: 20px;
              }
              position: absolute;
              top: 35px;
              right: 20px;
            }

            button#toggle-button {
              @media screen and (max-width: 768px) {
                position: absolute;
                top: 52px;
                right: 110px;
              }
              position: absolute;
              top: 35px;
              right: 110px;
            }

            .toggle-btn-grp{
              @media screen and (max-width: 768px) {
                position: absolute;
                top: 52px;
                right: 110px;
              }
              position: absolute;
              top: 35px;
              right: 110px;
              display: flex;
              gap: 14px;
              a#toggle-btn-download {
                color: #FFFFFF;
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
          button#back-button {
            @media screen and (max-width: 768px) {
              position: absolute;
              top: 52px;
              right: 20px;
            }
            position: absolute;
            top: 35px;
            right: 20px;
          }
        }
      }
    }
    .doc_info-rh {
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #cfd0d2;
    }
  }
}

// custom checkbox
/* Customize the label (the container) */
/* Hide the browser's default checkbox */
/* Create a custom checkbox */
/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
/* Create the checkmark/indicator (hidden when not checked) */
/* Show the checkmark when checked */
/* Style the checkmark/indicator */
.input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: #3f7e39;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: #ccc;
        }
      }
    }
  }
  .checkmark {
    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

// flex helpers
.flex-shrink-1 {
  flex-shrink: 1 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
}
//end of flex helpers

// Colors
.text-red {
  color: rgb(199, 42, 42) !important;
}
.text-gray {
  color: rgb(136, 136, 136) !important;
}
.text-yellow {
  color: rgb(224, 178, 49) !important;
}
.text-green {
  color: #3f7e39 !important;
}

// modals
.ReactModalPortal {
  .ReactModal__Overlay.ReactModal__Overlay--after-open.RemoveApplntPopup {
    .ReactModal__Content.ReactModal__Content--after-open {
      margin: 0 auto;
      width: 400px;
      @media screen and (max-width: 480px) {
        width: 95%;
        min-width: 280px;
      }
      .modal-head {
        .modal-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .modal-body {
        .content {
          padding: 3rem 0;
          p {
            font-size: 1.8rem;
          }
        }
        .buttons {
          padding: 2rem 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end;
          button {
            margin: 1rem;
          }
        }
      }
    }
  }
}

.sign_canvas {
  .sign_img {
    object-fit: contain !important;
    object-position: center;
    width: 100%;
  }
}
// sign canvas
.sign {
  width: 50%;

  @media screen and (max-width: $device-med) {
    width: 100%;
  }

  .sign_canvas {
    border: 1px solid #d5d8d9;
    border-radius: 3px;
    min-height: 229px;
    max-height: 229px;
    margin-bottom: 14px;

    canvas {
      height: 229px;
      width: 100%;
      margin-bottom: 14px;
    }
  }

  p {
    margin-bottom: 13px;
    font-size: 13px;
    font-weight: bold;
  }
}

.user-docs {
  padding-top: 20px;

  .docs_breadcrumbs {
    margin: -10px -15px;
    max-width: 100vw;
    background: #fffce7;
    padding: 12px 20px 10px;
    font-size: 12px;
    font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  }

  .docs_breadcrumbs .green,
  .docs_breadcrumbs span {
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
    cursor: pointer;
    font-size: 12px;
  }

  .docs_breadcrumbs .green {
    color: #3f7e39;
  }

  .docs_breadcrumbs span,
  .docs_breadcrumbs a {
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #202020;
  }

  .docs_breadcrumbs .green:hover {
    color: #3f7e39;
  }
}

// onboarding docs
.container {
  @media screen and (min-width: 1024px) {
    width: 1024px;
  }
}

.onboarding-docs, .resource-order-form {
  display: flex;
  min-height: 100vh;
  // background-color: #fff;

  a {
    cursor: pointer !important;
  }

  .doc-home {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .no_padding {
    padding: 0 !important;
  }

  // w4 new
  .no_margin {
    margin: 0 !important;
  }

  .float_border,
  .float_border-f {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: relative;
      bottom: -24px;
      left: 30px;
      right: 30px;
      max-width: calc(100% - 30px);
      height: 1px;
      background: #dadada;
      @media screen and (max-width: 1024px) {
        max-width: 100%;
        left: 0;
      }
    }

    .float_border-f {
      &:after {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }

  .mt-0 {
    margin-top: 0px !important;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  .no_border {
    border: 0 !important;
  }

  .no_border {
    border: 0 !important;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  ol {
    // w4 new
    padding-left: 15px;

    & > li {
      line-height: 23px;
      margin-bottom: 12px;
      // list-style-type: lower-alpha;
      padding-left: 10px;
    }

    &.checkb_lists {
      counter-reset: checklist_count;
      list-style: none;
      padding-left: 0;

      & > li {
        counter-increment: checklist_count;
        line-height: 23px;
        margin-bottom: 12px;
        display: block;
        padding-left: 30px;
        position: relative;

        &:before {
          content: counter(checklist_count) ".";
          position: absolute;
          font-weight: 600;
          left: 5px;
        }
      }
    }

    ul {
      margin-top: 15px;

      &.checkb_lists {
        li {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        &:before {
          display: block;
          flex-basis: 100%;
          height: 5px;
          content: "";
        }
      }
    }
  }

  .disabled,
  .discl_input {
    background: #f9f9f9;
  }

  textarea {
    &.discl_input {
      min-height: 72px;
    }
  }

  .error_label {
    font-family: "Montserrat", sans-serif !important;
    margin-bottom: 20px !important;
    color: rgba(223, 70, 67, 0.5) !important;
    margin-left: 0px !important;
  }

  .error {
    border: 1px solid rgba(223, 70, 67, 0.5);
    background-image: url($assetsUrl + "/icons/error-icon.svg") !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 15px) center;
  }

  select.error {
    background-position: calc(100% - 15px) center;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(32, 32, 32, 0.32);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(32, 32, 32, 0.32);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(32, 32, 32, 0.32);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(32, 32, 32, 0.32);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }

  ::placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }

  /*custom data tables*/
  .data_table {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0px 24px;

    @media screen and (max-width: 767px) {
      padding: 0;
    }

    /*master styling*/
    .data_table-head,
    .data_table-info {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-evenly;
      padding: 18px 20px 14px;

      @media screen and (max-width: $device-med) {
        padding: 18px 5px 14px;
      }

      .item {
        flex: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .data_table-head {
      background: #f3f3f3;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-weight: 600;
    }

    .data_table-info {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      &:nth-child(odd) {
        background: rgba(216, 216, 216, 0.2);
      }
    }

    /*specific table*/
    &.dates {
      .item {
        &:first-child {
          max-width: 160px;

          @media screen and (max-width: $device-med) {
            max-width: 70px;
          }
        }

        &:last-child {
          max-width: 120px;

          @media screen and (max-width: $device-med) {
            max-width: 90px;
          }
        }
      }
    }

    &.holidays {
      .item {
        &:first-child {
          @media screen and (min-width: ($device-med+1)) {
            min-width: 200px;
          }

          @media screen and (max-width: $device-med) {
            flex: 250%;
            max-width: 160px;
          }
        }
      }
    }
  }

  /*custom data tables end*/

  .col-vert {
    flex-direction: column !important;
  }

  .separator {
    width: 100%;
    height: 1px;
    position: relative;

    &:after {
      border-top: 1px solid $borderB;
      position: absolute;
      content: "";
      height: 1px;
      left: -10px;
      right: -10px;
    }
  }

  .spacer {
    margin-bottom: 20px;
  }

  .price_input {
    padding-left: 55px !important;
    background-image: url($assetsUrl + "/icons/usd.svg");
    background-repeat: no-repeat;
    background-position: 12px center;

    @media screen and (max-width: $device-med) {
      padding-left: 35px !important;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    line-height: 23px;
    margin-bottom: 20px;

    strong {
      font-weight: 600;
    }

    // w4 new
    &.w-list {
      text-indent: -24px;

      strong {
        margin-right: 12px;
      }
    }
  }

  .doc-home-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
    padding-bottom: 50px;
    padding-top: 50px;

    img {
      &.doc-home-img {
        margin-bottom: 48px;
      }
    }

    .doc-username {
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 0px;
    }
    .doc-btn {
      padding: 5px 15px;
    }

    p {
      margin-bottom: 23px;
      max-width: 540px;
    }
  }

  .doc_info {
    display: flex;
    width: 100%;
    background-color: #fff;

    // tabs and search
    .tabs-bar {
      display: flex;
      // background: #f7f9fa;
      align-items: center;
      padding: 0px 20px;
      padding-left: 0;

      @media screen and(max-width: $device-med) {
        flex-direction: column;
        padding-left: 20px;
      }

      .doc-tabs {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
          padding: 22px 30px;
          border-bottom: 2px solid transparent;

          @media screen and(max-width: $device-med) {
            padding: 10px 20px;
          }

          @media screen and(max-width: $device-sm) {
            padding: 10px 12px;
          }

          &.active {
            border-bottom: 2px solid $green;
          }
        }
      }

      .search_filters {
        margin-left: auto;
        display: flex;
        align-items: center;
        max-width: 375px;
        .clear-btn {
          margin-right: 1rem;
        }

        @media screen and(max-width: $device-med) {
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
          margin-bottom: 20px;
          width: 100%;
        }

        input {
          margin: 0;
          padding: 8px 18px;
          margin-left: 12px;
        }

        button {
          min-width: 78px;
          padding: 7px 8px;
          background: #fff;
          border: 1px solid $borderB;
          position: relative;
          // padding: 5px 15px;
          &:focus {
            outline: none;
          }

          span {
            margin-left: 8px;
          }

          &.filter-open {
            &:after {
              content: "";
              width: 20px;
              height: 20px;
              bottom: -17px;

              @media screen and (max-width: $device-med) {
                bottom: -23px;
              }

              left: 50%;
              transform: translateX(-50%);
              display: block;
              position: absolute;
              background: url($assetsUrl + "/icons/doc-filter-ax.svg") no-repeat center;
            }
          }
        }
      }
    }

    .doc-title {
      display: flex;
      border-bottom: $borderB 1px solid;
      position: relative;
      height: 66px;
      align-items: center;
      width: 100%;
    }

    .doc_info-lh {
      width: 252px;
      background: $greyB;
      border-right: 1px solid $borderB;

      &.doc-list-lh {
        background: #fff;
        box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
      }

      .doc-title {
        padding-left: 34px;
        padding-right: 18px;

        h3 {
          font-size: 13px;
          font-weight: 700;
          margin: 0;

          span {
            font-weight: 400;
            background: $green;
            color: #fff;
            width: 17px;
            height: 17px;
            border-radius: 100px;
            font-size: 9px;
            display: inline-block;
            line-height: 2;
            text-align: center;
            vertical-align: text-bottom;
          }
        }

        .back-ico {
          margin-left: auto;
        }
      }

      .doc-statusbar {
        padding: 24px 30px 24px 36px;

        &.doc-listing {
          padding: 0;

          li {
            border-left: none !important;
          }
        }
      }

      .doc-status-list,
      .doc-lists {
        list-style: none;
        padding: 0;
        .hist_list {
          &:before {
            display: none;
          }
          p {
            font-weight: normal;
          }
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 34px;
        }
        .doc-active {
          .active_green_text {
            color: #3F7E39;
          }
        }
        a {
          &:last-child {
            li {
              border-left: none;
            }
          }
        }

        li {
          padding-bottom: 48px;
          padding-left: 20px;
          padding-right: 20px;
          border-left: 1px solid $borderB;
          position: relative;
          z-index: 1;
          color: rgba(0, 0, 0, 0.5);

          a {
            color: rgba(0, 0, 0, 0.5);
          }

          &:before {
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid $borderB;
            background-color: white;
            display: block;
            border-radius: 20px;
            position: absolute;
            left: -8px;
            z-index: 9;
          }

          &:last-child {
            // border: none;
          }

          &.doc-active {
            &:before {
              border: 1px solid $green !important;
              // background-image: url("../images/doc-active.svg");
              background-image: url($assetsUrl + "/icons/doc-active.svg");
              background-position: center 0px;
              background-repeat: no-repeat;
              background-size: 16px;
            }

            color: #000;
            font-weight: 700;

            a {
              color: #000;
              font-weight: 700;
            }
          }

          &.doc-complete {
            border-left: 1px solid rgba(81, 137, 76, 0.5);
            color: #000;
            font-weight: 400;

            a {
              color: #000;
              font-weight: 400;
            }

            &:before {
              border: 1px solid $green;
              background-color: $green;
              // background-image: url("../images/doc-complete.svg");
              background-image: url($assetsUrl + "/icons/doc-complete.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 8px;
            }
          }
        }
      }

      .doc-lists {
        li {
          border: none;
          color: #000;
          padding-top: 24px;
          padding-bottom: 24px;
          padding-left: 55px;
          line-height: 24px;

          .disabled {
            opacity: 0.4;
          }
          p {
            margin: 0;
            width: calc(100% - 20px);
            display: inline-block;
          }
          .doc-list-item_edit {
            position: absolute;
            position: absolute;
            /* right: 15px; */
            right: 0;
            background-color: transparent;
            display: inline-block;
            padding: 0 15px;
            min-width: 10px;
            outline: none;
            border: 0;
          }

          &.doc-active {
            background: #e2e2e2;
            font-weight: 700;

            &:after {
              display: none;
            }
          }

          &:before {
            left: 32px;
            border: 1px solid $green;
            background: url($assetsUrl + "/icons/doc-list-tick.svg") no-repeat center/8px;
            top: 28px;
          }

          &:after {
            content: "";
            background: #e2e2e2;
            display: block;
            right: 10px;
            height: 1px;
            position: absolute;
            left: 12px;
            bottom: -1px;
          }
        }
      }
    }
    // LH ends

    .doc_info-rh,
    .filter_form {
      display: flex;
      flex-direction: column;
      @media screen and (mix-width: $device-tab) {
        width: calc(100% - 252px);
      }

      .doc-title {
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        @media screen and (max-width: 768px) {
          h3 {
            flex-grow: 1;
          }
          .close-button {
            display: inline-block;
            flex-basis: 50px;
            opacity: 0.5;
            padding: 1px;
            img {
              display: block;
            }
          }
        }
        @media screen and (min-width: 769px) {
          .close-button {
            display: none;
          }
        }

        @media screen and (min-width: 767px) and (max-width: 768px) {
          padding-left: 25px;
          padding-right: 25px;
        }

        @media screen and (max-width: 480px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        .doc-title-img {
          margin-right: 12px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        h3 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 700;
          @media screen and (max-width: 480px) {
            max-width: calc(100% - 120px);
          }
          // new w-4
          .opt_text {
            color: #fff;
            background: #202020;
            border: 3px solid #202020;
            padding: 5px 10px;
            border-radius: 3px;
            margin-top: 0px;
            margin-left: 5px;
            font-weight: 700;
            font-size: 10px;
            position: absolute;
            top: 20px;
          }
        }
      }

      .doc-page-count {
        display: flex;
        margin-left: auto;

        span {
          padding: 10px;
          font-size: 12px;
          border: 1px solid transparent;
          padding: 8px;
          color: #8f8f8f;

          &.current_page {
            background: $greyB;
            border: 1px solid $borderB;
            border-radius: 3px;
            padding: 8px 14px;
            color: #202020;
          }
        }
      }

      .left_non_auto {
        margin-left: 10rem !important;
      }
      .doc-wrapper-rs {
        min-height: 78vh !important;
        padding: 12px 12px 0px 12px !important;
        .doc-page {
          margin-bottom: 0px !important;
        }
      }
      .doc-wrapper {
        border: 1px solid $borderB;
        margin: 20px 20px 30px 20px;
        min-height: 70vh;
        max-height: 100vh;
        overflow-y: scroll;
        position: relative;
        // overflow-x: hidden;
        background: #efefef;
        padding: 40px 20px 0px 20px;

        .doc-pdf-loader {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 20px;
          height: 20px;
          transform: translate(-50%, -50%);
          img {
            width: 100%;
          }
        }

        .doc-page {
          margin-bottom: 20px;
        }
      }

      .doc-pdf-buttons {
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: center;

        span {
          font-size: 10px;
        }

        button {
          background: transparent;
          margin: 0 3rem;
          color: #ccc;
          padding: 0;

          @media screen and (max-width: 767px) {
            margin: 0 1rem;
          }

          &.pdf_prev,
          &.pdf_next {
            font-size: 10px;
          }

          &.pdf_prev {
            @media screen and (max-width: 1024px) {
              margin-right: auto;
            }
          }

          &.pdf_next {
            @media screen and (max-width: 1024px) {
              margin-left: auto;
            }
          }

          &:hover {
            color: #3f7e39;
            font-weight: 700;
          }
        }
      }

      .doc-approve-btns {
        padding: 1rem 1.5rem;
        margin-bottom: 2rem;
        margin-left: auto;

        @media screen and (max-width: $device-med) {
          padding: 0px 12px;
          margin: 0 auto;
          display: flex;
        }

        button {
          max-height: 30px;
          padding: 5px 10px;
          min-width: 115px;
          background: #747474 !important;

          @media screen and (max-width: $device-sm) {
            min-width: 100px;
            min-height: 35px;
          }

          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          &.enabled {
            background: $green !important;
          }
        }
      }

      .doc_info-form {
        display: flex;
        flex-direction: column;
        padding: 30px 50px;

        @media screen and (max-width: 480px) {
          padding-right: 10px;
          padding-left: 10px;
        }

        @media screen and (min-width: 767px) and (max-width: 768px) {
          padding-left: 25px;
          padding-right: 25px;
        }

        &.worksheet {
          padding-top: 0;
        }

        .info-form {
          display: flex;
          flex-direction: column;
        }

        h6.inputs-head {
          font-weight: 600;
          margin-top: 0;
          line-height: 23px;
          font-size: 13px;
        }

        .row {
          &.form-row {
            margin: 0;
            padding-top: 24px;
            border-top: 1px solid $borderB;

            // new W4
            &.bg_grey {
              background-color: #f3f3f3;
              margin-bottom: 30px;
              border-top: 0px;
            }

            &:first-child {
              border-top: none;
              padding-top: 0;
            }

            &.last {
              border: none;
              padding-top: 0;
            }

            &.no_border {
              border: none;
            }

            &.worksheet {
              padding-bottom: 24px;

              .price_input {
                padding-left: 40px !important;

                // w-4 new
                &.step_count,
                &.step_counter {
                  padding-left: 76px !important;
                  background-position: 50px center;
                }
              }

              .col {
                margin-bottom: 0;

                &.col-vert {
                  flex-direction: column;

                  li {
                    margin-top: 18px;
                    line-height: 23px;
                  }
                }
              }

              h3 {
                margin-top: 0;
                margin-bottom: 0;
              }

              p {
                &.heading-note {
                  margin-top: 16px;
                  margin-bottom: 0;
                }
              }

              .inputs-col {
                display: flex;
                align-items: center;

                @media screen and (max-width: $device-med) {
                  flex-direction: column;
                }

                .inputs-head {
                  margin-bottom: 0;
                  font-weight: 400;
                  max-width: calc(100% - 250px);

                  @media screen and (max-width: $device-med) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 10px;
                  }

                  position: relative;

                  ul {
                    margin-bottom: 0;
                    padding-left: 40px;
                    @media screen and (max-width: 1024px) {
                      padding-left: 15px;
                    }

                    li {
                      margin-top: 18px;
                      line-height: 23px;
                    }
                  }

                  p {
                    padding-left: 28px;
                    margin-bottom: 0px;
                  }

                  span {
                    color: #000;
                    position: absolute;
                    left: 0;
                  }
                }

                .inputs-items {
                  margin-left: auto !important;
                  max-width: 191px;

                  // w4 new
                  position: relative;

                  &.steps_input {
                    .stepcounter {
                      background: rgba(218, 221, 221, 0.4);
                      position: absolute;
                      color: rgba(0, 0, 0, 0.35);
                      top: 0;
                      left: 0;
                      height: 40px;
                      width: 36px;
                      position: absolute;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-bottom-left-radius: 3px;
                      border-top-left-radius: 3px;
                    }
                  }

                  @media screen and(max-width: $device-med) {
                    max-width: 100%;
                    margin-left: 0 !important;
                    width: 100%;
                  }

                  select,
                  input,
                  textarea {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .col {
          display: flex;
          width: 100%;
          margin-bottom: 16px;

          // w4 new
          &.li-parentcol {
            align-items: baseline;
            flex-direction: row;
          }

          &.li-col {
            width: 30px;
            min-width: 30px;
            align-self: top;
            justify-content: flex-end;
            &.p-zero{
              @media (max-width: 768px) {
              padding: 0 !important;
              }
            }
          }

          @media screen and (max-width: $device-med) {
            flex-direction: column;
          }

          // passport check section input custom styling
          &.f_passport_check {
            .inputs-col {
              input {
                max-width: 330px;
              }

              &:last-child {
                @media screen and (max-width: $device-med) {
                  padding-left: 30px;
                }

                label {
                  @media screen and (max-width: $device-med) {
                    margin-left: 0 !important;
                  }
                }
              }
            }
          }

          &.head-info-col {
            align-items: stretch;
            margin-bottom: 10px;
            margin-top: 10px;

            @media screen and (max-width: $device-med) {
              flex-direction: row;
              flex-wrap: wrap;
            }

            .head-info {
              flex-basis: 100%;
              padding: 20px;
              width: 100%;

              h3 {
                margin-bottom: 8px;
              }

              @media screen and (max-width: $device-med) {
                padding: 10px 20px;
                text-align: center;
                order: 3;
              }

              &:first-child,
              &:last-child {
                flex-basis: 130px;
                min-width: 130px;
                padding-left: 10px;
                padding-right: 5px;

                @media screen and (max-width: $device-med) {
                  flex-basis: 0;
                  padding: 10px 15px;
                  flex: 50% 0;
                }
              }

              &:first-child {
                border-right: 1px solid $borderB;

                @media screen and (max-width: $device-med) {
                  border-right: none;
                  border-bottom: 1px solid $borderB;
                  order: 1;
                }
              }

              &:last-child {
                border-left: 1px solid $borderB;
                padding-right: 15px;
                text-align: right;

                .year {
                  padding-right: 8px;
                }

                @media screen and (max-width: $device-med) {
                  border-left: none;
                  border-bottom: 1px solid $borderB;
                  order: 2;
                }
              }
            }

            p {
              font-size: 8px;
              line-height: 14px;
              margin-bottom: 0;

              &.year {
                margin-top: 12px;
              }

              span {
                font-size: 24px;
                font-weight: 500;
                margin-left: 5px;
              }
            }

            h3 {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          .disclaimers {
            background: #f2f2f2;
            padding: 25px 35px;
            font-size: 13px;
            margin-top: 10px;
            line-height: 23px;
            color: rgba(0, 0, 0, 0.6);

            .diss {
              font-weight: 600;
              color: rgba(0, 0, 0, 0.6);
            }

            a {
              font-weight: 600;
              color: rgba(0, 0, 0, 0.6);
              font-size: 13px;
            }

            &.dis-notes {
              position: relative;
              width: 100%;
              margin-bottom: 20px;

              .inputs-head {
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
              }

              .toggler_btn {
                position: absolute;
                top: 5px;
                right: 0px;
                background-image: url($assetsUrl + "/icons/doc-toggler.svg");
                background-repeat: no-repeat;
                background-color: transparent;
                width: 15px;
                min-width: 15px;
                height: 8px;
                padding: 0;
                transform: rotateX(180deg);

                &.toggled {
                  transform: rotate(0deg);
                }
              }

              ol {
                &.checkb_lists {
                  padding-left: 25px;
                }
              }

              p {
                margin-bottom: 10px;
                margin-top: 10px;
                color: #000;
              }

              li {
                margin-bottom: 10px;

                p {
                  padding-left: 0;
                }
              }

              .separator {
                height: 2px;
                margin-bottom: 18px;
              }

              p,
              .inputs-head {
                padding-left: 20px;
                padding-right: 20px;
              }

              p {
                &.notes {
                  color: rgba(0, 0, 0, 0.5);
                  line-height: 23px;
                }
              }

              ul {
                padding-left: 40px;
              }
            }
          }

          .notes {
            color: #7f7f7f;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.5);
          }

          .inputs-col {
            width: 100%;

            .sign_here {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;
              min-height: 144px;
              // max-height: 144px;

              .sign_canvas {
                min-height: 144px;
                width: 100%;
                position: relative;
                // overflow: hidden;

                .sign_placeholder {
                  position: relative;
                  margin-bottom: 15px;
                  border: 1px solid #d5d8d9;
                  border-radius: 3px;
                  left: 0;
                  top: 0;
                  &:before {
                    content: "Sign here";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: rgba(0, 0, 0, 0.32);
                    transform: translate(-50%, -50%);
                  }
                  &.has_signed {
                    &:before {
                      content: "";
                    }
                  }

                  img {
                    object-fit: scale-down;
                    // object-position: center;
                  }
                }

                // canvas {
                //   height: 144px;
                //   width: 100%;
                //   margin-bottom: 14px;
                // }

                /* The Modal (background) */
                .sign_modal {
                  display: none;
                  /* Hidden by default */
                  position: fixed;
                  /* Stay in place */
                  z-index: 9999;
                  /* Sit on top */
                  left: 0;
                  top: 0;
                  width: 100%;
                  /* Full width */
                  height: 100%;
                  /* Full height */
                  overflow: hidden;
                  /* Enable scroll if needed */
                  background-color: rgb(0, 0, 0);
                  /* Fallback color */
                  background-color: rgba(0, 0, 0, 0.4);

                  /* Black w/ opacity */
                  /* Modal Content/Box */
                  .sign_modal-content {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;

                    section {
                      background-color: #fefefe;
                      padding: 1.5rem;
                      border: 1px solid #ccc;
                      border-radius: 3px;

                      /* The Close Button */
                      .sign_close {
                        color: rgb(32, 32, 32);
                        font-size: 16px;
                        display: flex;
                        justify-content: space-between;
                        margin: 0;
                        font-weight: bold;

                        span {
                          font-size: 24px;
                        }

                        & > span:hover,
                        & > span:focus {
                          color: black;
                          text-decoration: none;
                          cursor: pointer;
                        }
                      }

                      canvas {
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        margin: 1.5rem 0;
                      }

                      .sign_buttons {
                        display: flex;
                        margin: 0;
                        padding: 5px 0;
                        @media screen and (max-width: 768px) {
                          flex-wrap: wrap;
                          button {
                            width: 100%;
                            margin-bottom: 1rem;
                          }
                        }

                        button {
                          border: none;
                          border-radius: 2px;
                          background: #fff;
                          padding: 5px 15px;
                          margin-right: 10px;
                          cursor: pointer;
                          font-weight: bold;
                          transition: all 0.2s ease-in;

                          &.primary {
                            background-color: $green;
                            color: #fff;
                          }

                          &.primary:hover {
                            color: #ccc;
                          }

                          &.secondary {
                            background-color: #ccc;
                            color: #888;
                          }

                          &.secondary:hover {
                            color: #222;
                          }
                          &.outlined {
                            background-color: #fff;
                            border: 1px solid #ccc;
                            color: #888;
                          }

                          &.outlined:hover {
                            border: 1px solid #888;
                            color: #222;
                          }

                          &.disabled {
                            background-color: #bdbdbd;
                            color: #888;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .sign_btn-group {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 1024px) {
                  flex-direction: column;
                }

                button {
                  min-width: 100px;
                  background: #cfd0d2;

                  @media screen and (max-width: 1024px) {
                    width: 100%;
                  }
                }
              }

              .aplcn-next-btn {
                margin-left: 10px;

                @media screen and (max-width: 1024px) {
                  width: 100%;
                  margin-left: 0;
                  margin-top: 10px;
                }
              }
            }

            &:first-child {
              .inputs-items {
                margin-left: 0px !important;
              }

              .inputs-head {
                padding-left: 0px !important;
              }
            }

            &:last-child {
              .inputs-items {
                margin-right: 0px !important;
              }

              .inputs-items + p {
                margin-left: 6px !important;

                @media screen and (max-width: $device-med) {
                  margin-left: 0 !important;
                }
              }

              .inputs-head {
                padding-right: 0px !important;
              }
            }

            .check_radio_group {
              display: flex;
              flex-direction: row;

              &.check_disclaimer,
              &.check_exempt {
                justify-content: center;
                align-items: center;

                .check_radio_item {
                  display: flex;
                  align-items: baseline;

                  label {
                    color: #000;
                    line-height: 22px;
                  }

                  input[type="checkbox"] {
                    width: auto;
                    border: none !important;
                    opacity: 0;
                    z-index: 2;

                    // &:before {
                    //   content: "";
                    //   display: block;
                    //   width: 16px;
                    //   height: 16px;
                    //   background-color: #fff;
                    //   border-radius: 2px;
                    //   border: 1px solid #9d9d9d;
                    //   position: relative;
                    //   left: -1px;
                    // }
                  }
                  input[type="checkbox"] ~ .checkbox_checkbox {
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-color: #fff;
                    border-radius: 2px;
                    border: 1px solid #9d9d9d;
                    position: absolute;
                    z-index: 0;
                    top: 4px;
                  }

                  input[type="checkbox"]:checked ~ .checkbox_checkbox {
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-color: $green;
                    background-image: url($assetsUrl + "/icons/doc-complete.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 2px;
                    border: 1px solid #9d9d9d;
                  }
                  // input[type="checkbox"]:checked {
                  //   &:before {
                  //     content: "";
                  //     display: block;
                  //     width: 16px;
                  //     height: 16px;
                  //     background-color: $green;
                  //     border-radius: 2px;
                  //     border: 1px solid #9d9d9d;
                  //   }

                  //   &:after {
                  //     content: "";
                  //     display: block;
                  //     width: 8px;
                  //     height: 8px;
                  //     // background: url("../images/doc-complete.svg") center
                  //     //   no-repeat;
                  //     background: url($assetsUrl + '/icons/doc-complete.svg') no-repeat center;

                  //     border-radius: 16px;
                  //     top: 8px;
                  //     left: 3px;
                  //     position: absolute;
                  //   }
                  // }
                }
              }

              &.check_exempt {
                justify-content: left;
              }

              &.agreement_checks {
                flex-direction: column;

                .check_radio_item {
                  display: flex;
                }

                .inputs-col {
                  margin-top: 10px;
                  padding-left: 31px;
                  max-width: 330px;

                  @media screen and (max-width: $device-med) {
                    max-width: 360px;
                  }

                  &.inputs-col-g {
                    max-width: 100%;
                    padding-right: 31px;

                    textarea {
                      min-height: 72px;
                    }
                  }
                }

                ul {
                  &.checkb_lists {
                    padding-left: 31px;
                    list-style: none;

                    li {
                      line-height: 23px;
                      margin-bottom: 15px;
                      display: flex;

                      span {
                        margin-right: 12px;
                        color: #000;
                        font-weight: 600;
                      }
                    }
                  }
                }

                ol {
                  &.checkb_lists {
                    counter-reset: checklist_count;
                    list-style: none;
                    padding-left: 32px;

                    & > li {
                      counter-increment: checklist_count;
                      line-height: 23px;
                      margin-bottom: 15px;
                      display: flex;

                      &:before {
                        content: counter(checklist_count);

                        font-weight: 600;
                        margin-right: 20px;
                      }
                    }
                  }

                  ul {
                    &.checkb_lists {
                      &:before {
                        display: block;
                        flex-basis: 100%;
                        height: 5px;
                        content: "";
                      }
                    }
                  }
                }

                p {
                  &.checkb_detail {
                    padding-left: 31px;
                  }
                }

                input[type="checkbox"] {
                  width: auto;
                  height: 13px;
                  position: relative;
                  z-index: 2;
                  margin-left: 4px;
                  opacity: 0;
                }

                input[type="checkbox"] ~ .checkbox_checkbox {
                  display: block;
                  width: 20px;
                  height: 20px;
                  background-color: #fff;
                  border-radius: 2px;
                  border: 1px solid #9d9d9d;
                  position: absolute;
                  z-index: 0;
                  top: 0px;
                  left: 0px;
                }
                input[type="checkbox"]:focus ~ .checkbox_checkbox {
                  border: 1px solid #656565;
                }
                input[type="checkbox"]:checked ~ .checkbox_checkbox {
                  background-color: #000;
                  background-image: url($assetsUrl + "/icons/doc-complete.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                }

                label {
                  color: #000;
                  margin-left: 16px;
                  line-height: 23px;
                  font-weight: 400;

                  span {
                    font-weight: 500;
                  }
                }
              }

              &.agreement_radio {
                flex-direction: column;
                margin-top: 20px;

                .check_radio_item {
                  margin-left: 30px;
                  margin-bottom: 24px;
                  display: flex;
                  flex-wrap: wrap;

                  input[type="radio"] {
                    margin-top: 0;
                  }

                  input[type="radio"] ~ .radio_checkbox {
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    border: 1px solid #9d9d9d;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                  }
                  input[type="radio"]:checked ~ .radio_checkbox {
                    display: block;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #000;
                    background-color: #000;
                    &:before {
                      position: absolute;
                      background: #fff;
                      width: 4px;
                      height: 4px;
                      content: "";
                      display: block;
                      top: 7px;
                      left: 7px;
                      border-radius: 4px;
                    }
                  }

                  label {
                    color: #000;
                    margin-left: 16px;
                  }

                  span {
                    &.notes {
                      width: 100%;
                      margin-left: 25px;
                      margin-top: 6px;
                    }
                  }
                }
              }

              .check_radio_item {
                position: relative;
              }

              input[type="radio"] {
                width: 20px;
                position: relative;
                z-index: 2;
                opacity: 0;
              }
              input[type="radio"] ~ .radio_checkbox {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                border: 1px solid #9d9d9d;
                position: absolute;
                top: 4px;
                left: 0;
                z-index: 0;
              }
              input[type="radio"]:checked ~ .radio_checkbox {
                display: block;
                width: 20px;
                height: 20px;
                border: 1px solid #000;
                background-color: #000;
                &:before {
                  position: absolute;
                  background: #fff;
                  width: 4px;
                  height: 4px;
                  content: "";
                  display: block;
                  top: 7px;
                  left: 7px;
                  border-radius: 4px;
                }
              }

              input[type="radio"] {
                &.discl_input {
                  &:checked {
                    &:before {
                      border: 1px solid #8f8f8f;
                      background-color: #8f8f8f;
                    }
                  }
                }
              }

              input[type="checkbox"] {
                &.discl_input {
                  &:checked {
                    &:before {
                      border: 1px solid #8f8f8f;
                      background-color: #8f8f8f;
                    }
                  }
                }
              }

              &.gender_radio {
                input[type="radio"] {
                  position: relative;
                  z-index: 2;
                  top: 3px;
                  left: 3px;
                  opacity: 0;
                }
                input[type="radio"] ~ .radio_checkbox {
                  display: block;
                  width: 20px;
                  height: 20px;
                  border-radius: 20px;
                  border: 1px solid #9d9d9d;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 0;
                }
                input[type="radio"]:checked ~ .radio_checkbox {
                  display: block;
                  width: 20px;
                  height: 20px;
                  border: 1px solid #000;
                  background-color: #000;
                  &:before {
                    position: absolute;
                    background: #fff;
                    width: 4px;
                    height: 4px;
                    content: "";
                    display: block;
                    top: 7px;
                    left: 7px;
                    border-radius: 4px;
                  }
                }
              }

              input[type="radio"]:checked ~ label {
                color: #000;
              }

              label {
                margin-left: 10px;
                margin-right: 30px;
                font-family: "Montserrat", sans-serif;
                color: rgba(32, 32, 32, 0.32);
                width: auto;
                float: none;
              }
            }

            .inputs-head {
              font-weight: 700;
              margin-bottom: 14px;
              padding-left: 6px;
              padding-right: 6px;

              span {
                font-weight: 500;
                color: #b2b2b2;
              }

              @media screen and (max-width: $device-med) {
                padding-left: 0;
                padding-right: 0;
              }
            }

            @media screen and (max-width: $device-med) {
              margin-bottom: 8px;
            }

            .two-item {
              flex-wrap: wrap;

              .grouped {
                width: calc(50% - 12px) !important;
                margin-right: 6px;
                margin-left: 6px;

                @media screen and (max-width: $device-med) {
                  width: 100% !important;
                }
              }

              //input, input[type=email], input[type=password],input[type=text] , select, textarea{

              //   @media screen and (max-width: $device-med){
              //         width: 100%;
              //   }
            }

            .inputs-items {
              display: flex;
              margin-right: 6px;
              margin-left: 6px;
              flex-direction: column;
              .react-datepicker-wrapper {
                display: flex;
                width: 100%;
                .react-datepicker__input-container {
                  display: flex;
                  width: 100%;
                }
              }

              input,
              input[type="email"],
              input[type="password"],
              input[type="text"],
              select,
              textarea {
                margin-bottom: 8px;
                padding: 10px 18px;
                color: #202020;
                font-weight: 500;
              }

              input[type="text"] {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              input#employee_name {
                width: 100%;
                max-width: 100%;
              }

              textarea {
                min-height: 108px;
              }

              select {
                appearance: none;
                background-image: url($assetsUrl + "/icons/select-arrow.svg");
                background-repeat: no-repeat;
                background-position: calc(100% - 12px) center;
                padding-right: 25px;
                option {
                  border-radius: 3px;
                }
              }

              @media screen and (max-width: $device-med) {
                flex-direction: column;
                margin-left: 0;
                margin-right: 0;
              }

              &.group {
                margin-left: -6px !important;
                margin-right: -6px !important;
                flex-direction: row;

                @media screen and (max-width: $device-med) {
                  margin-left: 0 !important;
                  margin-right: 0 !important;
                  margin-bottom: 10px;
                  flex-direction: column;
                }

                //input, input[type=email], input[type=password],input[type=text] , select, textarea{
                .grouped {
                  margin-right: 6px;
                  margin-left: 6px;
                  min-height: 36px;
                  width: 100%;

                  @media screen and (max-width: $device-med) {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 0;
                    border-radius: 0px;
                    border-bottom: none;

                    /* &:last-child{
                                            // input, input[type=email], input[type=password],input[type=text] , select, textarea{
                                            //     border-bottom: 1px solid #d0d3d4;
                                            //     border-radius:  0px 0px 5px 5px;
                                            // }
                                            
                                        }
                                        &:first-child{
                                            // input, input[type=email], input[type=password],input[type=text] , select, textarea{
                                            //     border-radius:  5px 5px 0px 0px;
                                            // }
                                            
                                        }
                                        */
                  }
                }
              }
            }

            // input items end
          }

          // input col end
        }

        // col ends
        .form_buttons {
          display: flex;
          position: relative;
          padding-top: 25px;
          padding-bottom: 35px;
          justify-content: center;

          button {
            max-height: 30px;
            padding: 5px 15px;

            &.enabled {
              background: $green !important;
            }
          }

          .aplcn-next-btn {
            margin-left: auto;
            background: $green !important;

            &:disabled {
              background: #cfd0d2 !important;
            }
          }

          .aplcn-prev-button {
            background: $green !important;

            &:disabled {
              background: #cfd0d2 !important;
            }
          }
        }

        .action_buttons {
          display: flex;
          justify-content: space-between;
          position: relative;
          padding-top: 25px;
          padding-bottom: 35px;
          padding-left: 0;
          padding-right: 0;
          @media screen and (max-width: 960px){
            align-items: center;
            flex-direction: column;
          }
          button{
            @media screen and (max-width: 960px){
              width: 100%;
            }
          }
          .pagination-number{
            @media screen and (max-width: 960px){
              order: 2;
            }
          }
          & .btn-grouped {
            @media screen and (max-width: 960px){
              order: 1;
              margin-top: 15px;
              width: 100%;
            }
            button {             
              @media screen and (min-width: 961px){
                margin-right: 1rem;
              }
              @media screen and (max-width: 960px){
                margin-bottom: 15px;
                width: 100%;
              }
            }
            @media screen and (max-width: 960px){
              align-items: center;
              flex-direction: column;
            }
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            top: 0;
            left: -50px;
            right: -50px;
            background: $borderB;

            @media screen and (min-width: 767px) and (max-width: 768px) {
              left: 0;
              right: 0;
            }
          }

          button {
            max-height: 30px;
            padding: 5px 15px;
            min-width: 115px;
            // background: #cfd0d2 !important;
            font-weight: 700;

            &.enabled {
              background: $green !important;
            }
          }

          .aplcn-next-btn {
            margin-left: auto;
            background: $green !important;

            &:disabled {
              background: #cfd0d2 !important;
            }
          }

          .aplcn-prev-button {
            background: $green !important;

            &:disabled {
              background: #cfd0d2 !important;
            }
          }
        }

        .action_buttons.w4 {
          display: flex;
          position: relative;
          padding-top: 25px;
          padding-bottom: 35px;
          padding-left: 0;
          padding-right: 0;

          &:before {
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            top: 0;
            left: -50px;
            right: -50px;
            background: $borderB;
          }

          button {
            max-height: 30px;
            padding: 5px 15px;
            min-width: 115px;
            // background: #cfd0d2 !important;
            font-weight: 700;

            &.enabled {
              background: $green !important;
            }
          }

          .aplcn-next-btn {
            margin-left: auto;
            background: $green !important;

            &:disabled {
              background: #cfd0d2 !important;
            }
          }
        }
      }
    }

    &.full-width_form {
      .doc_info-rh,
      .filter_form {
        width: 100%;
      }
    }

    .action_buttons {
      display: flex;
      position: relative;
      padding-top: 25px;
      padding-bottom: 35px;
      padding-left: 30px;
      padding-right: 30px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        top: 0;
        left: 0;
        right: 0;
        background: $borderB;
      }

      button {
        max-height: 30px;
        padding: 5px 15px;
        min-width: 115px;
        // background: #cfd0d2 !important;
        font-weight: 700;

        &.enabled {
          background: $green !important;
        }
      }

      .aplcn-next-btn {
        margin-left: auto;
        background: $green !important;

        &:disabled {
          background: #cfd0d2 !important;
        }
      }

      .aplcn-prev-button {
        background: $green !important;

        &:disabled {
          background: #cfd0d2 !important;
        }
      }
    }

    .doc_info-rh {
      &.emp-verify {
        width: 100%;

        .doc_info-form {
          padding: 15px 20px;
        }

        .form-row {
          border-top: none !important;
          padding-top: 0px !important;
        }

        p {
          margin-bottom: 10px;
        }

        .inputs-head {
          margin-bottom: 5px !important;
        }

        .doc-approve-btns {
          margin-left: 0;
          padding: 0;

          button {
            max-height: 40px;
            padding: 10px 15px;
          }
        }
      }
    }

    .w-50 {
      width: 50% !important;

      input {
        margin-right: 6px;
      }

      @media screen and (max-width: $device-med) {
        width: 100% !important;
      }

      &.sign-input {
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }

    .w-33 {
      width: 33.333% !important;

      @media screen and (max-width: $device-med) {
        width: 100% !important;
      }
    }

    .filter_form {
      width: 100% !important;
      // background: #fff;
      border: 1px solid $borderB;

      .doc_info-form {
        padding-bottom: 20px;

        .search_select {
          position: relative;

          ul {
            position: absolute;
            top: 100%;
            background-color: #fff;
            border: 1px solid #ccc;
            height: 200px;
            overflow-y: scroll;
            width: 100%;
            z-index: 99;
            padding: 0;

            li {
              list-style: none;
              padding: 10px 5px;
              border-bottom: 1px solid #eee;
              cursor: pointer;

              &:hover {
                background-color: #eee;
              }
            }
          }
        }

        @media screen and (max-width: $device-med) {
          padding-left: 20px;
          padding-right: 20px;
        }

        .row.form-row {
          border-top: none;
          padding-top: 0;
        }
      }
    }

    .doc-list-items {
      .doc_table-wrapper {
        .doc_table-items {
          display: flex;
          flex-direction: column;
          margin: 18px;
          padding-bottom: 83px;
          border: 1px solid #fbfbfb;
          position: relative;

          .first_item {
            max-width: 36px;
          }

          input[type="checkbox"] {
            width: auto;
            position: relative;
            margin-top: 0;

            &:before {
              content: "";
              display: block;
              width: 16px;
              height: 16px;
              background-color: #fbfbfb;
              border-radius: 2px;

              border: 1px solid #000;
            }
          }

          .item_cell {
            input[type="checkbox"] {
              &:before {
                border: 1px solid #c5c5c5;
              }
            }
          }

          input[type="checkbox"]:checked {
            &:before {
              content: "";
              display: block;
              width: 16px;
              height: 16px;
              background-color: $green !important;
              border-radius: 2px;
              border: 1px solid #9d9d9d;
            }

            &:after {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              // background: url("../images/doc-complete.svg") center no-repeat;
              background: url($assetsUrl + "/icons/doc-complete.svg") no-repeat center;
              border-radius: 16px;
              top: 4px;
              left: 4px;
              position: absolute;
            }
          }

          .appln_list_header {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            background: #f3f3f3;

            input[type="checkbox"] {
              &:before {
                background: #f3f3f3;
              }
            }

            .list_item_head {
              padding: 10px;
              padding-top: 20px;
              font-weight: 700;

              &:nth-child(2) {
                flex: 2;

                img {
                  opacity: 0;
                  margin-right: 12px;
                }
              }

              &:last-child {
                max-width: 100px;

                @media screen and (max-width: 700px) {
                }
              }
            }
          }

          .applicant_item {
            background: #fbfbfb;

            &.list_item {
              padding: 0;
            }

            &:nth-child(2n) {
              background: #f3f3f3;

              input[type="checkbox"] {
                &:before {
                  background: #f3f3f3;
                }
              }
            }
          }

          .basic_info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 !important;
            padding-right: 20px;

            .doc-list-img {
              margin-right: 10px;
              margin-top: 2px;
            }

            &.unavailable_employe {
              width: auto !important;
            }
            .created-at{
              @media screen and (max-width: 425px) {
                flex: unset !important;
                width: 100%;
                padding-top: 0 !important;
                margin: 0;
              }
            }

            div {
              flex: 1 0 !important;
              text-align: left;
            }

            .item_cell {
              flex: 1 0;
              padding: 20px 10px;

              /*span{
                            
                        }*/
              &:nth-child(2) {
                flex: 2 0 !important;
                display: flex;
                align-items: flex-start;
              }

              &:last-child {
                max-width: 100px;
                display: flex;
                justify-content: center;
              }

              // responsive customisation
              &:first-child {
                @media screen and (max-width: 640px) {
                  margin-bottom: 0px !important;
                }

                input[type="checkbox"] {
                  @media screen and (max-width: 640px) {
                    margin: 0px !important;
                  }
                }
              }

              &:nth-child(2) {
                @media screen and (max-width: 640px) {
                  flex: calc(50% - 18px) 0 !important;
                }

                @media screen and (max-width: 480px) {
                  flex: calc(100% - 36px) 0 !important;

                  padding-bottom: 10px;
                  margin-bottom: 0px;

                  span {
                    padding-right: 62px;
                    width: 100%;
                    text-align: center;
                  }
                }
              }

              &:nth-child(3) {
                @media screen and (max-width: 640px) {
                  flex: calc(50% - 18px) 0 !important;
                }

                @media screen and (max-width: 480px) {
                  flex: 100% 0 !important;

                  padding: 5px 15px !important;
                  text-align: center;
                  margin-bottom: 0px;
                }
              }

              &:nth-child(4) {
                @media screen and (max-width: 640px) {
                  flex: 100% 0 !important;
                  padding: 5px 15px !important;
                  text-align: center;
                }
              }

              &:last-child {
                @media screen and (max-width: 640px) {
                  flex: 100% 0 !important;
                  padding: 5px 15px !important;
                  margin-bottom: 20px;
                  margin-left: auto;
                  margin-right: auto;
                }
              }
            }
          }

          .doc_table-pagenaiton {
            display: flex;
            padding-top: 29px;
            padding-bottom: 24px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: #fff;

            ul {
              font-family: "Helvetica";

              &.pagination {
                margin: 0 auto;
                padding: 0;

                @media screen and (max-width: $device-sm) {
                  a {
                    padding: 6px 8px;
                  }
                }

                .previous,
                .next {
                  a {
                    width: 29px;
                    height: 29px;
                    background: #f9f9f9;
                    border: 1px solid $borderB;

                    @media screen and (max-width: $device-sm) {
                      width: 26px;
                      height: 26px;
                    }

                    img {
                      margin-bottom: 5px;
                    }
                  }
                }

                .next {
                  a {
                    img {
                      transform: rotateY(180deg);
                    }
                  }
                }

                li > a,
                li > span {
                  border: none;
                  color: rgba(0, 0, 0, 0.4);
                }

                li > a:focus {
                  outline: none;
                }

                li > a:hover {
                  background: none;
                }

                .active > a,
                .active > span,
                .active > a:hover,
                .active > span:hover,
                .active > a:focus,
                .active > span:focus {
                  background: transparent;
                  color: $green;
                }
              }
            }
          }

          .sort-arrow {
            cursor: pointer;
            width: 0;
            display: inline-block;
            height: 0;
            margin: 2px 5px;
            border-style: solid;
            line-height: 16px;
            border-width: 0 5px 5px;
            border-color: transparent transparent #000;
            opacity: 0.2;

            &.desc {
              opacity: 1;
              border-width: 0 5px 5px;
              border-color: transparent transparent #000;
            }

            &.asc {
              opacity: 1;
              border-width: 5px 5px 0;
              border-color: #000 transparent transparent;
            }
          }

          &.emp_view {
            @media screen and (max-width: $device-med) {
              .doc_table-items {
                min-width: 100%;
              }
            }

            .appln_list_header {
              @media screen and (max-width: 640px) {
                display: none;
              }
            }
          }

          &.admin_view {
            .doc_table-items {
              border: 1px solid $borderB;
              border-radius: 3px;
            }

            padding-bottom: 0px;

            .tab_list {
              list-style: none;
              display: flex;
              align-items: flex-start;
              padding: 0;
              margin: 0;
              border-bottom: 1px solid $borderB;
              background: #f7f9fa;

              li {
                color: #b3b4b6;
                background: #f7f9fa;
                padding: 12px 15px 23px 15px;
                border-right: 1px solid $borderB;
                position: relative;

                &.active {
                  color: #000;
                  background: #fff;

                  &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 1px;
                    background: #fff;
                  }
                }
              }
            }

            .appln_list_header {
              .list_item_head {
                padding: 10px;
                padding-top: 20px;
                font-weight: 700;

                &:first-child {
                  @media screen and (max-width: $device-m) {
                    flex: 50% 0;
                  }

                  img {
                    opacity: 0;
                    margin-right: 18px;

                    @media screen and (max-width: 640px) {
                      margin-right: 18px;
                    }
                  }
                }

                &:nth-child(2) {
                  flex: 1 0;
                  padding-left: 10px;
                }

                &:last-child {
                  max-width: 190px;

                  @media screen and (max-width: $device-med) {
                    display: none;
                  }
                }
              }
            }

            // .applicant_item div {
            //   width: 100%;
            // }

            .basic_info {
              .doc-list-img {
                margin-left: 7px;

                @media screen and (max-width: $device-m) {
                  margin-left: 0 !important;
                }
              }

              .item_cell {
                flex: 1 0;
                padding: 20px 10px;

                @media screen and (max-width: $device-m) {
                  padding-bottom: 5px;
                }

                span {
                  margin-top: 0;

                  @media screen and (max-width: 640px) {
                    padding-right: 0;
                  }
                }

                &:first-child {
                  display: flex;
                  align-items: flex-start;

                  @media screen and (max-width: $device-med) {
                    flex: 50% 0 !important;
                  }

                  @media screen and (max-width: $device-m) {
                    flex: 66% 0 !important;
                  }
                }

                &:nth-child(2) {
                  flex: 1 0 !important;
                  display: flex;
                  align-items: flex-start;

                  @media screen and (max-width: $device-m) {
                    flex: 25% 0 !important;
                  }
                }

                &:last-child {
                  max-width: 100px;
                  display: flex;
                  justify-content: center;
                }
              }
            }

            .doc_download {
              width: 160px;
              max-width: 190px !important;

              @media screen and (max-width: $device-m) {
                max-width: 100% !important;
                width: 100%;
                padding: 0 !important;
                margin-bottom: 20px;
              }

              span {
                width: 100%;
                display: flex;
                justify-content: space-around;

                @media screen and (max-width: $device-m) {
                  justify-content: space-evenly;
                  max-width: 120px;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }

      // doc table wrapper ends
    }
    // RH ends
  }

  // MOBILE SPECIFIC STYLES ************************************

  // Sidebar (Mobile)
  .sidebar-user-mobile {
    flex-grow: 1;
    .custom-container {
      background-color: #fff;
      .title {
        padding: 1.5rem;
        border-bottom: 1px solid #ccc;
        h3 {
          margin: 0;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            font-weight: bold;
            font-family: "Montserrat", sans-serif;
            &:first-child {
              font-size: 1.5rem;
              margin-right: 10px;
            }
            &:last-child {
              color: #fff;
              background-color: $green;
              border-radius: 20px;
              height: 20px;
              width: 20px;
              padding: 5px;
              font-size: 10px;
            }
            &.doc-count {
              text-align: center;
            }
          }
        }
      }
      .list-container {
        .list {
          padding: 0;
          list-style: none;
          li {
            padding: 1.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            &:last-child {
              border: none;
            }
            a {
              color: #202020;
              display: flex;
              .icon {
                width: 16px;
                height: 16px;
                border: 1px solid $borderB;
                background-color: white;
                display: block;
                border-radius: 20px;
                margin-right: 10px;
              }
              &.doc-active {
                .icon {
                  border: 1px solid $green !important;
                  // background-image: url("../images/doc-active.svg");
                  background-image: url($assetsUrl + "/icons/doc-active.svg");
                  background-position: center 0px;
                  background-repeat: no-repeat;
                  background-size: 16px;
                }
              }
              &.doc-complete {
                .icon {
                  border: 1px solid $green;
                  background-color: $green;
                  // background-image: url("../images/doc-complete.svg");
                  background-image: url($assetsUrl + "/icons/doc-complete.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 8px;
                }
              }
              &.doc-locked {
                .icon {
                  border: 1px solid $green;
                  background-color: $green;
                  position: relative;
                  // background-image: url("../images/doc-complete.svg");
                  // background-image: url($assetsUrl + "/icons/doc-complete.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 8px;
                  &:after {
                    content: "\f023";
                    background-image: none;
                    font-family: "Font Awesome 5 Free";
                    color: #fff;
                    font-size: 8px;
                    position: absolute;
                    left: 3.5px;
                    top: -3px;
                    z-index: 9;
                    font-weight: 900;
                  }
                }
              }
              // .icon {
              //   display: block;
              //   margin-right: 15px;
              //   background-color: #3f7e39;
              //   width: 16px;
              //   height: 16px;
              //   border-radius: 8px;
              //   padding: 4px 4px;
              //   i {
              //     font-size: 8px;
              //     display: block;
              //     color: #fff;
              //   }
              // }

              .name {
                flex-grow: 1;
                font-family: "Montserrat", sans-serif;
                @media screen and (max-width: 767px) {
                  word-break: break-word;
                  max-width: calc(100% - 42px);
                }
              }
              .link {
                flex-basis: 15px;
              }
            }
          }
        }
      }
    }
  }
  // End of Sidebar Mobile

  // Accordion (Mobile)
  .custom-accordion {
    background: #f2f2f2;
    margin-bottom: 2rem;
    padding: 10px;
    @media screen and (max-width: 768px) {
      padding: 8px;
      &.col {
        padding: 8px !important;
      }
    }
    .accordion-item {
      background-color: #fff;
      @media screen and (max-width: 768px) {
        margin-bottom: 8px;
        p {
          background-color: #ffffff !important;
        }
      }
      .accordion-heading {
        .accordion-button {
          display: flex;
          justify-content: space-between;
          padding: 10px 15px;
          @media screen and (max-width: 768px) {
            padding: 12.5px 5px;
            &:focus {
              outline: none;
              border: none;
            }
          }
          p {
            margin: 0;
            font-weight: bolder;
            font-family: "proxima_nova_altbold", Georgia, sans-serif;
            &:nth-child(1) {
              flex-grow: 1;
              justify-content: flex-start;
            }
            &:nth-child(2) {
              flex-basis: 130px;
              display: flex;
              justify-content: space-between;
              flex-shrink: 0;
              padding-left: 26px;
            }
            span {
              display: inline-block;
              &:first-child {
                margin-right: 25px;
                @media screen and (max-width: 768px) {
                  margin-right: 14px;
                }
              }
              &:nth-child(2) {
                color: #3f7e39;
                margin-right: 35px;
                @media screen and (max-width: 768px) {
                  margin-right: 14px;
                }
              }
              &:nth-child(2)::before {
                content: "$";
                margin-right: 5px;
              }
            }
          }
          &[aria-expanded="true"] {
            p {
              span:last-child {
                i {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }
      }
      .accordion-content {
        padding: 10px;
        @media screen and (max-width: 768px) {
          padding: 8px;
          .content {
            section {
              p.inputs-col,
              p:nth-child(2) {
                padding: 16px 16px;
                .inputs-items {
                  padding: 0;
                }
                .inputs-head {
                  justify-content: flex-start;
                }
              }
            }
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          .inputs-col {
            @media screen and (max-width: 768px) {
              margin-bottom: 0px !important;
              .inputs-head {
                justify-content: flex-start;
              }
            }
          }
          section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
              &:last-child {
                border-bottom: 1px solid #dfdfdf !important;
              }
            }
            .inputs-items {
              @media screen and (max-width: 768px) {
                input,
                select,
                textarea {
                  margin-bottom: 0;
                }
              }
            }
            .inputs-head {
              color: #202020;
              font-weight: bold;
              @media screen and (max-width: 768px) {
                border-right: none !important;
                font-weight: bold !important;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 24px;
                color: #000000;
                font-family: "Montserrat", sans-serif;
              }
            }
            p {
              padding: 10px 15px;
              margin: 0;
              border: 1px solid #dfdfdf !important;
              @media screen and (max-width: 768px) {
                padding: 12px 16px;
                border-bottom: none !important;
              }
              &:first-child {
                flex-basis: 75px;
                flex-shrink: 0;
              }
              &:last-child {
                flex-grow: 1;
                @media screen and (max-width: 768px) {
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }
              }
              @media screen and (max-width: 480px) {
                padding: 10px;
                &:first-child {
                  flex-basis: 60px;
                }
              }
              .inputs-items {
                @media screen and (max-width: 768px) {
                  padding: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  // Total
  .accessories-total {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    align-items: center;
    margin-bottom: 1.5rem;
    .items-head {
      margin-right: 1.5rem;
      font-weight: bold;
    }
    .input-items {
      margin: 0;
    }
  }

  // End of MOBILE SPECIFIC STYLES ************************************
  .customised-pagination {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }

  // doc info ends
}

.applicant_item {
  background: #fbfbfb;

  &.list_item {
    padding: 0;
  }

  &:nth-child(2n) {
    background: #f3f3f3;

    input[type="checkbox"] {
      &:before {
        background: #f3f3f3;
      }
    }
  }
}

.basic_info {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // padding: 0 !important;

  .doc-list-img {
    margin-right: 10px;
  }

  // &.unavailable_employe {
  //   width: auto !important;
  // }

  // div {
  //   flex: 1 0 !important;
  //   text-align: left;
  // }

  .item_cell {
    flex: 1 0;
    padding: 20px 10px;
    width: 100px;

    /*span{
                    
                }*/
    // &:nth-child(2) {
    //   flex: 2 0 !important;
    //   display: flex;
    //   align-items: flex-start;
    // }

    // &:last-child {
    //   max-width: 100px;
    //   display: flex;
    //   justify-content: center;
    // }

    // responsive customisation
    // &:first-child {
    //   @media screen and (max-width: 640px) {
    //     margin-bottom: 0px !important;
    //   }

    //   input[type="checkbox"] {
    //     @media screen and (max-width: 640px) {
    //       margin: 0px !important;
    //     }
    //   }
    // }

    // &:nth-child(2) {
    //   @media screen and (max-width: 640px) {
    //     flex: calc(50% - 18px) 0 !important;
    //   }

    //   @media screen and (max-width: 480px) {
    //     flex: calc(100% - 36px) 0 !important;

    //     padding-bottom: 10px;
    //     margin-bottom: 0px;

    //     span {
    //       padding-right: 62px;
    //       width: 100%;
    //       text-align: center;
    //     }
    //   }
    // }

    // &:nth-child(3) {
    //   @media screen and (max-width: 640px) {
    //     flex: calc(50% - 18px) 0 !important;
    //   }

    //   @media screen and (max-width: 480px) {
    //     flex: 100% 0 !important;

    //     padding: 5px 15px !important;
    //     text-align: center;
    //     margin-bottom: 0px;
    //   }
    // }

    // &:nth-child(4) {
    //   @media screen and (max-width: 640px) {
    //     flex: 100% 0 !important;
    //     padding: 5px 15px !important;
    //     text-align: center;
    //   }
    // }

    // &:last-child {
    //   @media screen and (max-width: 640px) {
    //     flex: 100% 0 !important;
    //     padding: 5px 15px !important;
    //     margin-bottom: 20px;
    //     margin-left: auto;
    //     margin-right: auto;
    //   }
    // }
  }
}

// cusom fixes
.react-datepicker__navigation {
  min-width: 10px;
}

//applicants fix

.applicant_item {
  div {
    &.homeEmp_list {
      width: 100%;

      .basic_info .item_cell {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          padding: 10px;
          justify-content: center;
        }
      }
    }
  }
}

.applicants_inner_head.inner_head.header_tabs {
  display: flex;
  align-items: center;

  h2 {
    border-right: 1px solid #d8d8d8;

    &:last-child {
      border-right: none;
    }

    cursor: pointer;
    color: #b1b2b4;

    &.active {
      color: #000;
    }
  }
}

@media screen and (max-width:767px) {
  .applicants_inner_head.inner_head.header_tabs {
    flex-wrap: wrap;
  }
  .applicants_inner_head.inner_head{
    height: 100px;
  }
}
@media screen and (min-width:767px) {
  .applicants_inner_head.inner_head.header_tabs {
   overflow-x: auto;
   overflow-y: hidden;
  }
}

.employee_stats_details {
  .docs_stats_progress:first-child {
    .stats_progress_div {
      position: relative;
      margin-bottom: 15px;

      &:after {
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        background: #d8d8d8;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.Toastify__toast-body {
  color: #fff;
}

.dashboard_container {
  .onboarding-docs {
    min-height: 0;
    min-height: calc(100vh - 140px);
  }
}

.content_table {
  tr {
    td {
      padding-bottom: 10px;
      vertical-align: top;
      & + td {
        padding-left: 10px;
      }
    }
  }
}

.inputs_table {
  width: 100%;
  border: 1px solid #dadada;
  thead {
    font-weight: bold;
    text-align: center;
  }
  td {
    padding: 12px;
    & + td {
      padding-left: 5px;
      padding-right: 5px;
    }

    &:last-child {
      padding-right: 10px;
    }
    &.price_amt {
      font-weight: bold;
      text-align: center;

      border-right: 1px solid #dadada;
      border-left: 1px solid #dadada;
      @media screen and (max-width: 600px) {
        text-align: left;
        border-right: 0;
        border-left: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
      & + td {
        padding-left: 10px;
      }
    }
  }
  tr {
    border-bottom: 1px solid #dadada;
  }
  select {
    padding: 5px 12px !important;
  }
  input[type="text"] {
    height: 40px;
    padding: 0px 5px !important;
    width: 100%;
    text-align: center;
    &.totals {
      font-weight: bold !important;
      text-align: left !important;
      height: 48px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  .sizes {
    opacity: 0.5;
    padding-top: 10px;
    display: block;
  }
  .totals-info {
    background: #f2f2f2;
    border-right: 1px solid #dadada;

    p {
      font-style: italic;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
  .totals-count {
    padding-left: 10px;
  }

  label {
    color: #000000 !important;
  }
}
.no-border {
  border: none !important;
}

.paid-inputitems {
  display: flex;
  transform: translateY(-5px);
  height: 36px;
  p {
    margin-bottom: 0;
    margin-right: 10px;
    line-height: 40px;
    max-width: 30px;
    justify-content: center !important;
  }
  input {
    margin-bottom: 0;
  }
}
.merchandise {
  label {
    font-weight: normal !important;
    color: #000 !important;
  }
  .inputs-col {
    @media screen and (max-width: 960px) {
      overflow-y: scroll;
    }

    input[type="text"],
    input[type="number"],
    select {
      @media screen and (max-width: 960px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center;
        min-width: 40px;
      }
    }
  }

  .check_radio_group {
    .col.mb-0 {
      @media screen and (max-width: 992px) {
        flex-direction: row !important;
      }
    }
  }
}
.filledorder {
  border: 1px solid #dadada;
  input {
    margin-bottom: 0 !important;
  }
  .txt_light {
    font-weight: normal !important;
  }
  td {
    padding: 10px;
    vertical-align: bottom;
    width: calc(100% / 3);
  }
}

// INPUT TYPE NUMBER FIX
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* new fixes*/
.resource_list {
  .inner_head {
    .col-md-6 {
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
}

.resource_container {
  .btn_list {
    @media screen and (max-width: 768px){
      width: 100%;
    }
    .button {
      @media screen and (min-width: 531px) and (max-width: 900px) {
        width: calc(50% - 10px);
        margin-left: 10px;
        text-align: center;
        font-size: 13px;
        &:first-child {
          margin-left: 10px !important;
        }
      }
    }
  }
}

#view_print_manifest input.button {
  @media screen and (max-width: 480px) {
    width: calc(100% - 10px) !important;
  }
}

#assignment-status-form {
  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2rem;
  }
}

.applicants_inner_head.inner_head.inner_head_mob {
  @media screen and (max-width: 960px) {
    height: 120px;
    .col-md-6 {
      width: 100%;
    }
    .applicant_filter {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.onboarding-docs {
  .doc_info {
    .filter_form {
      .doc-approve-btns {
        @media screen and (max-width: 480px) {
          flex-direction: column;
          width: 100%;
          padding-left: 0px;
          padding-right: 0px;
          button {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.new_nav_list {
  @media screen and (max-width: 767px) {
    max-height: calc(100vh - 49px);
    overflow-y: scroll;
  }
}

.settings_container .btn_list {
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    height: 114px;
    justify-content: space-around;
    align-items: center;

    .button {
      margin-left: 0;
    }
  }
}

.settings_container .inner_head {
  @media screen and (max-width: 480px) {
    flex-direction: column;
    h2 {
      border-right: 0 !important;
      border-bottom: 1px solid #d8d8d8;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.onboarding-docs {
  .appln_list_header {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }
}

.check_radio_group.deliver_to {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
    .check_radio_item.agreement_radio {
      margin-bottom: 10px;
    }
  }
}

.msg-userlist-search {
  .is-admin-mob .is-admin-header {
    justify-content: flex-start;
  }
}

.is-admin-header {
  @media screen and (min-width: 768px) and (max-width: 1260px) {
    padding-left: 15px !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .btn-mark-as-read {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.msg-new-btn,
.msg-cancel-btn {
  @media screen and (min-width: 768px) and (max-width: 922px) {
    font-size: 10px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row.msg .col-sm-8,
.row.msg .col-sm-4 {
  @media screen and (min-width: 768px) and (max-width: 922px) {
    padding-left: 0px !important;
  }
}

.is-admin-header .pretty {
  @media screen and (min-width: 768px) and (max-width: 1260px) {
    margin-top: auto;
    margin-bottom: auto;
    display: table-cell;
    height: 18px;
    padding-top: 0px !important;
  }
}

.d-flex.msg-read {
  justify-content: space-around;
  width: 100%;

  .is-admin-mob .is-admin-header {
    margin-left: auto;
    @media screen and (max-width: 360px) {
      line-height: 1;
    }
  }
}

// rehire custom
.dd-check {
  text-align: left;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.emergency-contact-select {
  flex-direction: column !important;
}

.amount_check {
  @media screen and (min-width: 1081px) {
    align-items: center;
  }
  input {
    @media screen and (min-width: 1081px) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column !important;
    .check_radio_item.agreement_radio {
      margin-bottom: 10px;
    }
  }
}

.delivery_opt {
  .inputs-col {
    max-width: 600px;

    .check_radio_item {
      display: flex;
      label {
        margin-left: 10px !important;
      }

      input[type="radio"]:checked ~ label {
        margin-left: 15px !important;
      }
    }
  }
}

.checkb_vertical {
  .agreement_radio {
    margin-bottom: 10px;
  }
  .inputs-head .mb-0 {
    margin-bottom: 0px !important;
  }
}

.worked-for {
  .row.form-row {
    &:first-child {
      border-top: 1px solid #dadada !important;
      padding-top: 24px !important;
    }
    input {
      margin-bottom: 20px !important;
    }
  }
}

.row.form-row.bt-1 {
  border-top: 1px solid #dadada !important;
  padding-top: 24px !important;
}

.jobType {
  .col-md-4 {
    @media screen and (max-width: 1440px) {
      width: auto;
    }

    @media screen and (max-width: 1280px) {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 1280px) {
    flex-direction: column !important;
  }
}

.crew_outer .outer_list {
  padding-bottom: 20px;
}

///new mobile design for documents list - 29/01/2021
//modal-common style for custom-modal
body.document-active {
  &.ReactModal__Body--open {
    .right_sidebar {
      .doc_info {
        .doc_info-rh {
          .doc-title {
            z-index: 1 !important;
          }
        }
      }
    }
    .custom-modal {
      left: 50% !important;
      right: auto !important;
      top: 50% !important;
      position: absolute;
      transform: translate(-50%, -50%);
      &:focus,
      &:active {
        outline: none;
        border: none;
      }
      .modal-head {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #202020;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 9px 15px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        height: 54px;
        border: none;
        h4.modal-title {
          width: auto;
          font-weight: 600;
          font-family: "Proxima Nova", sans-serif;
          -webkit-font-smoothing: antialiased;
        }
        figure {
          display: none;
        }
      }
      .modal-body {
        background: #ffffff;
        padding: 12px 16px 16px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        .content {
          text-align: left;
          padding: 0 !important;
          p {
            font-family: "Proxima Nova", sans-serif;
            font-size: 14px !important;
            line-height: 24px;
            color: #202020;
            font-weight: normal;
          }
        }
        .buttons {
          padding: 0 !important;
          flex-wrap: nowrap !important;
          button {
            margin: 0 !important;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #ffffff;
            min-height: 36px;
            padding: 6px 12px;
            font-family: "proxima_nova_altbold", Georgia, sans-serif;
            -webkit-font-smoothing: antialiased;
            &:nth-last-child(1) {
              margin-left: 6px !important;
            }

            @media screen and (max-width: 360px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.documents-list {
  .custom-tabs li a.custom-tab span {
    font-size: 1.5rem;
  }
  .custom-tabs li.custom-tab.active a span {
    border: none;
    color: #3f7e39;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .col-lg-12 {
    .inner_head {
      border: 1px solid rgba(0, 0, 0, 0.12);
      h2 {
        @media screen and (max-width: 768px) {
          padding: 4px 10px;
        }
      }
    }
  }
}
.custom-pagination-nav {
  display: none;
}

@media (max-width: 768px) {
  //body class for document active

  .onboarding-docs .doc_info .doc_info-rh .doc_info-form .col.f_passport_check .inputs-col:last-child label {
    margin-left: 15px !important;
  }
  .onboarding-docs
    .doc_info
    .doc_info-rh
    .doc_info-form
    .row.form-row.worksheet
    .inputs-col
    .inputs-items.steps_input
    .stepcounter,
  .onboarding-docs
    .doc_info
    .filter_form
    .doc_info-form
    .row.form-row.worksheet
    .inputs-col
    .inputs-items.steps_input
    .stepcounter {
    height: 36px;
  }

  .opacity-1 {
    opacity: 1 !important;
  }
  .left-align {
    text-align: left;
    .d-flex.flex-column {
      p,
      h3 {
        text-align: left !important;
      }
      h3 {
        line-height: 24px;
      }
    }
  }
  .mar-bot-0 {
    margin-bottom: 0 !important;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  body.document-active {
    .right_sidebar {
      width: 100%;
      padding: 0;
      .user-docs {
        .onboarding-docs {
          padding-top: 0;
          .doc_info {
            .doc_info-rh {
              &.form-w4 {
                .doc-page-count {
                  display: none;
                }
              }
              .custom-pagination-nav {
                height: 40px;
                display: flex !important;
                justify-content: flex-end;
                align-items: center;
                padding: 0 8px;
                border: 1px solid rgba(0, 0, 0, 0.12);
                .pagination-wrapper {
                  display: flex;
                  align-items: center;
                  a {
                    padding: 8px 8px;
                    display: flex;
                    align-items: center;
                  }
                  p {
                    margin-bottom: 0;
                    margin: 0 8px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.6px;
                    color: rgba(0, 0, 0, 0.87);
                    mix-blend-mode: normal;
                    font-family: "Montserrat", sans-serif;
                    span {
                      color: rgba(0, 0, 0, 0.5);
                      font-family: "Montserrat", sans-serif;
                    }
                  }
                }
              }
              .doc_info-form {
                .row.form-row {
                  .inputs-col {
                    & > div {
                      .inputs-items {
                        & + .inputs-items {
                          margin-left: 8px !important;
                        }
                      }
                    }

                    .inputs_table {
                      @media screen and (max-width: 767px) {
                        min-width: 700px;
                      }
                    }

                    table.content_table {
                      @media screen and (max-width: 767px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            .doc-title h3 .opt_text {
              padding: 1px 10px;
              top: 13px;
              background: #131415;
              border: 1px solid #979797;
              box-sizing: border-box;
              border-radius: 3px;
              margin-left: 0;
              font-size: 9px;
              font-family: "Montserrat", sans-serif;
              font-weight: bold !important;
            }
            .close-button {
              flex-basis: 35px;
            }
            .doc-title {
              height: 48px;
              padding-right: 16px;
              padding-left: 16px;
              position: sticky;
              top: 0;
              background-color: white;
              z-index: 99;
              margin-top: -2px;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
                0px 1px 3px rgba(0, 0, 0, 0.2);
              h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
                color: #000000;
                font-family: "Montserrat", sans-serif;
                max-width: 100%;
              }
            }
            .doc_info-form {
              padding: 20px 0px;
              &.form-i9 {
                .check_radio_group.agreement_checks {
                  margin-bottom: 10px;
                }
              }
              h6.inputs-head {
                color: #000000 !important;
              }
              .checkb_lists.mb-6 {
                li {
                  margin-bottom: 6px;
                }
              }
              &.disability-status {
                .inputs-col {
                  .agreement_checks {
                    .checkb_lists {
                      li {
                        margin-bottom: 6px;
                        &:nth-last-child(1) {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
              &.employee-agreement {
                .col.part1-checkbox {
                  .inputs-col {
                    .agreement_checks {
                      .check_radio_item {
                        label,
                        label span {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
              }
              .disclaimers.dis-notes {
                padding: 18px 20px;
                p {
                  padding-left: 0;
                  padding-right: 0;
                  color: #000000;
                }
                ol {
                  padding-left: 0;
                  li {
                    color: #000000;
                  }
                }
                ul {
                  margin-top: 12px;
                  padding-left: 17px;
                  margin-bottom: 0;
                  li {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 23px;
                    color: #000000;
                    margin-bottom: 6px;
                    font-family: "Montserrat", sans-serif;
                    strong {
                      font-weight: bold;
                      font-family: "Montserrat", sans-serif;
                    }
                    &:nth-last-child(1) {
                      margin-bottom: 0 !important;
                    }
                  }
                }
                .inputs-head {
                  padding: 0;
                  .toggler_btn {
                    right: 4px;
                  }
                }
              }
              &.wage-rate-agreement {
                .form-row {
                  &.employee-signature {
                    .col {
                      span.notes {
                        margin-bottom: 10px;
                        line-height: 24px;
                        color: #000000;
                        mix-blend-mode: normal;
                        opacity: 0.5;
                      }
                    }
                  }
                }
                .inputs-col {
                  .content_table.pay-rates {
                    tbody {
                      tr {
                        display: flex;
                        td:nth-child(1) {
                          width: 124px;
                          padding-left: 0;
                        }
                        td:nth-last-child(1) {
                          width: calc(100% - 125px);
                        }
                      }
                    }
                  }
                }
              }
              .col {
                padding: 0 16px;
                margin-bottom: 0px;
                .inputs-col .sign_here .sign_canvas {
                  .sign_btn-group {
                    button {
                      min-height: 36px;
                      background: #cfd0d2;
                    }
                  }
                }
                .inputs-col .sign_here .sign_canvas .sign_placeholder img {
                  width: 100%;
                }
                &.medical-info {
                  .inputs-col {
                    margin-bottom: 11px;
                  }
                  .inputs-items {
                    textarea {
                      margin-bottom: 16px;
                    }
                  }
                }
                .disclaimers {
                  padding: 16px 20px;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 23px;
                  mix-blend-mode: normal;
                  font-family: "Montserrat", sans-serif;
                  -webkit-font-smoothing: antialiased;
                }
                &.half-col {
                  display: flex;
                  justify-content: space-between;
                  flex-direction: row;
                  flex-wrap: wrap;
                  .inputs-col {
                    width: calc(50% - 8px);
                    &:nth-child(odd) {
                      margin-right: 12px;
                    }
                  }
                }
                &.mailing-address {
                  .inputs-col {
                    p {
                      margin-top: 8px;
                    }
                  }
                }
              }
              .info-form {
                .row {
                  margin: 0;
                  p {
                    color: #000000;
                  }
                }
                .row.form-row {
                  padding-top: 19.5px;
                  &:nth-child(1) {
                    padding-top: 0px;
                  }
                }
                .action_buttons {
                  padding-top: 16px;
                  padding-bottom: 20px;
                  padding-left: 16px;
                  padding-right: 16px;
                  margin-bottom: -20px;
                  .pagination-number {
                    display: flex;
                    align-items: center;
                    p {
                      margin-bottom: 0;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 15px;
                      letter-spacing: 0.6px;
                      color: rgba(0, 0, 0, 0.38);
                      font-family: "Montserrat", sans-serif;
                      mix-blend-mode: normal;
                      padding: 0 5px;
                    }
                  }
                  &:before {
                    left: 0;
                    right: 0;
                  }
                  button {
                    font-weight: 900;
                    -webkit-font-smoothing: antialiased;
                    font-family: "Montserrat", sans-serif;
                  }
                }
                .inputs-col {
                  margin-bottom: 20px;
                  .check_radio_group.agreement_checks p.checkb_detail {
                    opacity: 1;
                  }
                  span.notes {
                    color: #000000;
                    mix-blend-mode: normal;
                    opacity: 0.5;
                    font-family: "Montserrat", sans-serif;
                  }
                  h6.inputs-head {
                    margin-bottom: 16px;
                    font-weight: 600;
                    color: #000000 !important;
                  }
                  .check_radio_group {
                    .check_radio_item {
                      label {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: #000000;
                      }
                      span.checkbox_checkbox {
                        border: 1px solid #cccccc;
                      }
                    }
                    &.agreement_radio {
                      .check_radio_item {
                        label {
                          width: 70%;
                          margin-bottom: 0;
                        }
                        span.notes {
                          margin-left: 35px;
                        }
                      }
                    }
                    &.agreement_checks {
                      label span,
                      label {
                        font-style: normal;
                        font-weight: normal !important;
                        font-size: 13px;
                        line-height: 23px;
                        color: #000000;
                        font-family: "Montserrat", sans-serif;
                      }
                      span.radio_checkbox.square {
                        // border-radius:0 !important;
                        top: 1px;
                        //background-color: #000;
                        //  background-image: url(https://firestormfire.sfo2.cdn.digitaloceanspaces.com/dev/assets//icons/doc-complete.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        &:before {
                          //display: none;
                        }
                      }
                      .radio_checkbox {
                        top: 1px !important;
                      }
                    }
                  }
                  .check_radio_group.check_disclaimer {
                    .check_radio_item {
                      label {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 24px;
                      }
                    }
                  }
                  p {
                    font-family: "Montserrat", sans-serif;
                    margin-bottom: 0;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 24px;
                    color: #000000;
                    mix-blend-mode: normal;
                    opacity: 0.5;
                    margin-bottom: 7.5px;
                  }
                  .inputs-head {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 24px;
                    // color: #000000;
                    font-family: "Montserrat", sans-serif;
                    margin-bottom: 8px;
                    span {
                      font-family: "Montserrat", sans-serif;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 24px;
                      color: #000000;
                      mix-blend-mode: normal;
                      // opacity: 0.3;
                      margin-left: 4px;
                    }
                  }
                  .inputs-items {
                    &.group {
                      margin-bottom: 0;
                      .grouped {
                        input,
                        select,
                        textarea {
                          margin-bottom: 16px;
                        }
                        &:nth-last-child(1) {
                          input,
                          select,
                          textarea {
                            margin-bottom: 0px;
                          }
                        }
                      }
                    }
                    input,
                    select,
                    textarea {
                      padding: 5px 16px;
                      border: 1px solid #d5d8d9;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                      color: #202020;
                      font-family: "Montserrat", sans-serif;
                      mix-blend-mode: normal;
                      opacity: 1;
                      &::placeholder {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #202020;
                        mix-blend-mode: normal;
                        opacity: 0.32;
                        &::-webkit-input-placeholder {
                          white-space: pre-line;
                        }
                        &::-moz-placeholder {
                          white-space: pre-line;
                        }
                        &:-ms-input-placeholder {
                          white-space: pre-line;
                        }
                        &:-moz-placeholder {
                          white-space: pre-line;
                        }
                      }
                    }
                    &:nth-last-child(1) {
                      input,
                      select {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .new_nav {
      display: none;
    }
    .settings_container.settings-pre-wrapper {
      margin-top: 0;
      .page_title {
        display: none;
      }
      .col-lg-12 {
        .inner_head {
          display: none;
        }
        .documents-list {
          .custom-tabs {
            display: none;
          }
          .custom-tab-content {
            .merchandise-form {
              .doc_info-rh {
                margin-top: 0;

                .doc-title {
                  position: sticky;
                  top: 0;
                  background-color: white;
                  z-index: 99;
                  margin-top: -2px;
                }
              }
            }
          }
        }
      }
    }
  }
  //right sidebar
  .right_sidebar {
    padding-left: 16px;
    padding-right: 16px;

    .breadcrumbs {
      padding: 6px 20px 6px;
      color: #202020;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      mix-blend-mode: normal;
      opacity: 0.7;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0760586);
      span.green {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold !important;
        font-size: 13px;
        line-height: 16px;
        color: #3d7d38 !important;
        mix-blend-mode: normal;
        opacity: 0.7;
      }
    }
    .user-docs {
      // padding-bottom: 16px;
      @media (max-width: 768px) {
        padding-top: 0px;
      }
      padding-top: 0px;
      .onboarding-docs {
        padding-top: 16px;
        .doc_info {
          // background-color: transparent;
        }
        .sidebar-user-mobile .custom-container {
          .title {
            padding: 1.35rem 1.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }
          .list-container .list {
            margin-bottom: 0;

            li {
              padding: 1.35rem 1.5rem;
              a {
                line-height: 20px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
  .settings-pre-wrapper {
    .col-lg-12 {
      padding: 0;
      .inner_head {
        white-space: nowrap;
        overflow: auto;
        text-overflow: ellipsis;
        border: 1px solid rgba(0, 0, 0, 0.12);
        min-height: 47px;
        display: flex;
        align-items: center;
        border-radius: 0px;
        padding: 10px 5px;
        h2 {
          padding: 4px 10px;
        }
      }
    }
    .documents-list {
      .custom-tabs .nav-tabs {
        flex-direction: row;
        padding: 0 5px;
        li {
          a {
            min-width: 96px;
            padding: 12px 10px;
            font-size: 14px !important;
            font-weight: 700 !important;
            font-family: "proxima_nova_altbold", Georgia, sans-serif;
            color: #737373;
            text-align: center;
            i {
              display: none;
            }
            span {
              display: none;
            }
          }
          &.active {
            a {
              color: #3f7e3a !important;
              border-bottom: 3px solid #3f7e3a;
            }
          }
        }
      }
      .custom-tab-content {
        .content .list p.button-container button {
          top: -42px;
        }
        .tab-content {
          .appln_list_header {
            padding: 6px 15px;
            border-top: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            background: white;
            height: 47px;
            display: flex;
            align-items: center;
            .list_item_head {
              span {
                color: #000000;
                font-weight: 600;
                font-size: 13px;
                max-width: 61px;
                line-height: 15.83px;
                -webkit-font-smoothing: antialiased;
              }
              color: #000000;
              font-weight: 600;
              font-size: 13px;
              display: flex;
              align-items: center;
              padding-left: 22px;
              &:nth-child(1) {
                padding-left: 0px;
              }
            }
          }
          .applicant_item {
            background: #fbfbfb;
            &:nth-child(2n) {
              background: #f3f3f3;
            }
            .homeEmp_list {
              padding-left: 0;
            }
            .basic_info {
              padding-left: 6px;
            }
            .basic_info .item_cell {
              padding: 10px 10px;
              span {
                line-height: 20px;
                max-width: 110px;
                font-family: "Montserrat", sans-serif;
              }
              &.doc_download {
                img {
                  max-width: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  body.document-active .right_sidebar .user-docs .onboarding-docs .doc_info .doc_info-form {
    .merchandise {
      padding-top: 30px;
      .paid-checkbox {
        .check_radio_group {
          margin-bottom: 16px;
          .col {
            padding: 0 !important;
            .check_radio_item {
              display: flex;
              label {
                margin-left: 14px;
              }
            }
          }
          .paid-inputitems {
            display: flex;
            align-items: center;
            p,
            input {
              background: #ffffff;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .settings-pre-wrapper .custom-tab-content .tab-content .onboarding-docs .merchandise-form {
    .inputs_table tr:nth-child(1) {
      display: flex;
    }

    .merchandise label {
      color: #000 !important;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 14px;
      line-height: 24px;
      margin-top: 2px;
    }
    .check_radio_group.deliver_to {
      flex-direction: row !important;
      .check_radio_item {
        margin-bottom: 0;
      }
    }
    .doc_info {
      padding: 0;
    }
    > p {
      #back-button {
        display: none;
      }
    }
    .doc_info-rh {
      .doc-title {
        height: 48px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        h3 {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          -webkit-font-smoothing: antialiased;
          color: #000000;
          max-width: calc(100% - 17px);
          font-family: "Montserrat", sans-serif;
        }
      }
      .doc_info-form {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0;
        form {
          .form-row {
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 20px;
            padding-bottom: 8px;

            .col {
              margin-bottom: 0;
            }
            .inputs-col {
              margin-bottom: 12px;
              .inputs-head {
                font-weight: bold;
                font-size: 13px;
                line-height: 24px;
                -webkit-font-smoothing: antialiased;
                font-family: "Montserrat", sans-serif;
                color: #000000;
                margin-bottom: 9px;
              }
              .inputs-items {
                input,
                select {
                  height: 36px;
                  padding: 0 18px !important;
                  text-align: left;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                }
              }
            }
          }
          .action_buttons {
            padding-left: 16px;
            padding-right: 16px;
            &:before {
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  .document-active {
    .onboarding-docs .custom-accordion + .accessories-total {
      background: #f2f2f2;
      display: flex;
      align-items: center;
      padding-top: 0px;
      margin-bottom: 0;
      input {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: right;
        color: #000000;
        font-family: "Montserrat", sans-serif !important;
        text-align: center;
      }
      .inputs-head {
        margin: 0 !important;
        padding-left: 15px;
        padding-right: 15px;
      }
      .inputs-items {
        max-width: 115px;
        input {
          height: 48px;
        }
      }
    }
    .page_title + .row {
      margin: 0;
    }
  }
  .settings_container.settings-pre-wrapper .page_title {
    h1 {
      text-align: left;
      margin-top: 20px;
      margin-bottom: 0px;
      font-weight: 800;
      font-size: 24px;
      line-height: 24px;
    }
    .btn_list {
      margin-top: 20px;
      margin-bottom: 0;
      button {
        -webkit-font-smoothing: antialiased;
      }
    }
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .content .list p.button-container {
    height: auto;
    button {
      top: 0;
      position: relative;
      right: 16px;
    }
  }
  .settings-pre-wrapper .documents-list > .row {
    margin: 0;
    .col-sm-12 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .tab-content .applicant_item .basic_info .item_cell {
    flex: 3 0 !important;
  }
  .settings-pre-wrapper
    .documents-list
    .custom-tab-content
    .tab-content
    .applicant_item
    .basic_info
    .item_cell:nth-child(1) {
    min-width: 150px;
  }
  .settings-pre-wrapper
    .documents-list
    .custom-tab-content
    .tab-content
    .appln_list_header
    .list_item_head:nth-child(1) {
    min-width: 150px;
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .tab-content .appln_list_header .list_item_head {
    padding-left: 5px;
  }
  .settings-pre-wrapper .custom-tab-content .tab-content .onboarding-docs section.new {
    padding: 20px 16px;
    p {
      font-weight: 700 !important;
      font-family: "Montserrat", sans-serif !important;
      font-size: 13px !important;
      line-height: 24px;
      margin-bottom: 8px;
      -webkit-font-smoothing: antialiased;
    }
    p.actions {
      button {
        height: 30px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        font-family: "Montserrat", sans-serif !important;
        &.false {
          margin-left: 4px;
        }
      }
    }
    .inputs-items {
      select,
      input {
        height: 36px;
        font-size: 13px;
        font-family: "Montserrat", sans-serif !important;
        display: flex;
        align-items: center;
        padding: 0 10px;
      }
    }
  }
  .settings_container.settings-pre-wrapper .page_title {
    border-bottom: none !important;
    margin-bottom: 8px;
  }
  .settings-pre-wrapper .documents-list .custom-tabs .nav-tabs {
    justify-content: space-between;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .content .list p.button-container {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .content .list p.button-container button {
    height: 30px;
    padding: 0 20px;
    -webkit-font-smoothing: antialiased;
  }

  .applicant_item .basic_info {
    padding: 0px 0px !important;
  }
}

@media (max-width: 767px) {
  .settings-pre-wrapper .documents-list .custom-tab-content .tab-content .applicant_item .basic_info .item_cell {
    justify-content: flex-start;
    text-align: left;
    //  flex: auto !important;
    margin-bottom: 0px;
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .tab-content .appln_list_header {
    flex-wrap: nowrap;
  }
  .settings-pre-wrapper .col-lg-12 .inner_head {
    flex-direction: row;
    h2 {
      border-bottom: none;
      border-right: 1px solid #d8d8d8 !important;
      &:nth-last-child(1) {
        border-right: none !important;
      }
    }
  }
}
@media (max-width: 480px) {
  .settings-pre-wrapper .documents-list .custom-tab-content .tab-content .appln_list_header .list_item_head {
    padding-left: 0px;
  }
  .settings-pre-wrapper .documents-list .custom-tab-content .content .list p.button-container button {
    right: 0;
  }
}


@media (max-width: 991px) {
  .crew_outer .outer_list.table-view {
   width: 1100px;
  }
}
@media (max-width: 350px) {
  .settings-pre-wrapper .documents-list .custom-tabs .nav-tabs li a {
    min-width: 84px;
  }
  .onboarding-docs .doc_info .doc_info-rh .doc_info-form .action_buttons button {
    min-width: 100px;
  }
  .settings-pre-wrapper
    .custom-tab-content
    .tab-content
    .onboarding-docs
    .merchandise-form
    .check_radio_group.deliver_to {
    flex-direction: column !important;
  }
  .settings-pre-wrapper
    .documents-list
    .custom-tab-content
    .tab-content
    .applicant_item
    .basic_info
    .item_cell:nth-child(1) {
    min-width: 137px;
  }
  .settings-pre-wrapper
    .documents-list
    .custom-tab-content
    .tab-content
    .appln_list_header
    .list_item_head:nth-child(1) {
    min-width: 137px;
  }
  .settings-pre-wrapper
    .custom-tab-content
    .tab-content
    .onboarding-docs
    .merchandise-form
    .check_radio_group.deliver_to
    .check_radio_item {
    margin-bottom: 10px;
  }
}

.user-docs {
  p.sign_btn-group {
    opacity: 1 !important;
    button {
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      font-family: "Montserrat", sans-serif;
    }
  }
}

/* new fixes */
.msg-header {
  .group-titleEdit {
    button.sendBtn.sendBtn.sendBtn {
      width: 34px;
      height: 34px;
      top: 0;
      right: 0;
      border-radius: 0px 3px 3px 0px;
      background-color: #1f802e;
      img {
        max-width: 13px;
      }
    }
  }
}



.personal_licence_plate_container{
  margin-top: 5px;
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label="Lightbox"] {
  max-width: unset !important;
}

@media screen and (max-width:1024px) {
  .dress-measurements{
    flex-direction: column;
    .inputs-head,.inputs-items{
      padding: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.systemNotification__modal{
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    font-family: "Montserrat", sans-serif;
  }
}

.dd-user-docs .doc_info .doc_info-rh .doc_info-form .sign_canvas .has_signed + .sign_btn-group button {
  background: #3f7e3a !important;
}

.agreement_radio.d-flex {
  display: flex;
}

.gender_radio.d-flex-wrap {
  flex-wrap: wrap;
}

.right_sidebar {
  .outerMostContainer {
    .doc_info-rh {
      .doc-title {
        &.meal-title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          
          @media screen and (min-width:1024px) {
            height: auto;
            justify-content: flex-start;
            align-items: flex-end;
            padding-top: 32px;
            padding-bottom: 16px;
          }

          &.pl-0 {
            @media screen and (min-width:1024px) {
              padding-left: 0;
            }
          }

          h3 {
            flex: 1;
            text-align: center;
            margin: 0;
          }

          & > img {
            display: none;

            @media screen and (min-width: 1024px) {
              display: block;
              width: 120px;
              height: auto;
            }
          }

          &.fs-consent-doc-title {
            @media screen and (min-width:768px) and (max-width:990px) {
              padding: 0 25px;
              
              h3 {
                font-size: 13px;
              }
            }
          }
        }
      }
      .doc_info-form {
        .inputs-col {
          &.input-printed-name {
            width: 60%;
            margin-left: auto;

            @media screen and (min-width: 390px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

.mobile-screen-padding{
  @media  screen and (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.form-heading{
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
}

.hide_nav_link {
  cursor: pointer;
}
.fs-text-bold{
  b,strong{
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
    font-weight: 700;
  }
}