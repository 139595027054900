.ApplicantNoteCard {
  width: 100% !important;
  &:first-child{
    margin-top: 15px;
  }
  &:last-child{
    padding-bottom: 0;
    border: none;
  }
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);;
  :global {
    .applicant-note-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      @media (max-width: 767px){
        width: 100%;
        padding: 0;
      }
      div {
        flex: initial; //Overriding pre-existing bad styles
      }
    }
    .content{
      @media (max-width: 767px){
        width: 100%;
        display: block;
        padding: 0;
      }
      p{
        word-break: break-word;
      }
    }

    .name-time {
      display: flex;
      gap: 10px;
      @media (max-width: 767px){
        width: 100%;
        display: block;
        padding: 0;
      }
      .author-name {
        font-weight: bold;
        @media (max-width: 767px){
          width: 100%;
          display: block;
          padding: 0;
        }
      }
      .note-time {
        color: #20202099;
        @media (max-width: 767px){
          width: 100%;
          display: block;
          padding: 0;
        }
      }
    }
    .note-options-icon-wrapper{
      @media (max-width: 767px){ 
        width: 5px;
        display: block;
        padding: 0;
      }
      &>div,.note-options-icon{
        cursor: pointer;
        position: relative;
        @media (max-width: 767px){
          width: 20px;
          display: block;
          padding: 0;
        }
      }
      .note-options-icon{
        position: relative;
        z-index: 2;
        &::after{
          content: '';
          position: absolute;
          top: 45%;
          left: 50%;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #E4E4E4;
          transform: translate(-50%, -50%);
          z-index: -1;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }
        &:hover{
          &::after{
            opacity: 1;
          }
        }
      }
    }
  }
  div{
    @media (max-width:1024px){
      padding: 0;
    }
  }
}

.NoteOptionsPopup {
  :global {
    width: 88px;
    background-color: #fff;
    box-shadow: 0 0 5px #a3a3a3;
    position: absolute;
    top: -30px;
    right: 8px;
    border-radius: 5px;
    @media (max-width: 767px){
      display: block;
      padding: 0 !important;
      overflow: hidden;
    }
    p {
      width: 88px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      @media (max-width: 767px){ 
        display: block;
        padding: 0;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }:hover{
        background-color: #E4E4E4;

    }
  }
}
