.DateAndTimePicker {
  display: flex;
  :global {
    .custom-time-picker {
      .rc-time-picker-input {
        width: 100%;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid #d0d3d4;
        border-left: none;
        min-height: 37px;
      }
    }
    .react-datepicker-wrapper {
      input {
        min-height: 37px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.CustomDatePickerInput {
  :global {
    input {
      position: relative;
    }

    .calendar-icon {
      position: absolute;
      top: 10px;
      left: 182px;
    }
  }
}
