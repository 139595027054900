// mixin for finding width
@mixin calc-width($card-number, $gap) {
    width: calc((100% / #{$card-number}) - ((#{$gap} * (#{$card-number} - 1)) / #{$card-number}) * 1px);
}

// line clamp
@mixin clamp-lines($lineCount) {
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

// To size image using aspect ratio
.aspect-box {
    position: relative;
    width: 100%;
    img,
    video,
    canvas,
    iframe,
    svg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      object-fit: cover;
    }
    &:before {
      content: '';
      display: block;
      padding-top: calc(var(--aspect-ratio) * 100%);
    }
  }  

// media Querys
$md: "screen and (min-width:" + (720px) + ")";
$lg: "screen and (min-width:" + (992px) + ")";
$xl: "screen and (min-width:" + (1199px) + ")";
$xxl: "screen and (min-width:" + (1320px) + ")";
$xxxl: "screen and (min-width:" + (1440px) + ")";