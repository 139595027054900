.report_container {
  padding: 60px 0px 0;
  .page_title {
    position: relative;
    border-bottom: 1px solid #d8d8d8;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
  }
  .p_custom {
    a {
      padding: 1rem 1rem !important;
      font-size: 1.2rem !important;
      @media screen and (min-width: 993px) and (max-width: 1078px) {
        font-size: 1.1rem !important;
      }
      min-width: 62px !important;
    }
    li {
      &:first-child {
        a {
          padding: 1rem 1rem !important;
        }
      }
    }
  }
}

.no_access_container {
  text-align: center;
  padding: 12rem;
}