.accordion-wrapper {
  margin-top: 20px;
}

.accordion {
  background-color: #f7f9fa;
  color: #000;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-radius: 0;
  position: relative;
}

.accordion span {
  font-size: 14px;
  font-weight: 500;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}

.accordion.active,
.accordion:hover {
  background-color: #f7f9fa;
}

.accordion .toggle-controller {

  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);

  transition: all ease-in-out .2s;

}

.accordion.active .toggle-controller {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);

}

.panel {
  padding: 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border: none;
  border-radius: 0;
}
