.SystemManage {
  :global {
    padding: 32px;
    background: #fff;
    min-height: 650px;
    box-shadow: 6px 7px 10px -4px #dfe0e2;
    border-radius: 0px 0px 3px 3px;
    float: left;
    width: 100%;
    background: #fff;
    position: relative;
    * {
      font-family: "Montserrat", sans-serif;
    }
    @media screen and (max-width: 767px){
      padding: 24px 16px;
    }
    .main-container {
      .system-management-outer-tabs{
        .nav-tabs{
          border: none;
          border: 1px solid #E8F0F2;
          background: #F6F9FA;
          padding: 10px 16px;
          white-space: nowrap;
          overflow: auto;
          text-overflow: ellipsis;
          min-height: 47px;
          display: flex;
          li{
            a{
              background-color: transparent;
              padding: 4px 15px;
              font-size: 16px;
              color: #B1B2B4;
              font-weight: 700;
              line-height: 18px;
              border: none;
              margin: 0;
              border-radius: 0;
              border-right: 1px solid #D8D8D8;
              font-family: "Montserrat", sans-serif;
            }
            &:first-child{
              padding-left: 0;
            }
            &:last-child{
              padding-right: 0;
              // border-right: none;
            }
            &.active{
              a{
                color: #202020;
              }
            }
          }
        }
        .tab-content{
          border: 1px solid #E8F0F2;
          border-top: 0;
        }
      }
      h1 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        margin: 0;
      }

      .banner {
        background-color: #f6f9fa;
        height: 49px;
        display: flex;
        align-items: center;
        padding-left: 20px;
      }

      .action-container {
        // height: 100vh;
        padding: 30px;
        @media screen and (max-width: 767px){
          padding: 20px;
        }
        .title {
          margin-bottom: 50px;
        }

        .information-box {
          max-width: 480px;
          min-height: 144px;
          background: #eef7ed;
          padding: 20px 24px 24px 24px;
          margin-bottom: 20px;
          .info-title {
            display: flex;
            gap: 5px;
            align-items: center;
            margin-bottom: 10px;
            h3 {
              font-weight: 600;
              font-size: 13px;
              line-height: 24px;
              color: #3f7e38;
              margin: 0;
            }
          }
        }

        .action-buttons {
          display: flex;
          gap: 10px;
          p {
            margin-top: 10px;
          }
        }
      }
    }

    // Modal styles
  }
}

.system_manage_modal {
  width: 460px !important;
  background: #fff;
  position: absolute;
  max-width: calc(100% - 20px) !important;

  :global {
    .system-manage-modal-header {
      //   padding: 15px;
      background: #f7f9fa;
      min-height: 54px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-content: center;
      h4{
        font-size: 16px;
        color: black;
        font-weight: 700;
        line-height: 23px;
        padding: 15px 0;
        margin: 0;
      }
    }
    .system-manage-modal-body {
      text-align: left;
      padding: 24px 24px 16px 24px;
    }

    .download-file{
      color: #3F7E39;
      cursor: pointer;
    }

    .system-manage-modal-footer {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      padding-right: 30px;
      padding-bottom: 30px;
    }

    .error{
        color: red;
    }
  }
}
