.BulkImportPreview {
  margin-bottom: 24px;
  border: 1px solid  #F6F9FA;
  border-top: none;
  :global {
    .preview-section {
      padding: 0 24px;
      @media screen and (max-width: 1024px){
        width: 100%;
        overflow: auto;
      }
      .preview-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        @media screen and (max-width: 1024px){
          display: block;
        }
        .header-buttons {
          width: 300px;
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          @media screen and (max-width: 1024px){
            justify-content: flex-start;
          }
          .btn__close{
            background-color: #747474;
          }
        }
      }

      .preview-table {
        @media screen and (max-width: 1024px){
          width: 1024px;
        }
        table {
          width: 100%;
          table-layout: fixed;
        }
        .tbl-header {
          table{
            thead{
              tr{
                th{
                  background-color: #EEF0F2;
                  font-size: 13px;
                  font-weight: 700;
                  color: black;
                  line-height: 24px;
                  text-transform: capitalize;
                  font-family: "Montserrat", sans-serif;
                  padding: 12px 8px;
                  &:first-child{
                    text-align: right;
                    padding-right: 16px;
                    
                  }
                }
              }
            }
          }
        }
        .tbl-content {
          height: 330px;
          overflow-x: auto;
          margin-top: 0px;
          // border: 1px solid rgba(255, 255, 255, 0.3);
          font-family: "Montserrat", sans-serif;
          table{
            tbody{
              border-right: 1px solid #EEF0F2;
              border-left: 1px solid #EEF0F2;
              tr{
                background-color: transparent;
                border-top: 1px solid #EEF0F2;
                border-bottom: 1px solid #EEF0F2;
              }
              th {
                padding: 16px 8px;
                text-align: left;
                font-weight: 400;
                font-size: 13px;
                line-height: 24px;
                vertical-align: top;
                span{
                  font-weight: 400;
                  font-size: 13px;
                  width: 24px;
                  text-align: center;
                  line-height: 24px;
                  color: black;
                  display: inline-block;
                }
              }
              td {
                padding: 16px 0;
                padding-right: 16px;
                text-align: left;
                font-weight: 400;
                font-size: 13px;
                line-height: 24px;
                vertical-align: top;
                &:first-child{
                  span{
                    font-weight: 400;
                    font-size: 13px;
                    width: 24px;
                    text-align: center;
                    line-height: 24px;
                    color: black;
                    display: inline-block;
                  }
                }
                .bypass__error{
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 23px;
                  color: #FF0000;
                }
                input{
                  text-align: left;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 24px;
                  padding: 8px 10px;
                  border: 1px solid #D5D8D9;
                  border-radius: 3px;
                  background-color: transparent;
                  color: black;
                  margin-bottom: 0;
                  &::placeholder{
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 24px;
                    color: black;
                  }
                }
              }
            }
          }
        }
      }
    }

    .checkbox_wrap {
      width: auto;
      margin: 0;
      display: inline-block;
      position: relative;
      margin-left: 17px;
      top: 6px;
      .checkbox_label {
        display: block;
        width: auto;
        margin: 0;
        padding: 0;
        z-index: 0;
        position: relative;
        font-family: "Proxima Nova", Georgia, sans-serif;
        &::before {
          content: "";
          margin-right: 17px;
          display: inline-block;
          vertical-align: text-top;
          width: 16px;
          height: 16px;
          background: #fff;
          border: 1px solid #00000031;
          border-radius: 2px;
        }
      }
      .checkbox_input {
        width: 16px;
        height: 16px;
        margin: 0 !important;
        position: absolute;
        opacity: 0;
        z-index: 9;
        left: 0;
        top: 4px;
        border-radius: 2px;
        cursor: pointer;
        &:checked ~ .checkbox_label {
          &::before {
            background: #3f7e39;
            border-color: transparent;
          }
          &::after {
            content: "";
            position: absolute;
            left: 3px;
            top: 11px;
            background: #fff;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff,
              4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &.error ~ .checkbox_label {
          &::before {
            content: "";
            border: 1px solid red;
          }
        }
      }
    }
    
  }
}
