.radioButtonWrap {
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.radioInput {
    width: 20px;
    height: 20px;
    margin: 0 !important;
    position: absolute;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.radiolabel {
    display: block !important;
    width: auto !important;
    margin: 0 !important;
    z-index: 0!important;
    position: relative;
    font-family: "Proxima Nova", Georgia, sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.radioInput+.radiolabel {
    /* cursor: pointer; */
    padding: 0;
}

.radioInput+.radiolabel:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    border: 1px solid #9d9d9d;
}

.radioInput:checked+.radiolabel:before {
    background: #3f7e39;
    border-color: transparent;
}

.radioInput:disabled+.radiolabel {
    color: #b8b8b8;
    cursor: auto;
}

.radioInput:disabled+.radiolabel:before {
    box-shadow: none;
    background: #ddd;
}

.radioInput:checked+.radiolabel:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 7px;
    background: white;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
