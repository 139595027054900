.message-div {
  position: fixed;
  width: 100px;
  transition: width 2s;
  -webkit-transition: width 2s; /* Safari 3.1 to 6.0 */
}
.message-display-div {
  width: 92%;
  z-index: 99999;
  max-width: 500px;
}

@media only screen and (max-width: 768px) {
  .inv-inline-row select {
    padding: 10px 20px 10px 10px !important;
    /* font-size: 12px; */
  }
}

.ReactModal__Content--after-open {
  /* top: 40px; */
  left: 0px !important;
  right: 0px !important;
}

/*Add padding for login wrapper */
.loginForm [name="form"] {
  padding: 0 15px;
}

/* @media screen and (max-width:767px){
    .outerMostContainer{
      margin-top: 0 ;
      float: left;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px){
    .outer {
      margin-top: 111px !important;
  }
  }
  @media only screen and (max-width:550px){
    .outer {
      margin-top: 151px !important;
  }
  } */

.inv-page-head select {
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
  appearance: menulist !important;
}


.button-add-equipment{
  font-size: 12px  !important;
  float: right  !important;
  vertical-align: top  !important;
  position: relative  !important;
  /* top: -12px; */
  margin: 0  !important;
}
.inv-search-mobile input{
  margin-top: 0px !important ;
  margin-bottom: 0 !important ;
  padding-left: 40px;
  background: url(../assets/images/search-dark.png) no-repeat top 11px left 16px;
  background-size: 17px;
  background-color: #fff;
  &:focus,&:active,&[aria-expanded="true"]{
    background-image: none;
    padding-left: 15px;
    padding-right: 50px;
    &+.clear-and-close-search{
        display: block ;
    }
  }
} 
.search-result-wrapper{
  display: block ;
  float: left;
  width: 100% ;
  margin-top: 20px;
  .inv-result-set{
    background-color: transparent ;
    padding-left: 0 ;
    padding-right: 0 ;
  }
  .inv-result-content{
    padding: 0 16px ;
    ul.inv-result-collection{
      flex-direction: column ;
      li{
        padding: 24px 35px 30px 35px;
        background-color: #fff ;
        width: 100% ;
        max-width: 100% ;
        margin-bottom: 8px;
      }
    }
  }
}
.padding-top-7{
  padding-top: 7px !important ;
}

.main-page-wrapper{
  float: left;
  width: 100% ;
  display: block ;
  &[aria-hidden="true"]{
    display: none ;
  }
}

.inv-page-head.no-border{
  border: none !important ;
}
.search-box-wrapper-inline{
  display: inline-block ;
  width: 100% ;
  margin-top: 15px !important ;
  position: relative ;
  .clear-and-close-search{
    background-image: url(../assets/images/close_green.png);
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-size: 16px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: none ;
  }
}

.no-results-found-inv{
  text-align: left;
  padding: 12px 0 0;
  float: left;
  width: 100%;
}
.inv-loading-search{
  background-image: url(../assets/images/loader.gif);
  background-size: 20px;
  background-position: center;
  height: 34px;
  width: 100%;
  display: block;
  float: left;
  background-repeat: no-repeat;
}

.gallery-pop-wrapper{
  opacity: .1;
}



.ReactModal__Content.ReactModal__Content--after-open[aria-label="Lightbox"]{
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border: none !important;
    background: transparent !important;
    overflow: hidden !important;
    border-radius: 0px !important;
    outline: none !important;
    padding: 0px !important;
    width: 100%  !important;
    height: 100%  !important;
    top: 0  !important;
    button{
      outline: none ;
    }
}
.main-image-top{
  display: block ;
  margin-bottom: 10px;
  img{
    width: 100% ;
    height: auto ;
  }
}
.inv-slick-item-box{
  .item-bg{
    background-size: cover ;
  }
  img{
    width: 100% ;
    height: auto ;
    opacity: 0;
  }
}




//inventory button styling
.inventory-slider-bottom{
  display: flex;
  justify-content: space-between;
  .button{
    margin: 0 10px;
    min-width: 100px;
    @media only screen and (max-width: 991px) {
      margin: 0 5px;
      min-width: 80px;
    }
    &:nth-child(1){
      margin-left: 0;
    }
    &:nth-last-child(1){
      margin-right: 0;
    }
  }
}

.datePicker-error{
  border: 1px solid red;
  background-color: #FFDCDC;
}