/** Styles for no interview form*/
.no_int_form_container {
  .text_container {
    background-color: #eef0f2;
    padding: 15px;
  }
}
/**Loader Styles **/
.loader_container {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  .overlay_loader {
    display: table;
    background: transparent;
    position: absolute;
    top: 20rem;
    left: 50%;
    i {
      display: block;
      text-align: center;
      font-size: 6rem;
    }
  }
}

/** Styles for Interview Tab */
.int_tab_container {
  padding: 16px;
  :global {
    .int_tab_header {
      // margin-bottom: 16px;
      padding: 0 !important;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    .interview_form_wrapper {
      * {
        font-family: "Proxima Nova", Georgia, sans-serif;
        color: #202020;
      }
      table {
        tr {
          background-color: transparent;
          td,
          th {
            background-color: transparent;
          }
        }
      }
      .nav-tabs {
        li {
          border-bottom: 2px solid transparent;
          a {
            font-size: 13px;
            line-height: 21px;
            font-weight: 500;
            color: rgba(32, 32, 32, 0.6);
            border: none;
            padding: 6px 10px;
            outline: none;
            @media (min-width: 991px) {
              padding: 12px 16px;
            }
          }
          &.active {
            border-bottom: 2px solid #3f7e3a;
            a {
              color: #3f7e39;
            }
          }
        }
      }
      .tab-content {
        border: 1px solid #d8d8d8;
        border-top: none;
        padding: 15px;
        @media (min-width: 991px) {
          padding: 22px;
        }
        @media (min-width: 1440px) {
          padding: 32px 34px;
        }
        .ref_Form_wrapper {
          h3 {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            margin: 0 0 15px;
            font-family: "Proxima Nova Semibold", Georgia, sans-serif;
            @media (min-width: 991px) {
              font-size: 18px;
            }
          }
          p {
            font-size: 14px;
            line-height: 20px;
            &.note_para {
              border-bottom: 1px solid #dadada;
              padding-bottom: 15px;
              margin-bottom: 15px;
              line-height: 24px;
              b {
                font-family: "Proxima Nova Semibold", Georgia, sans-serif;
              }
              .refs_not_ok {
                color: red;
              }
              @media (min-width: 991px) {
                padding-bottom: 24px;
                margin-bottom: 40px;
              }
            }
          }
          .show-grid {
            margin-left: -14px;
            margin-right: -14px;
            // display: flex;
            flex-wrap: wrap;
            margin-bottom: 0px;
            @media (min-width: 600px) {
              margin-bottom: 30px;
            }
            @media (min-width: 991px) {
              margin-bottom: 40px;
            }
            @media (min-width: 1440px) {
              margin-bottom: 64px;
            }
            &.last_item {
              margin-bottom: 20px;
              @media (min-width: 991px) {
                margin-bottom: 30px;
              }
              @media (min-width: 1440px) {
                margin-bottom: 56px;
              }
            }
            & > div {
              padding-left: 14px;
              padding-right: 14px;
              float: none;
              &.col_table {
                width: 100%;
                margin-bottom: 24px;
                @media (min-width: 991px) {
                  width: 322px;
                  display: inline-block;
                }
              }
              &.referenceCheckSection {
                @media (min-width: 991px) {
                  display: inline-block;
                  width: calc(100% - 322px);
                }
              }
              &.col_date {
                width: 100%;
                @media (min-width: 600px) {
                  width: 200px;
                }
                @media (min-width: 991px) {
                  width: 233px;
                }
              }
              &.col_note {
                width: 100%;
                margin-bottom: 20px;
                textarea {
                  height: calc(100% - 26px);
                  margin-bottom: 0;
                  border-radius: 4px;
                  display: block;
                  min-height: 130px;
                  @media (min-width: 600px) {
                    min-height: 200px;
                  }
                  @media (min-width: 1440px) {
                    height: calc(100% - 40px);
                  }
                }
                .text-area-error-msg {
                  color: red;
                }
              }
              p {
                font-size: 15px;
                padding-bottom: 10px;
                margin-bottom: 0;
                line-height: 20px;
              }
              .verified_date,
              .datepicker_ref {
                width: 100%;
                margin-bottom: 4px;
                .react-datepicker-wrapper {
                  width: 100%;
                  div {
                    width: 100%;
                    input {
                      font-size: 15px;
                      line-height: 20px;
                    }
                  }
                }
              }
            }
            &.interview__grid {
              @media (min-width: 991px) {
                margin-bottom: 48px;
                display: flex;
              }
            }
            .interview__head {
              width: 100%;
              border-bottom: 1px solid #dadada;
              margin: 0 14px;
              margin-bottom: 16px;

              h1 {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                color: #202020;
                padding-bottom: 12px;
                margin: 0;
                font-family: "proxima_nova_altbold", Georgia, sans-serif;
              }
            }
          }
          .interview__wrap {
            background-color: #eeeeee;
            padding: 16px 20px 0;
          }
          .ref_table {
            width: 100%;

            thead {
              tr {
                th {
                  font-size: 15px;
                  font-weight: 700;
                  line-height: 20px;
                  color: #202020;
                  padding: 8px 0;
                  font-family: "proxima_nova_altbold", Georgia, sans-serif;
                  @media (min-width: 600px) {
                    border-bottom: 1px solid #dadada;
                  }
                  @media (min-width: 1440px) {
                    padding: 11px 0;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  width: 100%;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 20px;
                  padding: 5px 0 0;
                  vertical-align: middle;
                  @media (min-width: 600px) {
                    padding: 8px 0 8px ;
                    border-bottom: 1px solid #dadada;
                    width: 37%;
                  }
                  @media (max-width: 600px) {
                    &:first-child {
                      font-weight: 600;
                      font-family: "Proxima Nova Semibold", Georgia, sans-serif;
                    }
                    &:last-child {
                      padding-bottom: 12px;
                    }
                  }
                }
                &:last-child{
                  td{
                    border: 0;
                  }
                }
              }
            }
          }
          .referenceCheckSection {
            h4 {
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              font-family: "Proxima Nova Semibold", Georgia, sans-serif;
              margin: 0 0 12px;
              @media (min-width: 991px) {
                line-height: 32px;
                margin: 0 0 24px;
                font-size: 20px;
              }
            }
            .show-grid {
              margin-bottom: 0px;
              // @media (min-width: 991px) {
              //   margin-bottom: 32px;
              // }
              p {
                padding-bottom: 10px;
                font-size: 15px;
                line-height: 20px;
                margin: 0;
                display: inline-block;
                margin-left: 2px;
              }
              input {
                font-size: 15px;
                line-height: 20px;
                padding: 10px;
                border-radius: 4px;
                border: 1px solid #d8d8d8;
                margin-bottom: 20px;
                @media (min-width: 991px) {
                  margin-bottom: 32px;
                  padding: 10px 16px;
                }
              }
            }
            &.social_media {
              .show-grid {
                margin-bottom: 0;
              }
              input{
                max-width: 142px;
              }
            }
          }
          .ref_form_btn {
            width: auto;
            margin: 0 -15px;
            display: flex;
            justify-content: flex-end;
            padding: 15px 15px 0;
            border-top: 1px solid #dadada;
            gap: 16px;
            @media (min-width: 991px) {
              padding: 22px 22px 0;
              margin: 0 -22px;
            }
            @media (min-width: 1440px) {
              padding: 32px 36px 0;
              margin: 0 -34px;
            }
            .interview_btn {
              display: flex;
              gap: 16px;
              button {
                width: 102px;
                border-radius: 3px;
                height: 36px;
                color: #ffffff;
                &:hover {
                  background-color: #56a64e;
                }
              }
            }
          }
          .error {
            border: 1px solid red !important;
          }
        }
      }
    }
    .checkbox_wrap {
      width: auto;
      margin: 0;
      display: inline-block;
      position: relative;
      margin-right: 32px;
      .checkbox_label {
        display: block;
        width: auto;
        margin: 0;
        padding: 0;
        z-index: 0;
        position: relative;
        font-family: "Proxima Nova", Georgia, sans-serif;
        &::before {
          content: "";
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: #fff;
          border: 1px solid #9d9d9d;
        }
      }
      .checkbox_input {
        width: 20px;
        height: 20px;
        margin: 0 !important;
        position: absolute;
        opacity: 0;
        z-index: 9;
        left: 0;
        cursor: pointer;
        &:checked ~ .checkbox_label {
          &::before {
            background: #3f7e39;
            border-color: transparent;
          }
          &::after {
            content: "";
            position: absolute;
            left: 5px;
            top: 11px;
            background: #fff;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff,
              4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &.error ~ .checkbox_label {
          &::before {
            content: "";
            border: 1px solid red;
          }
        }
      }
    }
    .facebook_date {
      display: inline-block;
    }

    .refForm_wrapper {
      // padding: 16px;
      .interview_btn {
        display: flex;
        justify-content: right;
        gap: 10px;
      }
    }

    .int_tab_header {
      display: flex;
      justify-content: space-between;
      // padding: 0 20px;

      .add_int_btn {
        margin-top: 15px;
      }

      .back_int_btn {
        margin-top: 15px;
        float: right;
      }
    }

    .int_form_container {
      padding: 15px;
    }

    @media screen and (max-width: 462px) {
      .int_tab_header {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }

    .refForm-wrapper {
      background-color: red;
    }
  }
}

/** Styles for Interview form List */
.int_list_container {
  .int_list_tbl {
    table {
      width: 100%;
      th {
        padding: 12px;
        cursor: pointer;
        text-align: left;
        background-color: #eef0f2;
        border-bottom: 1px solid #d8d8d8;
        .header_txt {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
      }
      tr {
        background-color: #fbfbfb;
        &:nth-child(2n + 1) {
          background-color: #f3f3f3;
        }
        .pagination_row {
          padding: 0px !important;
          border: none;
          color: #fff;
          background: #fff;
        }
        td {
          padding: 12px;
          border-bottom: 1px solid #d8d8d8;
        }
        .icon_container {
          display: flex;
          gap: 22px;
          .icons_style {
            width: 24px;
          }
        }
      }
    }
    .pagination {
      display: flex;
      margin: 10px 0;
    }
  }
}

//new interview form
.interview_form_wrapper {
  // .interview_form_body {
  //   padding: 32px 33px;
  //   border: 1px solid #d8d8d8;
  //   @media screen and (max-width: 480px) {
  //     padding: 25px 20px;
  //   }
  // }
  .error {
    border: 1px solid red !important;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    line-height: 1.333;
    margin: 0;
    font-weight: 700;
    margin-bottom: 32px;
  }
  .form__wrapper {
    .form_group_row {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 640px) {
        flex-wrap: wrap;
      }
      .form_group {
        width: calc(50% - 12px);
        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }
    }
    .form_group {
      margin-bottom: 24px;
      &.overall_rating {
        border: none;
        .overall_rating_wrapper {
          border: 1px solid #d8d8d8;
          box-sizing: border-box;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
            margin-bottom: -27px;
            &:nth-last-child(1) {
              @media screen and (max-width: 480px) {
                transform: rotate(90deg);
              }
            }
            @media screen and (max-width: 480px) {
              transform: rotate(-90deg);
            }
          }
          .radio_btn_wrapper {
            display: flex;
            justify-content: center;
            padding: 40px 8px 56px;
            @media screen and (max-width: 480px) {
              padding: 30px 2px 46px;
            }
            .radio_group {
              display: flex;
              flex-direction: column;
              margin: 0 40px;
              min-width: 20px;
              align-items: center;
              cursor: pointer;
              @media screen and (max-width: 991px) {
                margin: 0 30px;
              }
              @media screen and (max-width: 640px) {
                margin: 0 15px;
              }

              @media screen and (max-width: 480px) {
                margin: 0 6px;
              }
              input {
                order: 2;
                cursor: pointer;
                min-height: 20px;
                margin-top: 24px;
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
                width: 100%;
                height: 100%;
                &:checked ~ .radiobtn {
                  &:after {
                    opacity: 1;
                  }
                }
                &:disabled {
                  background-color: #d0d3d4;
                }
              }
              label {
                text-align: center;
                margin-bottom: 0;
                min-height: 64px;
                span.radiobtn {
                  position: absolute;
                  top: 16px;
                  left: -3px;
                  height: 25px;
                  width: 25px;
                  border-radius: 50%;
                  border: 2px solid #9d9d9d;
                  cursor: pointer;
                  pointer-events: none;
                  margin-top: 24px;
                  margin-bottom: -3px;
                  &:after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-47%, -52%);
                    background-color: #3f7e39;
                    border-radius: 9999px;
                    opacity: 0;
                    pointer-events: none;
                    cursor: pointer;
                    // transition: all 0.3s ease-in-out;
                  }
                }
              }
              .container_radio {
                position: unset;
              }
            }
          }
        }
      }
      .table_wrapper {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        overflow-x: auto;
        table,
        th,
        td {
          border: 1px solid #d8d8d8;
        }
        table {
          width: 100%;
          min-width: 947px;
          thead,
          tr {
            @media screen and (max-width: 640px) {
              display: flex;
              align-items: center;
            }
          }
          thead th,
          tbody td {
            padding: 17.5px 16px;
            width: 100px;
            max-width: 100px;
            text-align: center;
            position: relative;
            &:nth-child(1) {
              width: 540px;
              max-width: 540px;
              text-align: left !important;
            }
            .radio_group {
              display: flex;
              flex-direction: column;
              // margin: 0 40px;
              min-width: 20px;
              align-items: center;
              cursor: pointer;
              input {
                order: 2;
                cursor: pointer;
                min-height: 20px;
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
                width: 100%;
                height: 100%;
                &:checked ~ .radiobtn {
                  &:after {
                    opacity: 1;
                  }
                }
              }
              label {
                text-align: center;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: static !important;
                width: 100%;
                height: 100%;
                span.radiobtn {
                  position: relative;
                  height: 25px;
                  width: 25px;
                  border-radius: 50%;
                  border: 2px solid #9d9d9d;
                  cursor: pointer;
                  pointer-events: none;
                  margin-top: -2px;
                  &:after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-53%, -52%);
                    background-color: #3f7e39;
                    border-radius: 50%;
                    opacity: 0;
                    pointer-events: none;
                    cursor: pointer;
                    // transition: all 0.3s ease-in-out;
                  }
                }
              }
              .container_radio {
                position: unset;
              }
            }
          }
          thead th {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
            font-family: "proxima_nova_altbold", Georgia, sans-serif !important;
            padding: 9.5px 16px;
            &:nth-first-child() {
              text-align: left;
            }
            span {
              width: 100%;
              display: block;
            }
          }
          tbody td {
            font-family: "Proxima Nova", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #202020;
            b {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #202020;
              font-family: "Proxima Nova Semibold", Georgia, sans-serif;
            }
            // &:nth-child(4), &:nth-child(6){
            //   .radio_group{
            //     label{
            //       span.radiobtn{
            //         &:after{
            //           transform: translate(-52%, -52%);
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
      label {
        margin-bottom: 10px;
        font-family: "Proxima Nova", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.333;
      }
      input,
      textarea {
        min-height: 40px;
        padding: 10px 16px;
        font-family: "Proxima Nova", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.333;
        margin-bottom: 0;
        display: block;
        &:disabled {
          background-color: #d0d3d4;
        }
      }
      &.notes {
        margin-top: 56px;
        margin-bottom: 56px;
      }
      .date_picker {
        width: 100%;
      }
    }
    .grouped_inputs {
      margin-top: 56px;
      &.m_lg {
        > .vertical_group {
          > .radio_btn_wrapper {
            > .radio_group {
              + .radio_group {
                margin-top: 32px;
              }
            }
          }
        }
      }
      &.living_status {
        .vertical_group .vertical_group {
          .radio_btn_wrapper {
            .radio_group {
              + .radio_group {
                margin-top: 24px !important;
              }
            }
          }
        }
      }
      &.sm_check {
        .form_group.notes {
          margin-top: 0;
        }
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #202020;
        margin: 0;
        margin-bottom: 16px;
        font-family: "Proxima Nova Semibold", Georgia, sans-serif;
      }
      .vertical_group {
        margin-top: 25px;
      }
    }
  }
  .form_group_row.vertical_group {
    .vertical_group {
      .radio_btn_wrapper .radio_group {
        margin-bottom: 12px;
        min-height: unset;
      }
    }
    @media screen and (max-width: 1024px) {
      .form__row {
        width: auto !important;
        margin-top: 0 !important;
        margin-left: 12px !important;
      }
      .width__auto {
        width: auto !important;
      }
    }
    .radio_btn_wrapper {
      display: block;
      width: 100%;

      .radio_group {
        display: flex;
        cursor: pointer;
        // margin-bottom: 23px;
        margin-bottom: 12px;
        min-height: 40px;
        align-items: center;
        @media screen and (max-width: 640px) {
          margin-bottom: 20px;
          min-height: unset;
        }
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        input.radio_inp {
          cursor: pointer;
          min-height: 20px;
          min-width: 20px;
          position: absolute;
          opacity: 0;
          margin: 0;
          cursor: pointer;
          left: 0;
          width: auto;
          height: auto;
          &:checked ~ .radiobtn {
            &:after {
              opacity: 1;
            }
          }
          &:checked ~ .form_group {
            input {
              pointer-events: all;
            }
          }
        }
        label {
          text-align: left;
          margin-bottom: 0;
          display: flex;
          float: none;
          position: relative;
          align-items: center;
          flex-wrap: wrap;
          input {
            margin-top: 0 !important;
          }
          p,
          p strong {
            cursor: pointer;
            margin-bottom: 0;
            margin-left: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
            font-family: "Proxima Nova", sans-serif;
            strong {
              margin-left: 0;
              font-weight: 600;
              font-family: "Proxima Nova Semibold", Georgia, sans-serif;
            }
          }
          span.radiobtn {
            position: relative;
            top: 0px;
            left: 0px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #9d9d9d;
            cursor: pointer;
            pointer-events: none;
            &:after {
              content: "";
              position: absolute;
              width: 13px;
              height: 13px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: #3f7e39;
              border-radius: 9999px;
              opacity: 0;
              pointer-events: none;
              cursor: pointer;
              // transition: all 0.3s ease-in-out;
            }
          }
          .form_group_row {
            width: 100%;
          }
        }
        .container_radio {
          position: unset;
        }
        .form_group_row {
          margin-left: 28px;
          margin-top: 16px;
        }

        .form_group {
          margin-bottom: 0;
          margin-left: 16px;
          @media screen and (max-width: 1024px) {
            margin-top: 10px;
            margin-left: 0px;
            width: 100%;
          }
          input {
            width: 160px;
            pointer-events: none;
          }

          input[type="date"]::-webkit-inner-spin-button,
          input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }
          label.hidden_label {
            display: none !important;
          }
        }
      }
      .first_row {
        margin-bottom: 0;
        margin-left: 16px;
        width: auto;
      }
    }
  }

  .submit_action__wrapper {
    // border: 1px solid #d8d8d8;
    // border-top: 1px solid #d8d8d8;
    // padding: 34px 34px 0;
    // margin: 0 -34px;
    // // padding: 32px 36px;
    // display: flex;
    // justify-content: space-between;
    // @media screen and (max-width: 640px) {
    //   flex-wrap: wrap;
    // }

    width: auto;
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 15px 0;
    border-top: 1px solid #dadada;
    gap: 16px;
    @media (min-width: 991px) {
      padding: 22px 22px 0;
      margin: 0 -22px;
    }
    @media (min-width: 1440px) {
      padding: 32px 36px 0;
      margin: 0 -34px;
    }
    .input__wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      @media screen and (max-width: 640px) {
        width: 100%;
        margin-bottom: 15px;
        justify-content: center;
      }
      input {
        margin-left: 16px;
        margin-bottom: 0;
        width: auto;
        color: #fff;
        padding: 8px 24px;
        background: #3f7e39;
        text-transform: capitalize;
        font-family: "proxima_nova_altbold", Georgia, sans-serif;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        @media screen and (max-width: 640px) {
          margin-right: unset !important;
          margin-left: unset !important;
          // margin:0px 5px !important;
        }
      }
    }
    .no_margin_input {
      input {
        margin-right: 16px;
        margin-left: 0px !important;
        @media screen and (max-width: 640px) {
          margin-right: 0px !important;
        }
      }
    }
  }
}

//success message
.success_msg__wrapper {
  .success_cntnt__wrapper {
    // padding: 50px;
    // max-width: 450px;
    // margin: 0 auto;
    padding: 45px;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 50vh;
    img {
      max-width: 48px;
      max-height: 48px;
      margin: 0 auto;
      display: block;
      margin-bottom: 16px;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #202020;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 20px;
    }
    .input__wrapper {
      text-align: center;
      a.button {
        margin: 0 auto;
        margin-bottom: 0;
        width: auto;
        color: #fff;
        padding: 7.5px 20px;
        background: #3f7e39;
        //text-transform: capitalize;
        font-family: "proxima_nova_altbold", Georgia, sans-serif;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        min-height: 32px;
      }
    }
  }
}

.references_container {
  display: flex;
  align-items: flex-start;
  padding: 8px 0px;
}

.professional_reference {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.reference_checkBoxWrap div:first-child {
  margin: 0px;
}

// ----------- Student table---------------------//
.continer_table_students {
  position: relative;
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 30px;
  }
}
.table_outerdiv {
  width: 100%;
  margin-bottom: 56px;
  @media only screen and (max-width: 1200px) {
    overflow-x: auto;
  }
  .second_table {
    margin: auto;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      width: 1200px;
    }
    @media (max-width: 600px) {
      border-spacing: 0;
      border-collapse: collapse;
    }
    tr {
      @media (max-width: 600px) {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
      }
      td {
        @media (max-width: 600px) {
          display: table-cell;
          vertical-align: inherit;
          width: auto;
        }
      }
    }
    table,
    th,
    td {
      border: 1px solid #d8d8d8;
      padding-right: 10px;
      padding-left: 10px;
    }

    table {
      border-collapse: collapse;
      min-width: 947px;
    }

    // tr:nth-child(even) {
    // background-color: lightgrey;
    // }

    th {
      background-color: skyblue;
    }

    :global {
      p {
        margin: 0;
      }

      .first_row {
        margin-top: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .ver_grp {
          margin-top: 0px !important;

          .radio_btns {
            display: flex;
            width: 300px;
            // padding-bottom: 10px;
            height: 60px;

            .first_radio_input {
              margin-bottom: 0 !important;
              margin-right: 20px;
              margin-left: 20px;
              width: 85px;
            }
          }
        }
      }

      .second_row {
        margin-top: 0 !important;
        padding: 10px 0;

        .ver_grp {
          margin-top: 0 !important;
        }
      }
    }
    @media only screen and (max-width: 1600px) {
      td:not(.second_table__head) {
        .container_radio {
          .form_group {
            width: 100%;
            margin-top: 10px;
            margin-left: 0 !important;
          }
        }
      }
    }
    .second_table__head {
      .grouped_inputs {
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 10px 0;
        .form_group_row {
          margin-top: 0;
          .radio_btn_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            .radio_group {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    //
  }
}
