.back_to_board{
  display: block;
  padding: 15px 20px;
  background: #f8fafb;
}
.back_to_board .back_button{
  position: relative;
  display: inline-block;
}
.back_to_board .back_button img{
  position: absolute;
}
.back_to_board .back_button span{
  display: block;
  line-height:12px;
  padding-left: 15px; 
  font-weight: 600;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.back_to_board .grid{
  width: 210px;
  display: inline-block;
  float: right;
  text-align: right;
}
.back_to_board .grid .list-view{
  display: inline-block;
  cursor: pointer;
  margin-right:20px;
  position: relative;
}
.back_to_board .grid .list-view img{
  width: 14px;
  position: absolute;
  top: 0;
}
/* .back_to_board .grid .list-view a{
  position: relative;
} */
.back_to_board .grid .list-view span{
  padding-left: 20px;
  font-weight: 600;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.back_to_board .grid .first img{
  top: 3px;
}
rect.bar {
  fill: #78a474!important;
}
.graph_date_wrap{
  width: 100%;
  float: left;
}
.graph_date{
  width:835px;
  float: right;
}
.print_button{
  display: inline-block;
  background: #3f7e39;
  color: #fff;
  padding: 6px 10px;
  height: 36px;
  width: 134px;
  text-align: center;
  /* margin-left: 2%; */
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}
.graph_date .form-group{
  position: relative;
  width:150px;
  display: inline-block;
  margin-left:2%;
}
.graph_date .form-control{
  position: relative;
  width:150px;
  display: inline-block;
  margin-left:2%;
  height: 40px;
}
.graph_date .form-group input{
  height: 40px;
}
.graph_date .form-group .datepicker-image{
  right: 10px;
}
.list_item_head__image{
  max-width: 50px;
  display: inline-block;
  min-width: 50px!important;
}
.report-navi a{
  color: #000;
}
.back_button a{
  color: #000;
}
.y-axis{
  margin-top: 200px;
  position: absolute;
  left: -30px;
  font-weight: 700;
/* Safari */
-webkit-transform: rotate(-90deg);

/* Firefox */
-moz-transform: rotate(-90deg);

/* IE */
-ms-transform: rotate(-90deg);

/* Opera */
-o-transform: rotate(-90deg);

float: left;
}
.x-axis{
  text-align: center;
  font-weight: 700;
}

.chart-report-wrapper{
  position: relative;
}
.graph_header {
  float: left;
}
.for_mobile_only{
  display:none;
}

.sort-arrow {
  cursor: pointer;
  width: 0px;
  display: inline-block;
  height: 0px;
  margin: 2px 5px;
  border-style: solid;
  line-height: 16px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #000000 transparent;
  opacity: 0.2;
}
.sort-arrow.desc {
  opacity: 1;
  /*border-width: 5px 5px 0 5px;*/
  border-width: 0 5px 5px 5px;
  /*border-color: #000000 transparent transparent transparent;*/
  border-color: transparent transparent #000000 transparent;
}

.sort-arrow.asc {
  opacity: 1;
  /*border-width: 0 5px 5px 5px;*/
  border-width: 5px 5px 0 5px;
  /*border-color: transparent transparent #000000 transparent;*/
  border-color: #000000 transparent transparent transparent;
}

@media screen and (max-width:1250px){
  .graph_date{
    width: 100%;
    margin-bottom: 15px;
  } 
  .graph_date .form-group{
    margin-left: 0px;
    margin-right: 1%;
    margin-bottom: 0px;
  }
  .graph_date .form-control{
    margin-left: 0px;
    margin-right: 1%;
    margin-bottom: 0px;
  }
  .print_button{
    margin-left: 0px;
  }
}
@media screen and (max-width:768px){
  .graph_date{
    width: 100%;
    position: relative;
    right: inherit;
  }
  .for_mobile_only{
    display:block;
  }
  .graph_date .form-group{
    margin-left: 0px;
    margin-right:1%;
  }
  .graph_date .react-datepicker-wrapper{
    width: 100%;
  }
  .graph_date .react-datepicker__input-container{
    width: 100%;
  }
}
@media screen and (max-width:600px){
  .graph_date .form-group {
    margin-left: 0px;
    margin-right: 5%;
    width: 45%;
  }
  .graph_date .form-control{
    width: 45%;
    margin-right: 5%;
  }
  .print_button{
    margin-top: 15px;
    width: 100%;
  }
}
@media screen and (max-width:440px){
  .back_to_board .grid{
    float: none!Important;
  }
  .back_to_board .back_button{
    margin-bottom: 15px;
  }
}
.top-buttons-wrapper{
  width: 280px !important;
}


/* Nov2021 */

.dashboard-expiration-date-print-btn-mock{
  min-height: 40px;
}

/* Jan2021 */

li.resource_order_form_list_nav_tab_active{
      border: none;
    border-bottom: 2px solid #3f7e39;
    color: #3f7e39;
    font-weight: bold;
}

.resource_order_form_list_nav_tab_active a {
   color: #3f7e39;
}

.resource_order_form_list_nav_tab{
    border: none;
    border-radius: 0px;
    color: #555;
    outline: none;
}

.resource_order_form_list_nav_tab a {
    color: #555;
}

.dropdown-container{
  position: relative;
}




/** 3 dots style **/
.dropdown-container .three-dots:after {
  cursor: pointer;
  color: #3f7e39;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dropdown-container .dropdown {
  position: absolute;
  /* top: 100%; */
  right: 6px;
  bottom: 29px;
  /* transform: translateX(-50%); */
  background-color: #fff;
  box-shadow: 0px 0px 3px 3px #e7e7e7;
  outline: none;
  opacity: 1;
  z-index: 100;
  max-height: 0;
  border-radius: 6px;
  border: 1px solid #f6f6f6;
  padding: 5px;
  max-height: 100vh;
}

.reset_btn_container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.reset_btn_container .btn__reset {
  font-size: 12px;
  min-width: 140px;
  background: #fff;
  color: #3f7e39 !important;
}