.applicant__container {
  padding: 0px 0px;
  .page_title {
    position: relative;
    font-size: 24px;
    padding-bottom: 10px;
    margin: 0px;
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
  }
  .p_custom {
    a {
      padding: 1rem 1rem !important;
      font-size: 1.2rem !important;
      min-width: 172px !important;
    }
    li {
      &:first-child {
        a {
          padding: 1rem 1rem !important;
        }
      }
    }
  }
  .applicant_tab_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    .header_btn_grp {
      display: flex;
      gap: 10px;
      max-height: 32px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: space-between;
    }
    @media screen and (max-width: 496px) {
      .header_btn_grp {
        flex-direction: column;
        max-height: unset !important;
        button {
          width: 100%;
        }
      }
    }
  }

  .tab_nav_item_txt {
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }
}

.no_access_container {
  text-align: center;
  padding: 12rem;
}
