@import 'mixin.scss';

.ft-inventory.inv-desktop-wrapper {
    @media #{$md}{
        gap: 10px;
    }
    .top_option {
        .inputField {
            &:nth-child(1) {
                @media #{$md} {
                    z-index: 1;
                }
            }
            &:nth-child(2) {
                @media #{$md} {
                    margin: 0 10px;
                }
            }
            select {
                width: 100%;
                @media #{$xl} {
                    width: 48%;
                }
                &.right {
                    @media #{$md} {
                        margin-right: calc(-100% - 10px);
                    }
                    @media #{$xl} {
                        width: 48%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .inv_detail_outer {
        @media #{$md} {
            gap: 10px;
            justify-content: space-between;
        }
    }
    .in_section {
        @media #{$md}{
            @include calc-width(4,10);
            flex: none;
            margin: 0;
            padding: 0;
        }
        @media #{$xxl}{
            @include calc-width(3,10);
        }
        &.user_area  {
            @media #{$md}{
                @include calc-width(2,10);
            }
            @media #{$xxl}{
                @include calc-width(3,10);
            }
            .inv_section {
                @media #{$md}{
                    @include calc-width(2,10);
                    margin-right: 10px;
                }
                &:last-child {
                    @media #{$md}{
                        margin-right: 0;
                    }
                }
            }
        }
        &.inv_crew_list {
            @media #{$xxl} {
                @include calc-width(3,10);
            }
        }
        &:nth-of-type(2) {
            margin: 0 !important;
        }
    }
    .innerDrag {
        @media #{$md}{
            flex-direction: column;
            padding: 8px;
        }
        @media #{$lg} {
            flex-direction: row-reverse;
        }
        .inv_field_type,
        .invImageContainer {
            @media #{$md} {
                width: 100%;
            }
            @media #{$lg} {
                width: 50%;
            }
        }
        .inv_field_type {
            @media #{$md} {
                padding-right: 4px;
            }
            & > span {
                width: 100%;
                display: block;
                @include clamp-lines(1);
            }
        }

        .invImageContainer {
            @media #{$md} {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }
            @media #{$xl} {
                width: 50%;
                max-width: 72px;
            }
            .invImg {
                @media #{$md} {
                    margin: 0;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 2;
                }
                &::after {
                    @media #{$md} {
                        content: '';
                        display: block;
                        padding-bottom: 100%;
                    }
                }
            }
            &::after {
                @media #{$md} {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #d6d6d6;
                    z-index: 1;
                }
            }
        }
    }
    .master_section {
        &.in_section {
            .head_sec {
                margin: 0;
            }
        }
        #master_section_outer {
            @media #{$md} {
                float: none;
                flex-direction: column;
            }

            @media #{$xxl} {
                flex-direction: row;
                gap: 5px;
            }

            .is-list {
                @media #{$md} {
                    width: 100%;
                    padding: 0 !important;
                }
                @media #{$xxl} {
                    @include calc-width(2, 5);
                }
            }

            .inventory_element {
                @media #{$md} {
                    float: none;
                    border: none;
                    margin-top: 10px !important;
                }
                @media #{xxl} {
                    margin-top: 0 !important;
                    margin-bottom: 10px !important;
                }
            }
        }
    }
    .avail_equip {
        .innerDrag {
            padding: 8px 0;
        }
    }
    .team_section {
        .team_member {
            .user_pic {
                @media #{$md}{
                    float: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;
                }
                @media #{$xl} {
                    display: inline-flex;
                    width: 34px;
                    height: 34px;
                    margin-bottom: 0;
                }
            }
            .user_pic_right {
                @media #{$md}{
                    width: 100%;
                    float: unset;
                    margin: 0;
                    text-align: center;
                }
                @media #{$xl} {
                    width: calc(100% - 40px);
                    display: inline-block;
                    text-align: left;
                    margin-left: 5px;
                }
            }
            .inventory_element {
                @media #{$md}{
                    margin-right: 0 !important;
                }
            }
        }
    }
    .inv_crew_list {
        .list-right {
            @media #{$md} {
                padding: 0 !important;
            }
            @media #{$xxl} {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 10px;
                padding: 10px !important;
                align-content: flex-start;
            }

            &:has(.epmty_crew) {
                @media #{$md} {
                    .epmty_crew {
                        position: static !important;
                        transform: translate(0);
                        padding-top: rem(32);
                    }
                }
            }
        }
        .resource_item {
            @media #{$md} {
                float: none;
                width: calc(100% - 16px);
                margin: 10px 8px !important;
            }
            @media #{$xxl} {
                @include calc-width(2, 10);
                margin: 0 !important;
            }
            .aspect-box {
                --aspect-ratio: 100/100;
                overflow: hidden;
                &::before {
                    background-color: #d6d6d6;
                    border-radius: 5px;
                    overflow: hidden;
                }
            }
            p {
                @media #{$md} {
                    @include clamp-lines(2);
                    text-align: center;
                    width: 100%;
                    height: auto;
                    white-space: normal;
                    min-height: 34px;
                }
            }
        }
    }
}